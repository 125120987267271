import React from "react"
import Icon from "@mdi/react"
import { mdiClose } from "@mdi/js"
import { Formik, Form, Field, ErrorMessage } from "formik"
import "../../Components/EditDetails/EditPersonalDetails.scss"
import * as Yup from "yup"
import PhoneInput from "react-phone-input-2"
import { CustomLoader } from "../Loader/Loader"

export interface Props {
  toggleModal?: () => void;
  userData?: any;
  onSubmit: any;
}
export interface EditPersonal {
  salutation_title: string
  email: string
  first_name: string
  middle_name: string
  last_name: string
  contact_no: string
  desgination: string
  country_code: string
}

export interface IPhoneInput{
  dialCode: string
}

const validationSchema = Yup.object({
  first_name: Yup.string()
    .min(2, "Name too small")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces")
    .required("Required"),
  // middle_name: Yup.string()
  //   .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
  //   .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces"),
  last_name: Yup.string()
    .min(4, "Name too small")
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .required("Required"),
  contact_no: Yup.string()
    .required("Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .min(11, "Phone number is not valid")
    .max(15, "Phone number is not valid"),

  email: Yup.string().email("Invalid email address").required("Required"),
})

const EditPersonalDetails: React.FC<Props> = ({
  toggleModal,
  userData,
  onSubmit,
}) => {
  return (
    <div className="edit-section">
      <div className="top-head">
        <div className="update-topic">Update Personal Details</div>

        <div className="close-icon" onClick={toggleModal}>
          <Icon className=" mdi-close" path={mdiClose} />
        </div>
      </div>

      <Formik
        initialValues={{
          email: userData?.email,
          first_name: userData?.first_name,
          middle_name: userData?.middle_name,
          last_name: userData?.last_name,
          contact_no: userData?.contact_no,
          country_code: userData?.country_code
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values, setValues }) => (
          <Form>
            <div className="row">
              <div className="col-md-4">
                <label className="form-label">First Name</label>
                <Field name="first_name" type="text" className="form-control" />
                <span className="error">
                  <ErrorMessage name="first_name" component="span" />
                </span>
              </div>
              <div className="col-md-4">
                <label className="form-label">Middle Name</label>
                <Field
                  name="middle_name"
                  type="text"
                  className="form-control"
                />
                <span className="error">
                  <ErrorMessage name="middle_name" component="span" />
                </span>
              </div>
              <div className="col-md-4">
                <label className="form-label">Last Name</label>
                <Field name="last_name" type="text" className="form-control" />
                <span className="error">
                  <ErrorMessage name="last_name" component="span" />
                </span>
              </div>
              <div className="col-md-6">
                <label className="form-label">Email</label>
                <Field name="email" type="text" className="form-control" />
                <span className="error">
                  <ErrorMessage name="email" component="span" />
                </span>
              </div>
              <div className="col-md-6">
                <label className="form-label">Contact no:</label>
                <PhoneInput
                  country={"np"}
                  value={values.contact_no}
                  onChange={(value, data: IPhoneInput) => {
                    setValues({
                      ...values,
                      contact_no: value,
                      country_code: data?.dialCode
                    })
                  }}
                />
                <span className="error">
                  <ErrorMessage name="contact_no" component="span" />
                </span>
              </div>
            </div>
            <div className="submit-section">
              <button
                className="search-btn pd-btn"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div>
                    <CustomLoader
                      color="white"
                      width={40}
                      height={25}
                      type="TailSpin"
                    />
                  </div>
                ) : (
                  <span>Update</span>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default EditPersonalDetails
