import { mdiAlertCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { useEffect } from "react";
import {
  TabKeys,
  conditionalRenderBidTabs,
  filledDocuments,
  isNoticeCategoryStandingList,
} from "../../utils/services";
import { BOQ } from "../BOQ/BOQ";
import { BankDetail } from "../BidDetailPage/BidDetailPage";
import { DetailBid } from "../BidDetails/BidDetails";
import { BidFee } from "../BidFee/BidFee";
import { ISubmitData, useBid } from "../Context/BidContext/BidContext";
import { Document } from "../Document/Document";
import {
  DocumentResponse,
  IBidDocumentsResponse,
  IBoqCatalogueFiles,
} from "../EditBids/EditBids";
import BidFormikWrapper, {
  BidDocumentInitialValues,
} from "../FormikWrapper/BidFornikWrapper";
import { JointVenture } from "../JointVenture/JointVenture";
import "./BidDetailStepper.scss";
import {
  ResponseBoq,
  ResponseBoqObject,
  ResponseBoqProps,
} from "../utils/interface";

export interface BidDetailStepperProps extends ResponseBoqProps {
  resDocument?: DocumentResponse;
  reFetchData?: () => void;
  bidFee?: number;
  bidDetail?: DetailBid;
  catalogueFiles?: string;
  boqFinancialFiles?: string;
  boqFinancialResDocs?: IBoqCatalogueFiles;
  optionalDoc?: string;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
}

export interface BoqPostData {
  boq: Array<ResponseBoqObject>;
  grand_total: number;
}

export enum BidDetailTabs {
  JOINT_VENTURE = 1,
  DOCUMENT = 2,
  BID_FEE = 3,
  BOQ = 4,
}

const BidDetailStepper: React.FC<BidDetailStepperProps> = ({
  setPage,
  page,
  boq,
  bankDetail,
  documentNeed,
  resDocument,
  reFetchData,
  bidFee,
  bidDetail,
  catalogueFiles,
  boqFinancialFiles,
  boqFinancialResDocs,
  optionalDoc,
}) => {
  const { setCompleteBidDetails } = useBid();

  const conditionalTabs = conditionalRenderBidTabs(
    bidDetail?.notice_category as string,
    bidDetail?.opening_date as string,
    bidDetail?.isMain as boolean
  );

  useEffect(() => {
    if (isNoticeCategoryStandingList(bidDetail?.notice_category ?? "")) {
      if (bidDetail?.isMain) {
        setPage(2);
      } else {
        setPage(4);
      }
    }

    if (resDocument && documentNeed) {
      const submitObject: ISubmitData = {
        documentTab: filledDocuments(
          documentNeed as string,
          resDocument.bid_documents as IBidDocumentsResponse
        ),
        boqTab: {
          boq: boq?.boq_details,
          boq_catalogue_name: boq?.boq_catalog_name as string,
        },
        feeTab: {
          payment: resDocument?.fee.e_payment as string,
        },
      };
      setCompleteBidDetails(submitObject);
    }
    
  }, [resDocument, documentNeed]);

  return (
    <>
      <BidFormikWrapper
        setPage={setPage}
        page={page}
        resDocument={resDocument as DocumentResponse}
        documentNeed={documentNeed as string}
        boq={boq as ResponseBoq}
        reFetchData={reFetchData}
        catalogueFiles={catalogueFiles as string}
        bankDetail={bankDetail as BankDetail}
        bidDetail={bidDetail as DetailBid}
        boqFinancialFiles={boqFinancialFiles}
        // boqFinancialResDocs={boqFinancialResDocs}
        optionalDoc={optionalDoc}
      >
        <div className="tab-content card">
          <div className="">
            <div className="Stepper tab-box ">
              <div className="card-section">
                <div className="form-section">
                  {conditionalTabs.Jointventure && (
                    <button
                      type="button"
                      className={
                        page === BidDetailTabs.JOINT_VENTURE
                          ? "prime-btn"
                          : "white-btn"
                      }
                      onClick={() => {
                        setPage(BidDetailTabs.JOINT_VENTURE);
                      }}
                    >
                      Joint Venture
                    </button>
                  )}

                  {conditionalTabs.Documents && (
                    <button
                      type="button"
                      className={
                        page === BidDetailTabs.DOCUMENT
                          ? "prime-btn"
                          : "white-btn"
                      }
                      onClick={() => {
                        setPage(BidDetailTabs.DOCUMENT);
                      }}
                    >
                      Document
                    </button>
                  )}
                  {conditionalTabs.Fee && (
                    <button
                      type="button"
                      className={
                        page === BidDetailTabs.BID_FEE
                          ? "prime-btn"
                          : "white-btn"
                      }
                      onClick={() => {
                        setPage(BidDetailTabs.BID_FEE);
                      }}
                    >
                      Fee
                    </button>
                  )}
                  {conditionalTabs.BOQ && (
                    <button
                      type="button"
                      className={
                        page === BidDetailTabs.BOQ ? "prime-btn" : "white-btn"
                      }
                      onClick={() => {
                        setPage(BidDetailTabs.BOQ);
                      }}
                    >
                      Financial
                    </button>
                  )}
                </div>

                {page === BidDetailTabs.JOINT_VENTURE && (
                  <>
                    {conditionalTabs.Jointventure ? (
                      <JointVenture
                        resDocument={resDocument}
                        setPage={(page) => setPage(page)}
                        reFetchData={reFetchData}
                      />
                    ) : (
                      restictViewAccordingToSubmission(
                        bidDetail?.opening_date as string,
                        "jointVenture",
                        bidDetail?.isMain as boolean
                      )
                    )}
                  </>
                )}
                {page === BidDetailTabs.BID_FEE && (
                  <>
                    {conditionalTabs.Fee ? (
                      <BidFee
                        bidFee={bidFee}
                        resDocument={resDocument}
                        setPage={setPage}
                        bankDetail={bankDetail}
                        bidDetail={bidDetail}
                        reFetchData={reFetchData}
                        documentNeed={documentNeed}
                        catalogueFiles={catalogueFiles}
                      />
                    ) : (
                      restictViewAccordingToSubmission(
                        bidDetail?.opening_date as string,
                        "bidFee",
                        bidDetail?.isMain as boolean
                      )
                    )}
                  </>
                )}
                {page === BidDetailTabs.BOQ && (
                  <>
                    {conditionalTabs.BOQ ? (
                      <BOQ
                        resDocument={resDocument}
                        setPage={setPage}
                        boq={boq}
                        reFetchData={reFetchData}
                        bidDetail={bidDetail as DetailBid}
                        documentNeed={documentNeed}
                        catalogueFiles={catalogueFiles}
                        boqFinancialFiles={boqFinancialFiles}
                        boqFinancialResDocs={boqFinancialResDocs}
                      />
                    ) : (
                      restictViewAccordingToSubmission(
                        bidDetail?.opening_date as string,
                        "boq_details",
                        bidDetail?.isMain as boolean,
                        bidDetail?.notice_category as string
                      )
                    )}
                  </>
                )}
                {page === BidDetailTabs.DOCUMENT && (
                  <>
                    {conditionalTabs.Documents ? (
                      <Document
                        resDocument={resDocument}
                        catalogueFiles={catalogueFiles}
                        bidDetail={bidDetail}
                        setPage={setPage}
                        documentNeed={documentNeed}
                        optionalDoc={optionalDoc}
                        reFetchData={reFetchData}
                      />
                    ) : (
                      restictViewAccordingToSubmission(
                        bidDetail?.opening_date as string,
                        "documents",
                        bidDetail?.isMain as boolean
                      )
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </BidFormikWrapper>
    </>
  );
};

const restictViewAccordingToSubmission = (
  openingDate: string,
  viewType: keyof BidDocumentInitialValues,
  isMain: boolean,
  noticeCategory?: string
) => {
  return (
    <div className="expire-bid">
      <div className="imageCenter">
        <img
          className="expiry-img"
          src={require("../../images/bidders-lock.png")}
          alt="img-lock"
        />
      </div>
      <div>
        {!isMain || Boolean(openingDate) ? (
          <div className="red-highlight" style={{ padding: "1rem" }}>
            <div>
              <Icon className="mdi-alert" path={mdiAlertCircleOutline}></Icon>
            </div>
            You have already submitted &nbsp;<b>{TabKeys(viewType)}</b>.
          </div>
        ) : (
          <div>
            You can view and submit &nbsp;
            <b style={{ color: "red" }}>{TabKeys(viewType)} </b> after{" "}
            {isNoticeCategoryStandingList(noticeCategory ?? "")
              ? `request quotation`
              : `technical
            opening.`}
          </div>
        )}
      </div>
    </div>
  );
};

export default BidDetailStepper;
