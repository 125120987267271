import { Icon } from "@mdi/react"
import { mdiInformationOutline } from "@mdi/js"
import "./PaymentPopUp.scss"
import { mdiClose } from "@mdi/js"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { generateToken } from "../utils/GenerateToken"
import {
  esewaPaymentInitiation,
} from "../EsewaPayment/EsewaPayemnt"
import { KhaltiPaymentInitiate } from "../../Khalti/KhaltiNewIntegration/KhaltiIntergationConfig"
import { PaymentFeeType } from "../../utils/EnumConstants"
import { useState } from "react"

export interface Props {
  toggleModal?: () => void
}
interface PaymentPopUpProps {
  toggleModal?: () => void
  tender_notice_id?: number | string | undefined
  loading?: boolean
  refetchMyBids?: any
  fee_for?: PaymentFeeType
}

export const PaymentPopUp: React.FC<PaymentPopUpProps> = ({
  toggleModal,
  tender_notice_id,
  loading,
}) => {
  const navigate = useNavigate()
  const token = generateToken()
  const { pathname } = useLocation()
  const { id } = useParams()
  const [publishFee] = useState(1000)
  const tenderId = tender_notice_id ? tender_notice_id : id

  return (
    <>
      {loading ? (
        ""
      ) : (
        <div className="confirmation-popup">
          <div className="">
            <div
              className="close-icon"
              onClick={() => {
                toggleModal?.()
                navigate("/")
              }}
            >
              <Icon className=" mdi-close" path={mdiClose} />
            </div>
            <div className="">
              <p className="text-title">
                Deposit Security Amount for Publishing Tender
              </p>
            </div>
            <div className="success-box">
              <div>
                <Icon className="mdi-alert" path={mdiInformationOutline}></Icon>
              </div>
              <p>
                Note:
                <li>Deposit security amount of Rs. {publishFee} </li>
                <li>
                  Its 100% refundable but incase of cancelation it will not be
                  refunded.
                </li>
              </p>
            </div>

            <div className="imgDesign">
              <div className="img-wrapper">
                <div className="image-box">
                  <div className="icon-block icon-spacing">
                    <img
                      src={require("../../images/esewa.png")}
                      alt="images"
                      onClick={() => {
                        esewaPaymentInitiation(
                          publishFee,
                          tender_notice_id as string,
                          token,
                          PaymentFeeType.Publish,
                          pathname
                        )
                      }}
                    />
                  </div>
                </div>
                <div className="image-box">
                  <div className="icon-block icon-spacing">
                    <img
                      onClick={() => {
                        KhaltiPaymentInitiate(
                          pathname,
                          PaymentFeeType.Publish,
                          publishFee,
                          tenderId
                        )
                      }}
                      src={require("../../images/khalti.png")}
                      alt="images"
                    />
                  </div>
                </div>
              </div>

              <div className="base-btn">
                <div className="p-10">
                  <button
                    className="show-bid btn-padd "
                    onClick={() => {
                      toggleModal?.()
                      navigate("/")
                    }}
                  >
                    Cancel payment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
