import ImageIcon from "@mui/icons-material/Image";
import ListIcon from "@mui/icons-material/List";
import { useEffect, useState } from "react";
import "./GalleryView.scss";

export const GalleryView = (props?: any) => {
  const [view, setView] = useState<string>("list");

  useEffect(() => {
    props?.getView(view);
  }, [view]);

  return (
    <div className="galleryview-section">
      <div className="view-card">
        <div
          onClick={() => setView("list")}
          className={`imagelist ${view === "list" ? "active" : ""}`}
        >
          <div className="list-icon">
            <ListIcon />
          </div>
        </div>
        <div
          onClick={() => {
            setView("image");
          }}
          className={`imagelist ${view === "image" ? "active" : ""}`}
        >
          <ImageIcon className="view-icon" />
        </div>
      </div>
    </div>
  );
};
