import "./PricingShimmer.scss";

export const PricingShimmer = () =>{
    return(
        <div className="pricing-shimmer-section">
        <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-md-3">
                <div className="shimmer-card">
                    {/* <div style={{padding:"20px"}}>

                    <p></p>
                    <p></p>
                    </div>
                  
                    <div className="border"></div>


                    <div style={{padding:"20px"}}>
                    
                    <div className="section-margin">
                    <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>
                       <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>   <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>

                    </div>

                    <div >
                    <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>
                    <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div> 
                      <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>   
                    <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>     <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>   

                    </div>

                    </div> */}
                </div>
                </div>

                <div className="col-md-3">
                <div className="shimmer-card">
                    {/* <div style={{padding:"20px"}}>

                    <p></p>
                    <p></p>
                    </div>
                  
                    <div className="border"></div>


                    <div style={{padding:"20px"}}>
                    
                    <div className="section-margin">
                    <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>
                       <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>   <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>

                    </div>

                    <div >
                    <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>
                    <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div> 
                      <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>   
                    <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>     <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>   

                    </div>

                    </div> */}
                
                </div>
                </div> 
                
                 <div className="col-md-3">
                <div className="shimmer-card">
                        {/* <div style={{padding:"20px"}}>

                    <p></p>
                    <p></p>
                    </div>
                  
                    <div className="border"></div>


                    <div style={{padding:"20px"}}>
                    
                    <div className="section-margin">
                    <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>
                       <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>   <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>

                    </div>

                    <div >
                    <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>
                    <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div> 
                      <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>   
                    <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>     <div className="d-flex">
                        <div className="icon"></div>
                        <div  className="content"></div>

                    </div>   

                    </div>

                    </div> */}
                </div>
                </div>

              
            </div>

            </div>
            </div>
    )
}