import { useEffect } from "react";
import { CheckButton } from "../CheckButton/CheckButton";
import { CustomLoader } from "../Loader/Loader";
import { ITenderAttr } from "../UserInterestStepper/UserInterestStepper";
import "./BudgetSources.scss";

export interface BudgetSourcesProps {
  showEdit?: boolean;
  savedProject?: any;
  setEditOpen?: any;
  interestAttr?: ITenderAttr;
  setPage?: (page: number) => void;
  setSelectedSources?: (selectedSources: Array<string>) => void;
  setSelectedBudget?: (selectedBudget: Array<string>) => void;
  selectedBudget?: Array<string>;
  selectedSources?: Array<string>;
  onsubmit?: () => void;
  onEdit?: () => void;
  savedEstimatedCost?: any;
  savedSources?: any;
  loading?: boolean;
}
export const BudgetSources: React.FC<BudgetSourcesProps> = ({
  showEdit,
  interestAttr,
  setPage,
  selectedBudget,
  selectedSources,
  setSelectedSources,
  setSelectedBudget,
  onsubmit,
  onEdit,
  savedEstimatedCost,
  savedSources,
  loading,
}) => {
  useEffect(() => {
    const tempArray = savedSources?.map((item: any) => item.id);
    setSelectedSources?.(tempArray);
  }, [savedSources]);

  useEffect(() => {
    const tempArray = savedEstimatedCost?.map((item: any) => item.id);
    setSelectedBudget?.(tempArray);
  }, [savedEstimatedCost]);

  return (
    <div className="budget-sources-section">
      <div className="title-block">
        <h4 className="title">Choose Your Budget and Sources</h4>
      </div>

      <div className="budget-sources-block">
        <div className={`interested-block`}>
          {!interestAttr?.estimated_cost || !interestAttr?.source ? (
            <CustomLoader
              color="#0052a7"
              type="TailSpin"
              height={20}
              width={105}
            />
          ) : (
            <div className="row">
              {interestAttr?.estimated_cost &&
                interestAttr?.estimated_cost?.length > 0 && (
                  <div className="col-md-6 col-sm-12">
                    <CheckButton
                      label={"Budgets"}
                      id={"all_budget"}
                      onClick={() => {
                        if (
                          selectedBudget?.length ===
                          interestAttr?.estimated_cost.length
                        ) {
                          setSelectedBudget?.([]);
                        } else {
                          const allBudgetValues =
                            interestAttr?.estimated_cost.map(
                              (item) => item.value
                            );
                          if (allBudgetValues) {
                            setSelectedBudget?.(allBudgetValues);
                          }
                        }
                      }}
                      isChecked={
                        selectedBudget?.length ===
                        interestAttr?.estimated_cost.length
                      }
                    />
                    {interestAttr?.estimated_cost.map((item, index) => {
                      return (
                        <div className="project-section" key={index}>
                          <CheckButton
                            label={item.label}
                            id={item.value}
                            onClick={() => {
                              if (selectedBudget?.includes(item?.value)) {
                                const temp = selectedBudget?.filter(
                                  (f) => f !== item?.value
                                );
                                setSelectedBudget?.(temp);
                              } else {
                                setSelectedBudget?.([
                                  ...(selectedBudget || []),
                                  item?.value,
                                ]);
                              }
                            }}
                            isChecked={selectedBudget?.includes(item?.value)}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              {interestAttr?.source && interestAttr?.source?.length > 0 && (
                <div className="col-md-6 col-sm-12">
                  <CheckButton
                    label={"Sources"}
                    id={"all_sources"}
                    onClick={() => {
                      if (
                        selectedSources?.length === interestAttr?.source?.length
                      ) {
                        setSelectedSources?.([]);
                      } else {
                        const allSourceValues = interestAttr?.source?.map(
                          (item) => item.value
                        );
                        if (allSourceValues) {
                          setSelectedSources?.(allSourceValues);
                        }
                      }
                    }}
                    isChecked={
                      selectedSources?.length === interestAttr?.source?.length
                    }
                  />
                  {interestAttr?.source?.map((item, index) => {
                    return (
                      <div className="project-section" key={index}>
                        <CheckButton
                          label={item.label}
                          id={item.value}
                          onClick={() => {
                            if (selectedSources?.includes(item?.value)) {
                              const temp = selectedSources?.filter(
                                (f) => f !== item?.value
                              );
                              setSelectedSources?.(temp);
                            } else {
                              setSelectedSources?.([
                                ...(selectedSources || []),
                                item?.value,
                              ]);
                            }
                          }}
                          isChecked={selectedSources?.includes(item?.value)}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="btn-section">
          <div className="d-flex justify-content-between">
            <button
              className="primary-btn"
              onClick={() => {
                setPage?.(1);
              }}
            >
              Back
            </button>
            {!showEdit ? (
              <button
                className="primary-btn"
                onClick={() => {
                  onsubmit?.();
                }}
              >
                {loading ? (
                  <CustomLoader
                    type="TailSpin"
                    color="#FFFFFF"
                    height={25}
                    width={52}
                  />
                ) : (
                  <span>Submit</span>
                )}
              </button>
            ) : (
              <button
                className="primary-btn"
                onClick={() => {
                  onEdit?.();
                }}
              >
                {loading ? (
                  <CustomLoader
                    type="TailSpin"
                    color="#FFFFFF"
                    height={25}
                    width={52}
                  />
                ) : (
                  <span>Update</span>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
