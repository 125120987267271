import React from "react";
import { INoticeDetail, ITenderAttributes } from "../../../Components/utils/interface";
import "./NoticeDetails.scss";


export interface ResponseAdvertisement {
  big_image_url: string;
  image_div_ratio: number;
  location: string;
  redirect_url: string;
  small_image_url: string;
}

interface NoticeDetailProps {
  noticeDetail: INoticeDetail | undefined;
  advertisement: ResponseAdvertisement | undefined;
  adLoading?: boolean;
  noticeLoading?: boolean;
}

export const NoticeDetail: React.FC<NoticeDetailProps> = ({
  noticeDetail,
  advertisement,
}) => {
  const rewards = noticeDetail?.award_result;
  return (
    <div className="notice-details-section">
      <div className="card card-section">
        {/* <img src={advertisement?.big_image_url} alt="Not Found" /> */}
        <div className="notice-content">
          <h2 className="small-content first-content">IFB RFP EOI PQ no: </h2>
          <p className="small-content second-content">
            {noticeDetail?.tender_number}
          </p>
        </div>
        <div className="notice-content">
          <h2 className="small-content first-content">Project Title: </h2>
          <p className="small-content second-content">{noticeDetail?.title}</p>
        </div>
        <div className="notice-content">
          <h2 className="small-content first-content">Public Entity Name: </h2>
          <p className="small-content second-content">
            {noticeDetail?.public_entity_name}
          </p>
        </div>
        <div className="notice-content">
          <h2 className="small-content first-content">Procurement Type: </h2>
          <p className="small-content second-content">
            {noticeDetail?.procurement_type?.map(
              (item: ITenderAttributes, index: number) => {
                if (index !== noticeDetail?.procurement_type.length - 1) {
                  return item.label + ",";
                }
                return item.label;
              }
            )}
          </p>
        </div>
        <div className="notice-content">
          <h2 className="small-content first-content">Published Date: </h2>
          <p className="small-content second-content">
            {noticeDetail?.publishing_date}
          </p>
        </div>
        <div className="notice-content">
          <h2 className="small-content first-content">Project Category: </h2>
          <p className="small-content second-content">
            {noticeDetail?.project_category?.map(
              (item: ITenderAttributes, index: number) => {
                if (index !== noticeDetail?.project_category.length - 1) {
                  return item.label + ",";
                }
                return item.label;
              }
            )}
          </p>
        </div>
        <div className="notice-content">
          <h2 className="small-content first-content"> Notice Category:</h2>
          <p className="small-content second-content">
            {noticeDetail?.notice_category}
          </p>
        </div>{" "}
        <div className="notice-content">
          <h2 className="small-content first-content"> Source:</h2>
          <p className="small-content second-content">{noticeDetail?.source}</p>
        </div>{" "}
        <div className="notice-content">
          <h2 className="small-content first-content"> </h2>
          <p className="small-content second-content"></p>
        </div>{" "}
        {/* <div className="notice-content">
          <h2 className="small-content first-content"> Tender Price:</h2>
          <p className="small-content second-content">Tender Upto 2 crore</p>
        </div> */}
      </div>

      <div className="card card-section">
        {rewards && rewards.length > 0 && (
          <div className="reward-section">
            <h2 className="small-content first-content">Awarded/Work to:</h2>
            <ul>
              {rewards.map((reward) => {
                return (
                  <li className="small-content second-content">
                    ID: {reward?.tender_number}, {reward?.company}
                    (Rs. {reward?.price})
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
