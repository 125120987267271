import "./ItWorks.scss";
export const ItWorks = () => {
  return (
    <div className="works-section">
      <div className="container">
        <div className="card">
          <h5 className="text-header">How It Works</h5>

          <div className="works-classify">
            <div className="work-source">
              <div className="uparrow-img">
                <span className="count">01</span>
                <img src={require("../../images/up.png")} alt="images" />
              </div>
              <div className="work-content">
              <p>Registration for Bidder</p>
              <p>& Publisher</p>
              </div>
                
            </div>
            <div className="work-source">
              <div className="downarrow-img">
                <span className="count">02</span>
                <img
                  src={require("../../images/down.png")}
                  alt="images"
                />
              </div>
              <div className="work-content">
              <p>Tender Published On Portal</p>
              <p>& Newspaper</p>
              </div>
            </div>
            <div className="work-source">
              <div className="">
                <span className="count">03</span>
                <img src={require("../../images/up.png")} alt="images" />
              </div>

              <p className="work-content">E-Tendering System</p>
            </div>
            <div className="work-source">
              <div className="downarrow-img">
                <span className="count">04</span>
                <img
                  src={require("../../images/down.png")}
                  alt="images"
                />
              </div>

              <p className="work-content"> Easy Evaluation</p>
            </div>
            <div className="work-source">
              <div className="">
                <span className="count">05</span>
              </div>
              <p className="work-content"> Online Contract Award</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
