import { mdiPlus } from "@mdi/js";
import { Icon } from "@mdi/react";
import React from "react";
import { ifAnyOfJointVentureFieldIsFilled } from "../../utils/services";
import { AddJointVenture } from "../AddJointVenture/AddJointVenture";
import { DetailBid } from "../BidDetails/BidDetails";
import { BidFeeProps } from "../BidFee/BidFee";
import {
  DocumentResponse,
} from "../EditBids/EditBids";
import { useBidFormikContext } from "../FormikWrapper/BidFornikWrapper";
import { Note } from "../Info";
import { CustomLoader } from "../Loader/Loader";
import "./JointVenture.scss";
export interface Props {
  page?: number;
  setPage: (e: number) => void;
}

export interface IJointVenture {
  joint_venture_name: string | undefined;
  venture_agreement: string | undefined;
  power_of_attorney: string | undefined;
  partner_document: string | undefined;
  tender_notice_id: string | undefined;
  type: string;
}

export interface JointVentureProps extends BidFeeProps {
  submitDocument?: any;
  resDocument?: DocumentResponse;
  reFetchData?: () => void;
  documentNeed?:string;
  catalogueFiles?:string;
  bidDetail?: DetailBid;
}

export const JointVenture: React.FC<JointVentureProps> = ({
  resDocument,
  reFetchData
}) => {
  const { values, isSubmitting, setFieldValue } = useBidFormikContext();

  const onClose=()=>{
    const statesToReset=["joint_venture_name","venture_agreement","power_of_attorney","partner_document"]
    setFieldValue("jointVenture.add_venture",!values.jointVenture.add_venture)
    statesToReset.forEach((val)=>{
      setFieldValue(`jointVenture.${val}`,"")
    })
    
  }

  return (
    <>
      <div className="joint-venture">
        <div className="boq-bill">
          <div className="info-flex">
            {values.jointVenture.add_venture ? (
              <AddJointVenture reFetchData={reFetchData} resDocument={resDocument} />
            ) : (
            <Note>
               Do you want to form a joint venture for this bid? If yes, please click on ‘Add Joint Venture’ button. Otherwise you can click "Save and Next" for proceed.
            </Note>
            )}
          </div>
          {!values.jointVenture.add_venture && (
            <div>
              <button
                onClick={() =>
                  setFieldValue(
                    "jointVenture.add_venture",
                    !values.jointVenture.add_venture
                  )
                }
                className="medium-content"
                type="button"
              >
                <Icon className="mdi-plus" path={mdiPlus} />
                Add Joint Venture
              </button>
            </div>
          )}
        </div>
        
        <div className="position-end">
          {
            //@ts-ignore
           (ifAnyOfJointVentureFieldIsFilled(resDocument?.joint_venture_documents as IJointVenture) ||  values.jointVenture.add_venture) && 
            <button className="btn-cancel" 
            onClick={onClose}
            >
              Remove
            </button>
          }
          <button
            className="transparent-btn "
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CustomLoader
                type="TailSpin"
                width={120}
                height={20}
                color="#0052a7"
              />
            ) : (
              <span>Save and Next</span>
            )}
          </button>
        </div>
      </div>
    </>
  );
};
