import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BodyDataBidder, getBidderDetail } from "../Api/GetApi";
import { DetailBid } from "../BidDetails/BidDetails";
import "./BidDetailPage.scss";
import { BidDetails } from "../BidDetails/BidDetails";
import { Link } from "react-router-dom";
import BidDetailStepper from "../BidDetailStepper/BidDetailStepper";
import { NotFound } from "../NotFound/NotFound";
import { MyBidLoader } from "../MyBidLoader/MyBidLoader";
import { decryptData, getDateTime } from "../../utils/services";
import { DocumentResponse, IBoqCatalogueFiles } from "../EditBids/EditBids";
import { useSearchParams } from "react-router-dom";
import { ResponseBoq } from "../utils/interface";

export interface BankDetail {
  account_number: number | string;
  account_name: string;
  branch: string;
  bank_name: string;
  bid_bond_security_amount: string | number;
  bid_bond_validity?: number;
}

export const BidDetailPage = () => {
  const dateNow = new Date();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(
    Number(searchParams.get("active_tab")) || 1
  );
  const [bidDetail, setBidDetail] = useState<DetailBid>();
  const [editBoq, setEditBoq] = useState<ResponseBoq>();
  const [bankDetail, setBankDetail] = useState<BankDetail>();
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [_, setRemainingDays] = useState<number>();
  const [documentNeed, setDocumentNeed] = useState<string>();
  const [bidFeeAmount, setBidFeeAmount] = useState<number>();
  const [catalogueFiles, setCatalogueFile] = useState("");
  const [boqFinancialFiles, setBoqFinancialFile] = useState("");
  const [resDocument, setResDocument] = useState<DocumentResponse>();
  const [boqFinancialResDocs, setboqFinancialResDocs] =
    useState<IBoqCatalogueFiles>();
  const [optionalDoc, setOptionalDoc] = useState("");

  const fetchSpecificTender = () => {
    setLoading(true);
    getBidderDetail(data)
      .then((res) => {
        let resTenderData = res.data.data.tender_notice;
        let resDocumentData = res.data.data.documents;
        setboqFinancialResDocs(res?.data?.data?.documents?.boq_financial_files);
        setDocumentNeed(resTenderData.document_needed);
        setCatalogueFile(resTenderData.boq_catalog_name);
        setBoqFinancialFile(resTenderData.boq_financial_documents);
        setOptionalDoc(resTenderData.optional_documents);
        setResDocument(resDocumentData);
        setRemainingDays(resTenderData.remaining_days);
        setBankDetail({
          bank_name: resTenderData.bank_name,
          branch: resTenderData.branch,
          account_number: resTenderData.account_number,
          account_name: resTenderData.account_name,
          bid_bond_security_amount: resTenderData.bid_bond_security_amount,
          bid_bond_validity: resTenderData.bid_bond_validity,
        });
        setBidDetail({
          tender_number: resTenderData.tender_number,
          pubilshing_date: resTenderData.publishing_date,
          submission_date: resTenderData.submission_date,
          title: resTenderData.title,
          procurement_type: resTenderData.procurement_type,
          project_category: resTenderData.project_category,
          notice_category: resTenderData.notice_category,
          district: resTenderData.district,
          public_entity_name: resTenderData.public_entity_name,
          isPrivate: resTenderData.private,
          ppmoFileUrl: resTenderData?.ppmo_file_url,
          publisherId: resTenderData?.user,
          image: resTenderData?.image,
          boq: resTenderData?.boq,
          savebids: resTenderData?.savebids,
          category: resTenderData?.category,
          opening_date: resTenderData?.opening_date,
          notes: resTenderData?.notes,
          terms: resTenderData?.terms,
          specification_terms_format: resTenderData?.specification_terms_format,
          show_boq_item_rate: resTenderData?.show_boq_item_rate,
          technical_pass: resTenderData.technical_pass,
          isVat: resTenderData.is_vat,
          hide_bidder: resTenderData.hide_bidder,
          additional_attributes: resTenderData.additional_attributes,
          systemBidFee: resTenderData.system_bid_fee ? true : false,
          isMain: resTenderData.is_main,
          user_info:resTenderData?.user_info, 
          award_type: resTenderData?.award_type
        });
        setBidFeeAmount(
          resTenderData.system_bid_fee || resTenderData.publisher_bid_fee
        );
        let bidBoq = JSON.parse(decryptData(resTenderData?.bid_boq));
        setEditBoq(
          Number(bidBoq?.boq_details.length) === 0 ? resTenderData?.boq : bidBoq
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const data: BodyDataBidder = {
    tender_notice_id: id,
    bidder_id: "",
  };

  useEffect(() => {
    window.localStorage.removeItem("pathname-bid");
    fetchSpecificTender();
  }, []);

  return (
    <>
      {loading ? (
        <div className="spinner">
          <MyBidLoader />
        </div>
      ) : (
        <>
          {editBoq && bidDetail && bankDetail ? (
            <>
              <BidDetails bidDetail={bidDetail} detailView={false} />
              <div className="borderline-btn"></div>
              <div className="detail-wrapper ">
                <div className="container">
                  {dateNow.getTime() <=
                  getDateTime(bidDetail?.submission_date).getTime() ? (
                    <BidDetailStepper
                      setPage={setPage}
                      page={page}
                      bidFee={bidFeeAmount}
                      boq={editBoq}
                      bankDetail={bankDetail}
                      documentNeed={documentNeed}
                      bidDetail={bidDetail}
                      catalogueFiles={catalogueFiles}
                      resDocument={resDocument || ({} as DocumentResponse)}
                      reFetchData={fetchSpecificTender}
                      boqFinancialFiles={boqFinancialFiles}
                      boqFinancialResDocs={boqFinancialResDocs}
                      optionalDoc={optionalDoc}
                    />
                  ) : (
                    <>
                      <div className="expire-bid">
                        <div className="imageCenter">
                          <img
                            className="expiry-img"
                            src={require("../../images/expired.png")}
                            alt="expire-img"
                          />
                        </div>
                        <div>
                          <div className="error" style={{ padding: "1rem" }}>
                            Submission Date has been over.
                          </div>
                          <Link to="/">
                            <button className="search-btn">
                              Check for other tenders{" "}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div>
              <NotFound message="Something Went Wrong" />
            </div>
          )}
        </>
      )}
    </>
  );
};
