import Axios from "./AxiosInstance"

export const logout = () => {
  return Axios.delete("/user_session")
}

export const deleteTenderNotice = (tender_notice_id: string | number) => {
  return Axios.delete(`/tender_notice/${tender_notice_id}`)
}

export const deleteBid = (tender_notice_id: string | number) => {
  return Axios.delete(`/my_bid/delete/${tender_notice_id}`)
}
