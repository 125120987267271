import { useEffect, useState } from "react";
import { Procurement } from "../Procurement/Procurement";
import "./UserInterestStepper.scss";
import { getTenderAttrs } from "../Api/GetApi";
import { BudgetSources } from "../BudgetSources/BudgetSources";
import { toast } from "react-toastify";
import { postUserInterest } from "../Api/PostApi";
import { useNavigate, useParams } from "react-router-dom";
import { EditProcurement } from "../Api/PutApi";
import { useAuth } from "../Context/AuthContext";
import { Option } from "../SelectComponent/SelectComponent";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { CustomLoader } from "../Loader/Loader";

export interface CategoryInterface {
  value: string;
  label: string;
  proc_id: string;
}

export interface ITenderAttr {
  procurement_type: Array<CategoryInterface>;
  project_category: Array<CategoryInterface>;
  estimated_cost: Array<CategoryInterface>;
  source: Array<CategoryInterface>;
}

interface UserInteresetStepperProps {
  setEditOpen?: any;
  showEdit?: boolean;
  savedProject?: any;
  savedEstimatedCost?: any;
  savedSources?: any;
}

export const UserInterestStepper: React.FC<UserInteresetStepperProps> = ({
  setEditOpen,
  showEdit,
  savedProject,
  savedEstimatedCost,
  savedSources,
}) => {
  const { userProfile, userData } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [interestAttr, setInterestAttr] = useState<ITenderAttr>();
  const [loading, setLoading] = useState(false);
  const [selectedProcurements, setSelectedProcurements] = useState<
    Array<string>
  >([]);
  const [selectedProjects, setSelectedProjects] = useState<Array<string>>([]);
  const [selectedBudget, setSelectedBudget] = useState<Array<string>>([]);
  const [selectedSources, setSelectedSources] = useState<Array<string>>([]);

  const fetchTenderAttr = () => {
    setLoading(true);
    getTenderAttrs()
      .then((res) => {
        setInterestAttr(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(
          err.response.status,
          err.response.data.status.status_message
        );
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTenderAttr();
  }, []);

  const onsubmit = () => {
    setLoading(true);
    var postData = {
      user_id: id ? id : userData?.id,
      procurement_types: selectedProcurements,
      project_categories: selectedProjects,
      sources: selectedSources,
      estimated_costs: selectedBudget,
    };
    postUserInterest(postData)
      .then((res) => {
        toast.success(res.data.status.status_message);
        setLoading(false);
        navigate("/");
      })
      .catch((err) => {
        toast.error(
          err.response.status,
          err.response.data.status.status_message
        );
        setLoading(false);
      });
  };

  const onEdit = () => {
    setLoading(true);
    var postData = {
      user_id: id ? id : userData?.id,
      procurement_types: selectedProcurements,
      project_categories: selectedProjects,
      sources: selectedSources,
      estimated_costs: selectedBudget,
    };
    EditProcurement(postData)
      .then((res) => {
        toast.success(res.data.status.status_message);
        userProfile();
        setEditOpen(false);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(
          err.response.status,
          err.response.data.status.status_message
        );
        setLoading(false);
      });
  };

  return (
    <div className="user-interest">
      <div className="d-flex justify-content-between top-header-part">
        <div className="form-section">
          <button
            type="button"
            className={page === 1 ? "primary-btn" : "btn white-btn"}
            onClick={() => {
              setPage(1);
            }}
          >
            Interests
          </button>
          <button
            type="button"
            className={page === 2 ? "primary-btn" : "btn white-btn"}
            onClick={() => {
              setPage(2);
            }}
          >
            Budget and Sources
          </button>
        </div>
        {showEdit && (
          <div
            className="close-icon"
            onClick={() => {
              setEditOpen(false);
            }}
          >
            <Icon className=" mdi-close" path={mdiClose} />
          </div>
        )}
      </div>

      {page === 1 ? (
        <Procurement
          setSelectedProcurements={setSelectedProcurements}
          selectedProcurements={selectedProcurements}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
          loading={loading}
          setPage={setPage}
          interestAttr={interestAttr}
          savedProject={savedProject}
          setEditOpen={setEditOpen}
        />
      ) : (
        <BudgetSources
          setPage={setPage}
          interestAttr={interestAttr}
          setSelectedBudget={setSelectedBudget}
          selectedBudget={selectedBudget}
          setSelectedSources={setSelectedSources}
          selectedSources={selectedSources}
          onsubmit={onsubmit}
          onEdit={onEdit}
          showEdit={showEdit}
          savedEstimatedCost={savedEstimatedCost}
          savedSources={savedSources}
          loading={loading}
        />
      )}
    </div>
  );
};
