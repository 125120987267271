import React, { useEffect, useState } from "react";
import "./Document.scss";
import { Props } from "../JointVenture/JointVenture";
import { FormikErrors } from "formik";
import { DropZoneBox } from "../pdfDropzone/pdfDropzone";
import { IBidDocumentsResponse, ITenderDocuments } from "../EditBids/EditBids";
import { UploadedFile } from "../UploadedFile/UploadedFile";
import { CustomLoader } from "../Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { BidDocumentsTab } from "../Context/BidContext/BidContext";
import {
  BidDocumentInitialValues,
  useBidFormikContext,
} from "../FormikWrapper/BidFornikWrapper";
import { BidDetailTabs } from "../BidDetailStepper/BidDetailStepper";
import { EoiDocumentSubmitValidationSchema } from "../FormikWrapper/BidalidationSchemas";
import { postBidSubmission } from "../Api/PostApi";
import { toast } from "react-toastify";
import {
  CheckTechnicalPassStatus,
  convertToFormData,
  isNoticeCategoryEOI,
} from "../../utils/services";
import { DetailBid } from "../BidDetails/BidDetails";
import Modal from "react-modal";
import { ConfirmationPopUp } from "../PopUp/ConfirmationPopUp";
import { Note } from "../Info";
import { catch500Error } from "../utils/catchError";
import { DocumentType } from "../../utils/EnumConstants";

export interface DocumentProps extends Props {
  documentNeed?: string | undefined;
  submitRequiredDocument?: any;
  resDocument?: ITenderDocuments;
  isNoticeEOI?: Boolean;
  catalogueFiles?: string;
  bidDetail?: DetailBid;
  boqFinancialFiles?: string;
  optionalDoc?: string;
  reFetchData?: () => void;
}

export interface IOnlyDocuments {
  bid_letter: string | File;
  declaration_letter: string | File;
  tax_clearence: string | File;
  applicants_information: string | File;
  experience: string | File;
  capacity: string | File;
  qualification: string | File;
  citizenship: string | File;
}

export interface IDocument {
  bid_letter: string;
  declaration_letter: string;
  tax_clearence: string;
  applicants_information: string;
  experience: string;
  capacity: string;
  qualification: string;
  citizenship: string;
  tender_notice_id: string | undefined;
  type: string;
  filledDocuments?: IFilledDocuments;
  document_needed?: string;
}

type IFilledDocuments = {
  [key: string]: string;
};

export const Document: React.FC<DocumentProps> = ({
  setPage,
  documentNeed,
  resDocument,
  catalogueFiles,
  bidDetail,
  optionalDoc,
  reFetchData
}) => {
  const { id } = useParams();
  const { errors, values, setFieldError, isSubmitting, setFieldValue } =
    useBidFormikContext();
  const [isOpen, setIsOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const isNoticeEOI = isNoticeCategoryEOI(bidDetail?.notice_category as string);
  const technicalPass =
    CheckTechnicalPassStatus(bidDetail?.technical_pass as boolean) !== null;
  const navigate = useNavigate();

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const EoiBidSubmision = (data: BidDocumentInitialValues) => {
    const obj = { ...data.jointVenture, ...data.documents };
    const formdata = convertToFormData(obj);
    setSubmitLoading(true);
    postBidSubmission(formdata, data.documents.tender_notice_id)
      .then((res) => {
        toast.success("Bid submitted");
        navigate("/my-ebids");
      })
      .catch((err) => {
        catch500Error(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const documentSubmission = (data: BidDocumentInitialValues) => {
    EoiDocumentSubmitValidationSchema(
      documentNeed as string,
      catalogueFiles as string
    )
      .validate(data, { abortEarly: false })
      .then(() => {
        toggleModal();
      })
      .catch((error: any) => {
        error.inner.forEach((error: any) => {
          setFieldError(error.path, error.message);
        });
      });
  };

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
    },
  };

  useEffect(() => {
    if (resDocument) {
      const completeResDocument = {
        ...resDocument.joint_venture_documents,
      };
      setFieldValue("jointVenture.filledDocuments", completeResDocument);
    }
  }, [resDocument]);

  const formatDocumentName = (documentName: string) => {
    if (documentName === "tax_clearence") {
      documentName = "tax_clearance";
    }
    let name = documentName.replace("_", " ");
    return name;
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        style={customModalStyles}
        ariaHideApp={false}
      >
        <ConfirmationPopUp
          popUpContents={{
            question1: "Are you sure want to submit?",
            warn: "Note: Your company documents will be auto forwarded from your profile to publisher for bidding process. Make sure, you have uploded correct and upto date documents",
            aprroveBtn: "Submit",
            cancelBtn: "Cancel",
          }}
          toggleModal={toggleModal}
          onAprroveButton={() => {
            EoiBidSubmision(values);
          }}
          activeButtonLoading={submitLoading}
          acceptTerm={true}
        />
      </Modal>
      <div className="document-wrapper-block">
        <div className="wrapper-block">
          <Note>
            From your profile, your PAN and Company Certificates will be
            auto-forwarded for Bidding purposes.
          </Note>
          {documentNeed &&
            documentNeed.split(",").map((item: string) => {
              return (
                <>
                  <div>
                    <h5 className="drop-title"> {formatDocumentName(item)}</h5>
                    {resDocument &&
                      resDocument?.bid_documents[
                        item as keyof IBidDocumentsResponse
                      ] && (
                        <UploadedFile
                          url={
                            resDocument?.bid_documents[
                              item as keyof IBidDocumentsResponse
                            ] as string
                          }
                          tenderId={id}
                          documentType={DocumentType.DOCUMENT}
                          documentLabel={item}
                          deleteDocument={true}
                          reFetchData={reFetchData}
                        />
                      )}
                    <div className="upload-venture">
                      <DropZoneBox
                        name={`documents.${item}`}
                        placeholder="Drag file here or Choose files from your device"
                        label={`Upload document here *`}
                      />
                      <span className="error">
                        {
                          //@ts-ignore
                          errors?.documents?.[item]
                        }
                      </span>
                    </div>
                  </div>
                  <div className="border-line"></div>
                </>
              );
            })}
          {Boolean(catalogueFiles) && (
            <div className="catalogue-files">
              {/* <span className="catalogue-sub-title">Catalogue Files</span> */}
              {catalogueFiles?.split(",").map((item: string) => {
                return (
                  <>
                    <div>
                      <h5 className="drop-title">{formatDocumentName(item)}</h5>
                      {resDocument &&
                        resDocument?.boq_catalogue_files[item]["file_url"] && (
                          <UploadedFile
                            url={
                              resDocument?.boq_catalogue_files[item][
                                "file_url"
                              ] as string
                            }
                            tenderId={id}
                            documentType={DocumentType.CATALOGUE}
                            documentLabel={item}
                            deleteDocument={true}
                            reFetchData={reFetchData}
                          />
                        )}
                      <div className="upload-venture">
                        <DropZoneBox
                          name={`documents.${item}`}
                          placeholder="Drag file here or Choose files from your device"
                          label={`Upload document here *`}
                        />
                        <span className="error">
                          {
                            //@ts-ignore
                            errors?.documents?.[item]
                          }
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          )}

          {Boolean(optionalDoc) && (
            <div className="catalogue-files">
              {optionalDoc?.split(",").map((item: string) => {
                return (
                  <>
                    <div>
                      <h5 className="drop-title">
                        {formatDocumentName(item)}
                        {"(optional)"}
                      </h5>
                      {resDocument &&
                        resDocument?.optional_documents &&
                        resDocument?.optional_documents?.[item]?.[
                          "file_url" 
                        ] && (
                          <UploadedFile
                            url={
                              resDocument?.optional_documents[item][
                                "file_url"
                              ] as string
                            }
                            tenderId={id}
                            documentType={DocumentType.OPTIONAL}
                            documentLabel={item}
                            deleteDocument={true}
                            reFetchData={reFetchData}
                          />
                        )}
                      <div className="upload-venture">
                        <DropZoneBox
                          name={`documents.${item}`}
                          placeholder="Drag file here or Choose files from your device"
                          label={`Upload document here *`}
                        />
                        <span className="error">
                          {
                            //@ts-ignore
                            errors?.documents?.[item]
                          }
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          )}
          <div className="botton">
            <button
              type="button"
              className="save-btn float-left"
              onClick={() => {
                setPage(BidDetailTabs.JOINT_VENTURE);
              }}
            >
              Back
            </button>
            <div className="save-section">
              <button
                className="transparent-btn"
                type="submit"
                disabled={
                  errors && errors.documents
                    ? Boolean(
                        Object.entries(
                          errors?.documents as FormikErrors<BidDocumentsTab>
                        ).length > 0
                      )
                    : false
                }
              >
                {isSubmitting ? (
                  <CustomLoader
                    type="TailSpin"
                    width={35}
                    height={25}
                    color="#0052a7"
                  />
                ) : (
                  <span>{isNoticeEOI ? "Save" : "Save & Next"}</span>
                )}
              </button>
              {isNoticeEOI && technicalPass && (
                <button
                  type="button"
                  className="save-btn"
                  onClick={() => {
                    documentSubmission(values);
                  }}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
