import "react-tabs/style/react-tabs.scss";
import { Stepper } from "../Stepper/Stepper";
import "./RegisterStepper.scss";

export const RegisterStepper = () => {
  return (
    <div className="fee-wrapper">
      <div className="bids-pages">
        <div className="bids-tabsection">
          <Stepper />
        </div>
      </div>
    </div>
  );
};
