import { BannerType } from "../../utils/EnumConstants";
import { isBidder } from "../../utils/services";
import { useAuth } from "../Context/AuthContext";
import "./PublisherBannerShimmer.scss";

interface PublisherBannerShimmerProps {
  bannerType: BannerType;
}

export const PublisherBannerShimmer: React.FC<PublisherBannerShimmerProps> = ({
  bannerType,
}) => {
  const { role } = useAuth();
  return (
    <div className="publisher-banner-shimmer-page">
      <div className="shimmer-overlay"></div>
      <div className="card-section">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="details-section">
                <div className="logo-section"></div>
                <div className="details">
                  <p className="shimmer-text big-text"></p>
                  <p className="shimmer-text small-text"></p>
                  <p className="shimmer-text small-text"></p>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div
                className={`banner-detail ${
                  bannerType === BannerType.E_NOTICE
                    ? "justify-content-start"
                    : "justify-content-end"
                }`}
              >
                {bannerType === BannerType.BID ||
                  (bannerType === BannerType.E_NOTICE && (
                    <>
                      <p className="shimmer-text big-text"></p>
                      <div className="row">
                        <div className="col-md-6">
                          <p className="shimmer-text small-text"></p>
                          <p className="shimmer-text small-text"></p>
                        </div>
                        <div className="col-md-6">
                          <p className="shimmer-text small-text"></p>
                          <p className="shimmer-text small-text"></p>
                        </div>
                      </div>
                    </>
                  ))}
                {bannerType !== BannerType.E_NOTICE && isBidder(role) && (
                  <div className="btn-section">
                    <p className="shimmer-text btn-text"></p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
