import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import { mdiClose, mdiTrashCan } from "@mdi/js";
import { Formik, Form, Field, FormikHelpers } from "formik";
import "../EditDetails/EditCompanyDetails.scss";
import { DropZoneBox } from "../pdfDropzone/pdfDropzone";
import { ErrorMessage } from "formik";
import { OrganizationCategory } from "../utils/OrganizationCategory";
import { useAuth } from "../Context/AuthContext";
import * as Yup from "yup";
import { Municipalities } from "../../Datas/Municipalities";
import { Provinces } from "../../Datas/Provinces";
import CustomSelect from "../SelectComponent/SelectComponent";
import { NepaliDistricts } from "../../Datas/Districts";
import PhoneInput from "react-phone-input-2";
import { CustomLoader } from "../Loader/Loader";
import { UpdateCompanyDetails } from "../Api/PutApi";
import { toast } from "react-toastify";
import { isPublisher, shouldShowEditCompanyPopup } from "../../utils/services";
import { catchError } from "../utils/catchError";
import { UploadedFile } from "../UploadedFile/UploadedFile";
import { Role } from "../../utils/EnumConstants";
export interface Props {
  toggleModal?: () => void;
  handleLaterClick?: () => void;
}

export interface CompanyDetails {
  company_name: string;
  office_email: string;
  company_contact_no: string;
  municipality: string;
  district: string;
  organization_categiry: string;
  province: string;
  website_url: string;
  registration_certificate_front?: any;
  registration_certificate_back?: any;
  pan_vat_certificate?: any;
  liscense_front?: any;
  liscense_back?: any;
}
export interface Password {
  password: string;
}
const validationSchema = Yup.object({
  company_name: Yup.string()
    .required("Required")
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces"),
  province: Yup.string().required("Required"),
  district: Yup.string().required("Required"),
  municipality: Yup.string().required("Required"),
  company_contact_no: Yup.string()
    .required("Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .min(7, "Phone number is not valid"),

  registration_certificate_front: Yup.mixed().when(
    "hasRegistrationCertificate",
    {
      is: true,
      then: Yup.mixed().test(
        "registration_certificate_front",
        "File size exceeds maximum limit of 3MB",
        (value) => !value || (value && value.size <= 3000000)
      ),
      otherwise: Yup.mixed()
        .test(
          "registration_certificate_front",
          "File size exceeds maximum limit of 3MB",
          (value) => !value || (value && value.size <= 3000000)
        )
        .test(
          "fileFormat",
          "Company Registration Certificate (Front) Required! Pdf only",
          (value) => {
            return value && ["application/pdf"].includes(value.type);
          }
        ),
    }
  ),

  registration_certificate_back: Yup.mixed().test(
    "fileFormat",
    "Required! PDF only",
    (value) => {
      if (value) {
        return ["application/pdf"].includes(value.type);
      }
      return true;
    }
  ),

  pan_vat_certificate: Yup.mixed().when("hasPanVatCertificate", {
    is: true,
    then: Yup.mixed().test(
      "pan_vat_certificate",
      "File size exceeds maximum limit of 3MB",
      (value) => !value || (value && value.size <= 3000000)
    ),
    otherwise: Yup.mixed()
      .test(
        "pan_vat_certificate",
        "File size exceeds maximum limit of 3MB",
        (value) => !value || (value && value.size <= 3000000)
      )
      .test(
        "fileFormat",
        "PAN / VAT Certificate Required! Pdf only",
        (value) => {
          return value && ["application/pdf"].includes(value.type);
        }
      ),
  }),

  liscense_front: Yup.mixed().test(
    "fileFormat",
    "Required! PDF only",
    (value) => {
      if (value) {
        return ["application/pdf"].includes(value.type);
      }
      return true;
    }
  ),

  liscense_back: Yup.mixed().test(
    "fileFormat",
    "Required! PDF only",
    (value) => {
      if (value) {
        return ["application/pdf"].includes(value.type);
      }
      return true;
    }
  ),

  logo: Yup.mixed().when("role", {
    is: (role: string) => role === Role.Publisher,
    then: Yup.mixed()
      .test(
        "logo",
        "File size exceeds maximum limit of 3MB",
        (value) => {
          if (typeof value === 'string' || value === undefined) {
            return true; 
          }
          return value.size <= 3000000;
        }
      )
      .test("fileFormat", "Company Logo Required! Image only", (value) => {
        return (
          value &&
          [
            "image/jpeg",
            "image/png",
            "image/gif",
            "image/bmp",
            "image/svg+xml",
            "image/tiff",
            "image/webp",
            "image/x-icon",
          ].includes(value.type)
        );
      }),
    otherwise: Yup.mixed().notRequired() 
  }),
  
  introduction: Yup.string().when("role", {
    is: (role: string) => role === Role.Publisher,
    then: Yup.string().required("Required"),
    otherwise: Yup.string().nullable(),
  }),
});

const EditCompanyDetails: React.FC<Props> = ({
  toggleModal,
  handleLaterClick,
}) => {
  const { role, userProfile, IsAuthenticate, userData, eligible } = useAuth();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<any>(null);
  const { showCompanyPopup } = shouldShowEditCompanyPopup(
    IsAuthenticate,
    eligible,
    userData?.email
  );

  const editCompanyDetails = (
    val: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    const formData = new FormData();
    Object.entries(val).forEach(([key, value]: any) => {
      formData.append(key, value);
    });
    UpdateCompanyDetails(formData)
      .then((res) => {
        userProfile();
        IsAuthenticate();
        toast.success(res.data.status.status_message);
        handleLaterClick?.();
        toggleModal?.();
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const handleDelete = () => {
    setImagePreviewUrl(null);
  };
  const years: any[] = [];
  for (let year = 1900; year <= new Date().getFullYear(); year++) {
    years.push({ label: year, value: year });
  }

  useEffect(() => {
    setImagePreviewUrl(userData?.logo);
  }, [userData?.logo]);

  return (
    <div className="edit-details">
      <div className="top-head">
        <div className="update-topic">Update Company Details</div>
        <div
          className="close-icon"
          onClick={() => {
            if (!eligible) {
              handleLaterClick?.();
            } else {
              toggleModal?.();
            }
          }}
        >
          <Icon className=" mdi-close" path={mdiClose} />
        </div>
      </div>
      <Formik
        initialValues={{
          company_name: userData?.company_name,
          office_email: userData?.office_email,
          company_contact_no: userData?.company_contact_no,
          municipality: userData?.municipality,
          district: userData?.district,
          organization_category: userData?.organization_category,
          province: userData?.province,
          website_url:
            userData?.website_url === "undefined" ? "" : userData?.website_url,
          registration_certificate_front: "",
          registration_certificate_back: "",
          pan_vat_certificate: "",
          liscense_front: "",
          liscense_back: "",
          hasRegistrationCertificate: Boolean(
            userData?.registration_certificate_front
          ),
          hasPanVatCertificate: Boolean(userData?.pan_vat_certificate),
          logo: "",
          introduction: userData?.introduction ?? null,
          estd: Number(userData?.estd) ?? null,
          employee_no: userData?.employee_no ?? null,
          role: role,
        }}
        validationSchema={validationSchema}
        onSubmit={editCompanyDetails}
      >
        {({ isSubmitting, values, setValues, setFieldValue }) => (
          <Form>
            <div className="row">
              {isPublisher(role) && (
                <>
                  <div className="col-lg-3 mb-2">
                    {!imagePreviewUrl && (
                      <div className="custom-dropzone">
                        <label className="form-label">Company Logo</label>
                        <DropZoneBox
                          name="logo"
                          placeholder="Select Logo"
                          label="Company Logo *"
                          handleChange={(e) => {
                            if (e) {
                              const previewUrl = URL.createObjectURL(e);
                              setImagePreviewUrl(previewUrl);
                              setFieldValue("logo", e);
                            }
                          }}
                        />
                      </div>
                    )}
                    {imagePreviewUrl && (
                      <>
                        <label className="form-label">Company Logo</label>
                        <div className="image-section">
                          <img src={imagePreviewUrl} alt="Company Logo" />
                          <div
                            className="close-icon"
                            title="Delete"
                            onClick={() => {
                              setFieldValue("logo", "");
                              handleDelete();
                            }}
                          >
                            <Icon className=" mdi-trash" path={mdiTrashCan} />
                          </div>
                        </div>
                      </>
                    )}
                    <span className="error">
                      <ErrorMessage name="logo" />
                    </span>
                  </div>
                  <div className="col-lg-9 col-sm-12 mb-2">
                    <div className="mg-bottom">
                      <label className="form-label">About Company</label>
                      <Field
                        name="introduction"
                        type="text"
                        placeholder="Enter introduction of company"
                        className="form-control about_company_textarea"
                        as="textarea"
                        rows="5"
                      />
                      <span className="error">
                        <ErrorMessage name="introduction" />
                      </span>
                    </div>
                  </div>
                </>
              )}
              <div className="col-md-6 mb-2">
                <label className="form-label">Company Name</label>
                <Field
                  name="company_name"
                  type="text"
                  className="form-control"
                />
                <span className="error">
                  <ErrorMessage name="company_name" component="span" />
                </span>
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label">Official Email</label>
                <Field
                  name="office_email"
                  type="text"
                  className="form-control"
                />
                <span className="error">
                  <ErrorMessage name="office_email" component="span" />
                </span>
              </div>

              {isPublisher(role) && (
                <div className="col-md-12 mb-2">
                  <label className="form-label">Organization Category</label>
                  <Field
                    as="select"
                    name="organization_category"
                    className="form-control"
                    aria-label=".form-select-sm example"
                  >
                    <option value="" selected>
                      Choose Organization Category
                    </option>
                    {OrganizationCategory.map((item, id) => {
                      return (
                        <>
                          <option key={id} value={item.value}>
                            {item.label}
                          </option>
                        </>
                      );
                    })}
                  </Field>
                  <span className="error">
                    <ErrorMessage
                      name="organization_category"
                      component="span"
                    />
                  </span>
                </div>
              )}
              {isPublisher(role) && (
                <>
                  <div className="col-lg-6 col-sm-12 mb-2">
                    <div className="mg-bottom">
                      <label className="form-label">
                        Year of Establishment
                      </label>
                      <Field
                        className="abc"
                        name="estd"
                        options={years}
                        component={CustomSelect}
                        placeholder="Select established year"
                        isMulti={false}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 mb-2">
                    <div className="mg-bottom">
                      <label className="form-label">Number of Employees</label>
                      <Field
                        name="employee_no"
                        type="text"
                        placeholder="Enter number of employees"
                        className="form-control"
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-6 mb-2">
                <label className="form-label">Contact Number</label>
                <PhoneInput
                  inputStyle={{ width: "100%" }}
                  country={"np"}
                  value={values.company_contact_no}
                  onChange={(value: string) => {
                    setValues({
                      ...values,
                      company_contact_no: value,
                    });
                  }}
                />
                <span className="error">
                  <ErrorMessage name="company_contact_no" component="span" />
                </span>
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label">Website URL</label>
                <Field
                  name="website_url"
                  type="text"
                  className="form-control"
                />
                <span className="error">
                  <ErrorMessage name="website_url" component="span" />
                </span>
              </div>

              <div className="col-md-4 col-sm-12 mb-2">
                <label className="form-label">Province</label>
                <Field
                  className="abc"
                  name="province"
                  options={Provinces}
                  component={CustomSelect}
                  placeholder="Select a province"
                  isMulti={false}
                />
                <span className="error">
                  <ErrorMessage name="province" component="span" />
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mb-2">
                <label className="form-label">District</label>
                <Field
                  className="abc"
                  name="district"
                  options={NepaliDistricts}
                  component={CustomSelect}
                  placeholder="Select a district"
                  isMulti={false}
                />
                <span className="error">
                  <ErrorMessage name="district" component="span" />
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mb-2">
                <label className="form-label">Municipality</label>

                <Field
                  className="abc"
                  name="municipality"
                  options={Municipalities}
                  component={CustomSelect}
                  placeholder="Select a municiplaity"
                  isMulti={false}
                />

                <span className="error">
                  <ErrorMessage name="municipality" component="span" />
                </span>
              </div>
            </div>

            <div className="">
              {/* <div className="drop-border"></div> */}
              <p className="upload-venture"> Upload Document</p>
              <div className="row">
                <div className="col-lg-12">
                  <DropZoneBox
                    name="pan_vat_certificate"
                    placeholder="Upload PAN/VAT certificate *"
                    label="PAN / VAT Certificate *"
                  />
                  <span className="error">
                    <ErrorMessage name="pan_vat_certificate" />
                  </span>
                  <br></br>
                  {userData?.pan_vat_certificate && (
                    <UploadedFile url={userData?.pan_vat_certificate} />
                  )}
                </div>
                <div className="col-md-6 col-sm-12">
                  <DropZoneBox
                    name="registration_certificate_front"
                    placeholder="Upload Company Registration Certificate (Front) *"
                    label="Company Registration Certificate (Front) *"
                  />
                  <span className="error">
                    <ErrorMessage name="registration_certificate_front" />
                  </span>
                  <br></br>
                  {userData?.registration_certificate_front && (
                    <UploadedFile
                      url={userData?.registration_certificate_front}
                    />
                  )}
                </div>

                <div className="col-md-6 col-sm-12">
                  <DropZoneBox
                    name="registration_certificate_back"
                    placeholder="Upload Company Registration Certificate (Back)"
                    label="Company Registration Certificate (Back)"
                  />
                  <span className="error">
                    <ErrorMessage name="registration_certificate_back" />
                  </span>
                  <br></br>
                  {userData?.registration_certificate_back && (
                    <UploadedFile
                      url={userData?.registration_certificate_back}
                    />
                  )}
                </div>

                <div className="col-md-6 col-sm-12">
                  <DropZoneBox
                    name="liscense_front"
                    placeholder="Upload License (Front)"
                    label="License (Front)"
                  />
                  <span className="error">
                    <ErrorMessage name="liscense_front" />
                  </span>
                  <br></br>
                  {userData?.liscense_front && (
                    <UploadedFile url={userData?.liscense_front} />
                  )}
                </div>

                <div className="col-md-6 col-sm-12">
                  <DropZoneBox
                    name="liscense_back"
                    placeholder="Upload License (Back)"
                    label="License (Back)"
                  />
                  <span className="error">
                    <ErrorMessage name="liscense_back" />
                  </span>
                  <br></br>
                  {userData?.liscense_back && (
                    <UploadedFile url={userData?.liscense_back} />
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="line-section primary-section">
                  {showCompanyPopup && (
                    <button
                      className="btn-cancel"
                      onClick={() => {
                        handleLaterClick?.();
                      }}
                    >
                      I'll do it later
                    </button>
                  )}
                  <button
                    className="search-btn pd-btn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <div>
                        <CustomLoader
                          color="white"
                          width={40}
                          height={25}
                          type="TailSpin"
                        />
                      </div>
                    ) : (
                      <span>Update</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditCompanyDetails;
