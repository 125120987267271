import { mdiClose, mdiInformationOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import "../../Components/RejectPopup/RejectPopup.scss";
export interface Props {
  toggleModal?: () => void;
  tender_notice_id?: string | number;
  bidder_id?: string | number;
  onReject?: any;
}
export interface TenderRejectInitial {
  tender_notice_id: string | number;
  bidder_id: string | number;
  rejection_reason: string;
}

const validate = Yup.object({
  rejection_reason: Yup.string()
    .max(60, "Only 60 characters are allowed.")
    .required("Required"),
});
export const NewRejectPopup: React.FC<Props> = ({
  toggleModal,
  tender_notice_id,
  bidder_id,
  onReject,
}) => {
  return (
    <Formik
      initialValues={{
        tender_notice_id: tender_notice_id,
        bidder_id: bidder_id,
        rejection_reason: "",
      }}
      onSubmit={(values) => {
        onReject(values);
      }}
      validationSchema={validate}
    >
      <Form>
        <div className="reject-bid ">
          <div className="container">
            <div className="overlay"></div>
            <div className="close-icon" onClick={toggleModal}>
              <Icon className=" mdi-close" path={mdiClose} />
            </div>
            <div className="info-logo">
              <Icon className="mdi-alert" path={mdiInformationOutline}></Icon>
            </div>
            <div>
              <h5 className="text-title">Disqualify Bid!</h5>
              <p className="sub-header">
                Are you sure you want to disqualify Bid?
              </p>
            </div>

            <div className="popup-body">
                <label className="form-label">Disqualify Reason:</label>
                <Field
                  as="textarea"
                  name="rejection_reason"
                  className="form-control"
                  aria-describedby="emailHelp"
                />
                <span className="error">
                  <ErrorMessage name="rejection_reason" />
                </span>
            </div>

            <div className="popup-btn">
              <div>
                <button
                  className="btn-cancel"
                  onClick={toggleModal}
                  type="button"
                >
                  Cancel disqualify bid
                </button>
              </div>
              <div>
                <button className="btn-withdraw" type="submit">
                  Yes, disqualify bid
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
