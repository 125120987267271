import { useState } from "react";
import { toast } from "react-toastify";
import { sendRegisterOTP } from "../Api/PostApi";
import RegisterFormikWrapper from "../FormikWrapper/RegisterFormikWrapper";
import { OTPConfirmation } from "../StepperOne/OTPConfirmation";
import { OnbordingOne } from "../StepperOne/StepperOne";
import "./Stepper.scss";
import { catchError } from "../utils/catchError";

export const Stepper = () => {
  const [Page, setPage] = useState(1);
  const [check1, setCheck1] = useState(true);
  const [check2, setCheck2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(false);
  const [skipLoading, setSkipLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const handleSendOtp = (email: string) => {
    const data = {
      email: email,
    };
    setOtpLoading(true);
    sendRegisterOTP(data)
      .then((res) => {
        if (res.status === 200) {
          setPage(2);
          toast.success(res.data.status.status_message);
          setOtpLoading(false);
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        setOtpLoading(false);
      });
  };

  return (
    <RegisterFormikWrapper
      page={Page}
      setPage={setPage}
      loading={loading}
      setLoading={setLoading}
      skipLoading={skipLoading}
      setSkipLoading={setSkipLoading}
      skip={skip}
      handleSendOtp={handleSendOtp}
    >
      <div className="Stepper">
        <div className="card-section">
          <div className="form-section">
            <button
              type="button"
              className={Page === 1 ? "btn primary-btn" : "btn white-btn"}
              onClick={() => {
                setPage(1);
              }}
            >
              Personal Details
            </button>
            <button
              type="button"
              disabled
              className={Page === 2 ? "btn primary-btn" : "btn white-btn"}
              onClick={() => {
                setPage(2);
              }}
            >
              OTP
            </button>
          </div>
          {Page === 1 ? (
            <OnbordingOne
              setPage={setPage}
              Page={Page}
              check1={check1}
              setCheck1={setCheck1}
              check2={check2}
              setCheck2={setCheck2}
              setSkipValue={setSkip}
              otpLoading={otpLoading}
            />
          ) : (
            <OTPConfirmation
              Page={Page}
              setPage={setPage}
              onResendOTP={handleSendOtp}
            />
          )}
        </div>
      </div>
    </RegisterFormikWrapper>
  );
};
