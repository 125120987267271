import { useEffect } from "react";
import { CheckButton } from "../CheckButton/CheckButton";
import { CustomLoader } from "../Loader/Loader";
import { ITenderAttr } from "../UserInterestStepper/UserInterestStepper";
import "./Procurement.scss";

export interface ProcurementProps {
  savedProject?: any;
  setEditOpen?: any;
  interestAttr?: ITenderAttr;
  setPage?: (page: number) => void;
  loading?: boolean;
  setSelectedProcurements?: (selectedProcurements: Array<string>) => void;
  selectedProcurements?: Array<string>;
  selectedProjects?: Array<string>;
  setSelectedProjects?: (selectedProjects: Array<string>) => void;
}
export const Procurement: React.FC<ProcurementProps> = ({
  savedProject,
  interestAttr,
  setPage,
  loading,
  setSelectedProcurements,
  selectedProcurements,
  selectedProjects,
  setSelectedProjects,
}) => {
  useEffect(() => {
    if (savedProject) {
      const newArr = savedProject?.map((item: any) => {
        return item?.id;
      });

      setSelectedProjects?.(newArr);
    }
  }, []);

  useEffect(() => {
    interestAttr?.procurement_type?.map((item) => {
      return interestAttr?.project_category.filter(
        (itm) => item.value === itm?.proc_id
      );
    });
  }, [interestAttr?.procurement_type]);

  useEffect(() => {
    const tempArray: string[] = selectedProcurements
      ? [...selectedProcurements]
      : [];
    savedProject &&
      interestAttr?.project_category.map((item) => {
        const savedProjectIds = savedProject.map(
          (val: { name: string; id: number }) => {
            return val.id;
          }
        );
        if (savedProjectIds.includes(item.value)) {
          if (!tempArray.includes(item.proc_id)) {
            tempArray.push(item.proc_id);
          }
        }
      });
    setSelectedProcurements?.(tempArray || []);
  }, [savedProject, interestAttr]);

  return (
    <div className="procurement">
      <div className="title-block">
        <h4 className="title">Choose Your Interest</h4>
      </div>

      <div className="procurement-block">
        <div className={`interested-block`}>
          <h4 className="title mb-20">
            The procurement and Projects you are interested
          </h4>
          {!interestAttr?.procurement_type ? (
            <CustomLoader
              color="#0052a7"
              type="TailSpin"
              height={20}
              width={105}
            />
          ) : (
            <div className="row">
              {interestAttr?.procurement_type.map((item, index) => {
                const projects = interestAttr?.project_category.filter(
                  (itm) => itm?.proc_id === item.value
                );
                const newArr = projects?.map((s) => s.value);

                return (
                  <div className="col-md-3">
                    <CheckButton
                      label={item.label}
                      id={item.value}
                      onClick={() => {
                        if (selectedProcurements?.includes(item?.value)) {
                          const temp = selectedProcurements?.filter(
                            (f) => f !== item?.value
                          );
                          setSelectedProcurements?.(temp);

                          const newTemp =
                            selectedProjects?.filter(
                              (el) => !newArr.includes(el)
                            ) ?? [];

                          setSelectedProjects?.(newTemp);
                        } else {
                          setSelectedProcurements?.([
                            ...(selectedProcurements || []),
                            item?.value,
                          ]);
                          const newTemp = projects?.map((s) => s.value);
                          setSelectedProjects?.([
                            ...(selectedProjects || []),
                            ...newTemp,
                          ]);
                        }
                      }}
                      isChecked={newArr.every((x) =>
                        selectedProjects?.includes(x)
                      )}
                    />
                    {projects?.map((itm, idx) => {
                      const exists = selectedProjects?.find(
                        (p) => p === itm.value
                      );

                      return (
                        <div className="project-section">
                          <CheckButton
                            label={itm.label}
                            id={itm.value}
                            onClick={() => {
                              if (selectedProjects?.includes(itm?.value)) {
                                const temp = selectedProjects?.filter(
                                  (f) => f !== itm?.value
                                );
                                setSelectedProjects?.(temp);
                                if (!newArr.some((x) => temp.includes(x))) {
                                  const newTemp =
                                    selectedProcurements?.filter(
                                      (f) => f !== item?.value
                                    ) ?? [];
                                  setSelectedProcurements?.(newTemp);
                                }
                              } else {
                                setSelectedProjects?.([
                                  ...(selectedProjects || []),
                                  itm?.value,
                                ]);

                                if (
                                  selectedProcurements?.includes(itm?.proc_id)
                                ) {
                                } else {
                                  setSelectedProcurements?.([
                                    ...(selectedProcurements || []),
                                    itm?.proc_id,
                                  ]);
                                }
                              }
                            }}
                            isChecked={Boolean(exists)}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div style={{ textAlign: "right" }}>
          <button
            className="primary-btn"
            onClick={() => {
              setPage?.(2);
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
