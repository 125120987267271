function BidderTerm() {
  return (
    <div className="container company-content">
      <h2 style={{ textAlign: "center" }}>
        <strong>BIDDER TERM</strong>
      </h2>
      <div className="row">
        <div className="mar">
          <p style={{ margin: "10px" }}>
            <span style={{ fontWeight: 400 }}>
              TERMS AND CONDITIONS FOR BIDDERS ON BOLPATRA NEPAL
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>GENERAL</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              1.1 These terms and conditions (<strong>"Agreement"</strong>)
              govern the use of the Bolpatra Nepal tender portal (
              <strong>"Portal"</strong>) by bidders (<strong>"Bidders"</strong>
              ).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.2 By registering on the Portal and submitting a bid, the Bidder
              agrees to be bound by these terms and conditions.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.3 The Portal is owned and operated by Bolpatra Nepal and
              provides a platform for Private Businesses, Government Sector &
              Other Organizations to advertise the bids / procurement
              opportunities.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>BID SUBMISSION</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              2.1 All bids submitted on the Portal must be complete and
              accurate.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.2 Bidders are responsible for ensuring that their bids are
              submitted before the deadline specified on the Portal.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.3 Bolpatra Nepal is not responsible for any bids that are not
              received due to technical issues or other reasons beyond its
              control.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.4 Bidders are responsible for any costs associated with
              preparing and submitting their bids.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.5 Bids may be rejected if they do not comply with the
              requirements specified in the tender documents or if they contain
              false or misleading information.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.6 Our system automatically sends the bidder's company documents
              to the publisher when a bid is submitted.Bidders must complete
              their Bolpatra profile with True and Updated Document before
              submitting any bids.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>AWARDING TENDER</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              3.1 A tender may not only be awarded based on price, Publishers
              may also consider quality and technical evaluation when selecting
              a bidder.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.2 The Publisher is responsible for evaluating the technical and
              financial proposals of bidders. Choosing bidders and awarding
              tenders is the publisher's responsibility.
            </span>
          </p>
        </div>

        <ol>
          <li>
            <strong>REFUND</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              4.1 If Bid fee Refund has to be made after tender cancellation by
              Bolpatra Nepal. Bolpatra Nepal will make a Refund to bidder in a
              timely manner.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.2 Publisher has to refund Bid bond security amount to the Bidder
              in the case of Bid cancellation or after the completion of Bidding
              process.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.3 In the event of a violation in the bid process, publishers
              might not refund the bidder's security amount (
              <strong>Bid Bond</strong>).
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>CONFIDENTIALITY</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              5.1 Bolpatra Nepal keeps confidential all information obtained
              through the Bidder that is marked as confidential.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.2 Bidders shall not use any information obtained through the
              Portal for any purpose other than submitting a bid.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>INTELLECTUAL PROPERTY</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              6.1 All intellectual property rights in the Portal and its
              contents are owned by Bolpatra Nepal.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              6.2 Bidders may not use any of the intellectual property on the
              Portal for any purpose other than submitting a bid.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong> LIMITATION OF LIABILITY</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              8.1 Bolpatra Nepal shall not be liable for any damages arising out
              of or in connection with the use of the Portal, including but not
              limited to direct, indirect, incidental, consequential, or
              punitive damages.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.2 Bidders agree to indemnify and hold Bolpatra Nepal harmless
              from any and all claims, liabilities, damages, and expenses
              arising out of or in connection with their use of the Portal.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>GOVERNING LAW AND JURISDICTION</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              9.1 This Agreement shall be governed by and construed in
              accordance with the laws of Nepal.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              9.2 Any dispute arising out of or in connection with this
              Agreement shall be subject to the exclusive jurisdiction of the
              courts of Nepal.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default BidderTerm;
