import { useRef, useState, useEffect } from "react";
export const useTimer = () => {
  const RESEND_INTERVAL_S = 45;
  const [time, setTime] = useState(RESEND_INTERVAL_S);

  let timerRef = useRef<any>();

  useEffect(() => reStartTimer(), []);

  const clearTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      setTime(RESEND_INTERVAL_S);
    }
  };

  const reStartTimer = () => {
    clearTimer();
    timerRef.current = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearTimer();
          return 0;
        }
      });
    }, 1000);
  };

  return { time, reStartTimer, clearTimer };
};
