import { RegisterInfo } from "../RegisterInfo/RegisterInfo";
import { UserInterestStepper } from "../UserInterestStepper/UserInterestStepper";
import "./UserInterest.scss";

export interface CategoryInterface {
  value: string;
  label: string;
  proc_id: string;
}

export const UserInterest: React.FC = ({}) => {
  return (
    <div className="user-interest section-gap">
      <div className="container">
        <div className="row">
          <div className="col-md-4 pr-0">
            <RegisterInfo />
          </div>
          <div className="col-md-8 pl-0">
            <UserInterestStepper />
          </div>
        </div>
      </div>
    </div>
  );
};
