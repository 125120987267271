import "./CompanyPolicy.scss";

function CompanyPolicy() {
  return (
    <div className="container company-content">
      <h2 style={{ textAlign: "center" }}>
        <strong>COMPANY POLICY</strong>
      </h2>
      <div className="row">
        <div className="mar">
          <p style={{ margin: "10px" }}>
            <span style={{ fontWeight: 400 }}>
              PRIVACY POLICY FOR BOLPATRA NEPAL
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>INTRODUCTION</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              1.1 <strong>Bolpatra Nepal</strong> (<strong>"Company" </strong>or{" "}
              <strong>"we"</strong>) is committed to protecting the privacy of
              its users (<strong>"Bidders"</strong> or{" "}
              <strong>"Publisher"</strong>).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.2 This Privacy Policy explains how we collect, use, and disclose
              information from and about our Users, including personal
              information.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.3 By using our website, bolpatranepal.com (
              <strong>"Website"</strong>) or any services provided by Bolpatra
              Nepal, you agree to the terms of this Privacy Policy.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>INFORMATION WE COLLECT</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              2.1 Personal Information. We may collect personal information from
              Users, such as name, email address, phone number, and other
              official information & Documents, when Users register for an
              account or use certain features of the Website.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.2 Usage Information. We may collect usage information about
              Users, including IP address, browser type, operating system, pages
              viewed, and the time and date of visits to the Website.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.3 Cookies. We may use cookies, web beacons, and other similar
              technologies to collect information about Users and their usage of
              the Website.
            </span>
          </p>
        </div>

        <ol>
          <li>
            <strong>HOW WE USE INFORMATION</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              3.1 We may use the information we collect for the following
              purposes:
            </span>
            <ol style={{ listStyle: " disc inside", textAlign: "start" }}>
              <li>
                {" "}
                <span style={{ fontWeight: 400 }}>
                  To provide and improve our services and Website;
                </span>
              </li>
              <li>
                {" "}
                <span style={{ fontWeight: 400 }}>
                  To communicate with Users about their accounts or our
                  services;
                </span>
              </li>
              <li>
                {" "}
                <span style={{ fontWeight: 400 }}>
                  To respond to User inquiries and requests;
                </span>
              </li>
              <li>
                {" "}
                <span style={{ fontWeight: 400 }}>
                  To personalize User experience on the Website;
                </span>
              </li>
              <li>
                {" "}
                <span style={{ fontWeight: 400 }}>
                  To send email and messages to Users;
                </span>
              </li>
              <li>
                {" "}
                <span style={{ fontWeight: 400 }}>
                  To enforce our policies and comply with legal requirements.
                </span>
              </li>
            </ol>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.2 We may also use de-identified information or aggregate
              information for research, statistical analysis, and other
              purposes.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>HOW WE SHARE INFORMATION</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              4.1 We may share information with third-party service providers
              who perform services on our behalf, such as hosting, Server, Cloud
              Storage and other services.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.3 We may disclose information in response to a subpoena, court
              order, or other legal requirement, or to protect our rights or the
              rights of others.
            </span>
          </p>
        </div>

        <ol>
          <li>
            <strong>DATA SECURITY</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              5.1 We take reasonable measures to protect the information we
              collect from and about Users from unauthorized access, disclosure,
              alteration, and destruction.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.2 However, no data transmission or storage system can be
              guaranteed to be 100% secure. Users are responsible for
              maintaining the confidentiality of their account information and
              password.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>YOUR CHOICES</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              6.1 Users may <b>choose to opt-out</b> of receiving messages or email
              from us by following the unsubscribe option to our support team.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              6.2 Users may also <b>choose to disable cookies</b> in their browser
              settings, but this may affect their ability to use certain
              features of the Website.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              6.3 Users have the <b>right to request</b> that their account and any
              associated data acquired by our website be <b>deleted</b>. Users can
              initiate the account deletion procedure by sending an email to our
              administration at info@bolpatranepal.com requesting the deletion
              of their account and personal information or visit page <a href='/request-account-deletion'>Request Account Deletion</a>.
              <p>
                When we receive a valid deletion request, we shall take
                reasonable steps to remove the User's account and any related
                personal information from our active databases. Please keep in
                mind that certain data may be retained in our backups or
                archives for a limited time as required by relevant legislation
                or for legitimate business purposes.
              </p>
              <p>
                Users should be informed that canceling their account may result
                in the loss of access to certain Bolpatra Nepal features and
                services. Furthermore, some information may be maintained in
                aggregated or de-identified form that does not directly identify
                the User.
              </p>
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>CHILDREN'S PRIVACY</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              7.1 Our Website and services are not intended for children under
              the age of 13. We do not knowingly collect personal information
              from children under the age of 13.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>CHANGES TO THIS PRIVACY POLICY</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              8.1 We may update this Privacy Policy from time to time. The
              updated policy will be posted on the Website and the effective
              date will be indicated.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>CONTACT US</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              9.1 If you have any questions or concerns about this Privacy
              Policy or our data practices, please contact us at.
            </span>
          </p>
        </div>
        <p style={{ textAlign: "center", margin: "20px" }}>
          <strong>Phone No- (+977) 9849447788 / 9802347788</strong>
        </p>
        <p style={{ textAlign: "center" }}>
          <span style={{ fontWeight: 400 }}>
            <strong>Email</strong>-{" "}
            <span>
              <strong>info@bolpatranepal.com</strong>
            </span>
          </span>
        </p>
      </div>
    </div>
  );
}

export default CompanyPolicy;
