import { useEffect, useState } from "react";
import { mdiAlertCircleOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useParams } from "react-router-dom";
import { BodyDataBidder, getBidderDetail } from "../Api/GetApi";
import { DetailBid } from "../BidDetails/BidDetails";
import "../BidDetailPage/BidDetailPage.scss";
import { BidDetails } from "../BidDetails/BidDetails";
import { Link } from "react-router-dom";
import BidDetailStepper from "../BidDetailStepper/BidDetailStepper";
import "./EditBids.scss";
import { MyBidLoader } from "../MyBidLoader/MyBidLoader";
import { decryptData, getDateTime } from "../../utils/services";
import { useSearchParams } from "react-router-dom";
import { NotFound } from "../NotFound/NotFound";
import { catchError } from "../utils/catchError";

export interface IVentureDocuments {
  venture_agreement: string;
  power_of_attorney: string;
  partner_document: string;
  joint_venture_name: string;
}

export interface IBidDocumentsResponse {
  bid_letter?: string;
  declaration_letter?: string;
  boq_catalog_file?: string;
  tax_clearence?: string;
  applicants_information?: string;
  experience: string;
  capacity: string;
  qualification: string;
  citizenship: string;
}

export interface IBidFeeDocuments {
  e_payment: string;
  voucher: string;
  bid_fee_voucher: string;
  bid_fee_voucher_date: string;
}

export type IBoqCatalogueFiles = {
  [key: string]: {
    file_url: string;
  };
};
export interface ITenderDocuments {
  joint_venture_documents: IVentureDocuments;
  fee: IBidFeeDocuments;
  bid_documents: IBidDocumentsResponse;
  boq_catalogue_files: IBoqCatalogueFiles;
  boq_financial_files: IBoqCatalogueFiles;
  optional_documents: IBoqCatalogueFiles;
}

export interface DocumentResponse extends ITenderDocuments {
  company_document: {
    registration_certificate_front: string;
    registration_certificate_back: string;
    pan_vat_certificate: string;
    liscense_back: string;
    liscense_front: string;
  };
}

export interface BankDetail {
  account_number: number | string;
  account_name: string;
  branch: string;
  bank_name: string;
  bid_bond_security_amount: number | string;
  bid_bond_validity?: number;
}

export const EditBidPage = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(
    Number(searchParams.get("active_tab")) || 1
  );
  const [bidDetail, setBidDetail] = useState<DetailBid>();
  const [editBoq, setEditBoq] = useState<any>();
  const [bankDetail, setBankDetail] = useState<BankDetail>();
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [documentNeed, setDocumentNeed] = useState<string>();
  const [resDocument, setResDocument] = useState<DocumentResponse>();
  const [bidFee, setBidFee] = useState<number>();
  const [catalogueFiles, setCatalogueFile] = useState<string>();
  const [boqFinancialFiles, setBoqFinancialFile] = useState("");
  const [boqFinancialResDocs, setboqFinancialResDocs] =
    useState<IBoqCatalogueFiles>();
  const [optionalDoc, setOptionalDoc] = useState("");

  const data: BodyDataBidder = {
    tender_notice_id: id,
    bidder_id: "",
  };

  const dateNow = new Date();
  const fetchBidDocument = () => {
    setLoading(true);
    getBidderDetail(data)
      .then((res) => {
        let resTenderData = res.data.data.tender_notice;
        let resDocumentData = res.data.data.documents;
        setboqFinancialResDocs(res?.data?.data?.documents?.boq_financial_files);
        setDocumentNeed(resTenderData.document_needed);
        setCatalogueFile(resTenderData.boq_catalog_name);
        setBoqFinancialFile(resTenderData.boq_financial_documents);
        setResDocument(resDocumentData);
        setOptionalDoc(resTenderData.optional_documents);
        setBankDetail({
          bank_name: resTenderData.bank_name,
          branch: resTenderData.branch,
          account_number: resTenderData.account_number,
          account_name: resTenderData.account_name,
          bid_bond_security_amount: resTenderData.bid_bond_security_amount,
          bid_bond_validity: resTenderData.bid_bond_validity,
        });
        setBidDetail({
          tender_number: resTenderData.tender_number,
          pubilshing_date: resTenderData.publishing_date,
          submission_date: resTenderData.submission_date,
          title: resTenderData.title,
          procurement_type: resTenderData.procurement_type,
          project_category: resTenderData.project_category,
          notice_category: resTenderData.notice_category,
          district: resTenderData.district,
          public_entity_name: resTenderData.public_entity_name,
          isPrivate: resTenderData.private,
          ppmoFileUrl: resTenderData?.ppmo_file_url,
          publisherId: resTenderData?.user,
          image: resTenderData?.image,
          boq: resTenderData?.boq,
          savebids: resTenderData?.savebids,
          category: resTenderData?.category,
          opening_date: resTenderData?.opening_date,
          notes: resTenderData?.notes,
          terms: resTenderData?.terms,
          specification_terms_format: resTenderData?.specification_terms_format,
          show_boq_item_rate: resTenderData?.show_boq_item_rate,
          technical_pass: resTenderData?.technical_pass,
          isVat: resTenderData.is_vat,
          hide_bidder: resTenderData.hide_bidder,
          additional_attributes: resTenderData.additional_attributes,
          systemBidFee: resTenderData.system_bid_fee ? true : false,
          isMain: resTenderData.is_main,
          user_info:resTenderData?.user_info, 
          award_type: resTenderData?.award_type
        });
        let bidBoq = JSON.parse(decryptData(resTenderData?.bid_boq));
        setBidFee(
          resTenderData?.publisher_bid_fee || resTenderData?.system_bid_fee
        );
        setEditBoq(
          bidBoq?.boq_details.length === 0 ? resTenderData?.boq : bidBoq
        );
      })
      .catch((err) => {
        catchError(
          err?.response?.status,
          err?.response?.data?.status?.status_message
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.localStorage.removeItem("pathname-bid");
    fetchBidDocument();
  }, []);

  return (
    <>
      {loading ? (
        <div className="spinner">
          <MyBidLoader />
        </div>
      ) : (
        <>
          {bidDetail ? (
            <>
              <BidDetails bidDetail={bidDetail} detailView={false} />
              <div className="borderline-btn"></div>
              <div className="detail-wrapper ">
                <div className="container">
                  {Boolean(bidDetail?.opening_date) ||
                  getDateTime(bidDetail?.submission_date).getTime() >=
                    dateNow.getTime() ? (
                    <BidDetailStepper
                      setPage={setPage}
                      page={page}
                      reFetchData={fetchBidDocument}
                      boq={editBoq}
                      bankDetail={bankDetail}
                      documentNeed={documentNeed}
                      resDocument={resDocument}
                      bidFee={bidFee}
                      bidDetail={bidDetail}
                      catalogueFiles={catalogueFiles as string}
                      boqFinancialFiles={boqFinancialFiles}
                      boqFinancialResDocs={boqFinancialResDocs}
                      optionalDoc={optionalDoc}
                    />
                  ) : (
                    <>
                      <div className="expire-bid">
                        <div className="imageCenter">
                          <img
                            className="expiry-img"
                            src={require("../../images/expired.png")}
                            alt="expire"
                          />
                        </div>
                        <div>
                          <div
                            className="red-highlight"
                            style={{ padding: "1rem" }}
                          >
                            <div>
                              <Icon
                                className="mdi-alert"
                                path={mdiAlertCircleOutline}
                              ></Icon>
                            </div>
                            Submission Date has been over. You cannot edit this
                            anymore
                          </div>
                          <Link to="/">
                            <button className="search-btn">
                              Check for other tenders
                            </button>
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <NotFound message="Something Went Wrong" />
          )}
        </>
      )}
    </>
  );
};
