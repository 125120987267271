import { mdiDownload } from "@mdi/js";
import { Icon } from "@mdi/react";
import { ErrorMessage, Field, FieldArray, FormikProps } from "formik";
import { nepaliAmountFormat } from "nepali-number";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { AdditionalAttributes } from "../FormikWrapper/TenderFormikWrapper";
import { CustomLoader } from "../Loader/Loader";
import { ConfirmationPopUp } from "../PopUp/ConfirmationPopUp";
import { DropZoneBox } from "../pdfDropzone/pdfDropzone";
import TableRows from "./TableRows";
import "./boqTable.scss";
import { QuotationBiddersList } from "../QuotationBiddersList/QuotationBiddersList";
import { CustomCkEditor } from "../customCkEditor/customCkEditor";
interface Props {
  page: number;
  setPage?: (e: number) => void;
  loading?: any;
  publishLoad?: boolean;
  edit: boolean;
  additionalColumns: string[];
  setAdditionalColumns: (value: string[]) => void;
  formikConfig: FormikProps<any>;
  enlistmentNotice?: boolean;
  tender_id?: string;
  setSelectedBidderList?: (value: number[]) => void;
}

const BoqTable: React.FC<Props> = ({
  page,
  setPage,
  loading,
  publishLoad,
  edit,
  additionalColumns,
  setAdditionalColumns,
  formikConfig,
  enlistmentNotice = false,
  tender_id,
  setSelectedBidderList,
}) => {
  const { values, handleSubmit, errors, setFieldValue, validateForm } = formikConfig;
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = React.useState("");

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const customLoginStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "40%",
    },
  };

  let arrayHelpersInstance;

  useEffect(() => {
    if (values.additional_attributes && values.additional_attributes.length > 0)
      values.boq?.map((eachBOQ: any, BOQindex: number) => {
        eachBOQ.item.map((_: any, itemIndex: number) => {
          var newAdditionalColumns = {};
          values.additional_attributes.map((newColumn: any) => {
            newAdditionalColumns = {
              ...newAdditionalColumns,
              [newColumn.column]: "",
            };
          });
          setFieldValue(
            `boq[${BOQindex}].additional_columns[${itemIndex}]`,
            newAdditionalColumns
          );
        });
      });
  }, [values.additional_attributes]);

  const onAdditionalInput = (e: any) => {
    setInputValue(e.target.value);
  };

  const onHandleRemoveAdditionalAttributes = (
    additionalColumns: string[],
    eachColumn: string
  ) => {
    const additonalColumnValues = [
      ...additionalColumns.filter((columnName) => columnName !== eachColumn),
    ];
    const editedVALUE = values.additional_attributes.filter((each: any) =>
      additonalColumnValues.includes(each.column)
    );
    setAdditionalColumns(additonalColumnValues);
    setFieldValue("additional_attributes", editedVALUE);
    values.boq?.map((eachBOQ: any, boqindex: number) => {
      eachBOQ.additional_columns.map((each: any, eachItem: string) => {
        delete each[eachColumn];
        if (Object.keys(each).length > 0) {
          setFieldValue(
            `BOQ[${boqindex}.additional_columns[${eachItem}]`,
            each
          );
        } else {
          setFieldValue(`BOQ[${boqindex}.additional_columns`, []);
        }
      });
    });
  };

  const handleOnChangeFinancialDocument = (value: boolean) => {
    setFieldValue("has_boq_financial_documents", value);
    if (!value) {
      setFieldValue("boq_financial_documents", "");
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        style={customLoginStyles}
        ariaHideApp={false}
      >
        {enlistmentNotice ? (
          <QuotationBiddersList
            toggleModal={toggleModal}
            tender_id={tender_id}
            onRequestButtonClick={(bidder_list: Array<number>) => {
              setFieldValue("saved", false);
              setSelectedBidderList?.(bidder_list);
              if (errors && Object.keys(errors).length > 0) {
                toast.error("You have got unresolved error");
                return;
              }
              handleSubmit();
              toggleModal();
            }}
          />
        ) : (
          <ConfirmationPopUp
            popUpContents={{
              question1: `Are you sure want to ${enlistmentNotice ? "ask quotation" : "publish tender"
                }?`,
              aprroveBtn: "Yes",
              cancelBtn: "Cancel",
            }}
            toggleModal={toggleModal}
            activeButtonLoading={publishLoad}
            onAprroveButton={() => {
              setFieldValue("saved", false);
              toggleModal();
              if (errors && Object.keys(errors).length > 0) {
                toast.error("You have got unresolved error");
                return;
              }
              handleSubmit();
            }}
            acceptTerm={true}
            publisherTerm={true}
          />
        )}
      </Modal>
      <div className="boq-table bordered-box">
        <div className="col-md-12">
          <div className="start-right">
            <label className="checkbox-label mg-right-8" htmlFor="radio1">
              Bidders can see your estimated item rate if you select this option
            </label>
            <Field
              name="show_boq_item_rate"
              type="checkbox"
              className="checkbox"
            />
          </div>
        </div>
        <div className="mb-3">
          <div className="row">
            <div className="col-md-7">
              {!edit && (
                <>
                  <div className="d-flex">
                    <h3 className="bold-head">
                      You can either fill the BOQ details in the table or upload
                      excel file for bulk upload.
                    </h3>
                  </div>

                  <div>
                    <div className="d-flex">
                      <h3 className="sub-title">
                        For Bulk Upload, follow these steps:
                      </h3>
                    </div>
                    <ul style={{ marginLeft: "-25px" }}>
                      <li>
                        Download the given sample file & Edit as per as your
                        requirement.
                      </li>

                      <li>Upload your file & Click Save Button.</li>
                      <li>Recheck the table for self verification.</li>
                    </ul>
                    <div className="float-right sample-file">
                      <a
                        href="https://bolpatranepal.s3.ap-south-1.amazonaws.com/Bolpatra-Sample-BOQ.csv"
                        target="_blank"
                        className=""
                        rel="noreferrer"
                      >
                        <label className="font-sample">Sample File</label>
                        <Icon className="icon-size" path={mdiDownload} />
                      </a>
                    </div>
                    <DropZoneBox
                      name="boq_file"
                      placeholder="Upload BOQ"
                      label={`Upload document here *`}
                    />
                    <span className="error">
                      <ErrorMessage name="boq_file" />
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-5 mt-auto mb-3">
              <div className="add-column-block">
                <Field
                  className="form-control mid-area"
                  type="text"
                  value={inputValue}
                  placeholder="Enter Column Title"
                  onChange={onAdditionalInput}
                />
                <button
                  type="button"
                  className="transparent-btn"
                  onClick={() => {
                    if (inputValue.length === 0) {
                      return;
                    }
                    setAdditionalColumns([...additionalColumns, inputValue]);
                    const recentAdditionalAttributes = [
                      ...values.additional_attributes,
                    ];
                    setFieldValue("additional_attributes", [
                      ...recentAdditionalAttributes,
                      { column: inputValue },
                    ]);
                    setInputValue("");
                  }}
                >
                  + Add Column
                </button>
              </div>
            </div>
          </div>

          <div className="st-line"></div>
          <div className="upper-big-box">
            <div className="boq-custom-table scrollwrapper">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="column-4">Category</th>
                    <th className="column-1">S.No.</th>
                    <th className="column-4">Item/Work Description</th>
                    <th className="column-2">Unit</th>
                    <th className="column-2">Quantity</th>
                    <th className="column-6">Rate(Currency)</th>
                    {additionalColumns &&
                      additionalColumns.map((eachColumn, eachIndex) => (
                        <th key={eachIndex} className="addtional-field">
                          <div className="d-flex  justify-content-between">
                            {eachColumn}
                            <i
                              onClick={() => {
                                onHandleRemoveAdditionalAttributes(
                                  additionalColumns,
                                  eachColumn
                                );
                              }}
                              className="bi bi-x"
                            ></i>
                          </div>
                        </th>
                      ))}
                    <th className="column-3">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <FieldArray
                    name="boq"
                    render={(arrayHelpers) => {
                      arrayHelpersInstance = arrayHelpers;

                      return (
                        <>
                          {
                            //@ts-ignore
                            values?.boq && values?.boq.length > 0
                              ? //@ts-ignore
                              values.boq.map((val: any, index: any) => (
                                <>
                                  <tr className="table-body" key={index}>
                                    <td
                                      rowSpan={2 + val?.item?.length}
                                      className="table-column column-4"
                                    >
                                      <Field
                                        as="textarea"
                                        placeholder="Enter the Item/Work Category"
                                        rows={
                                          //@ts-ignore
                                          values.boq[index].item.length * 3
                                        }
                                        name={`boq[${index}].category`}
                                      />
                                      <span className="error">
                                        <ErrorMessage
                                          name={`boq[${index}].category`}
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                  <TableRows
                                    index={index}
                                    name={`boq[${index}].item`}
                                    removeCategory={() =>
                                      arrayHelpers.remove(index)
                                    }
                                    additionalColumns={additionalColumns}
                                  />
                                </>
                              ))
                              : ""
                          }
                        </>
                      );
                    }}
                  />
                </tbody>
              </table>
              <button
                type="button"
                className="btn-show mg-btn"
                onClick={() => {
                  let additionaldata = {};
                  values.additional_attributes.forEach(
                    (eachItem: AdditionalAttributes) => {
                      additionaldata = {
                        ...additionaldata,
                        [eachItem.column]: "",
                      };
                    }
                  );
                  arrayHelpersInstance.push({
                    additional_columns: [additionaldata],
                    category: "",
                    item: [
                      {
                        fixed: false,
                        item_description: "",
                        unit: "",
                        quantity: "",
                        rate: 0,
                        blank: true,
                      },
                    ],
                  });
                }} // insert an empty string at a position
              >
                <i className="bi bi-plus"></i> Add New Category
              </button>
            </div>
            <div className="Boq-details">
              <div className="boq-details-child">
                <div className="head">
                  <span className="head-value">BOQ Total:</span>
                  <span className="head-value">
                    NPR.{" "}
                    {nepaliAmountFormat(values.boq_details?.boq_total || 0)}
                  </span>
                </div>
                <div className="head">
                  <div>
                    <Field
                      type="checkbox"
                      className="vat-checkbox"
                      name="is_vat"
                    />
                    <span className="head-value">VAT[13%]:</span>
                  </div>
                  <span className="head-value">
                    NPR. {nepaliAmountFormat(values.boq_details?.vat || 0)}
                  </span>
                </div>
                <div className="head">
                  <span className="head-value">Grand Total:</span>
                  <span className="head-value">
                    NPR.{" "}
                    {nepaliAmountFormat(values.boq_details?.grand_total || 0)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="st-line"></div>
        <div className="row  mg-top-15">
          <div className="col-md-6 base-content">
            <p className="catalog-label mg-top-5">
              Do you want any extra financial documents from bidder?
            </p>
            <div className="mg-top-15">
              <div className="check-section">
                <div id="checkbox1" className="check-box">
                  <input
                    type="radio"
                    className="radio"
                    value="true"
                    checked={values.has_boq_financial_documents}
                    onChange={() => handleOnChangeFinancialDocument(true)}
                  />
                  <label htmlFor="radio1">Yes</label>
                </div>

                <div id="radio1" className="radio-box">
                  <input
                    value="false"
                    type="radio"
                    className="radio"
                    checked={!values.has_boq_financial_documents}
                    onChange={() => handleOnChangeFinancialDocument(false)}
                  />

                  <label htmlFor="checkbox1">No</label>
                </div>
              </div>
            </div>

            {values.has_boq_financial_documents && (
              <div className="mg-top-5">
                <label className="catalog-label form-label">
                  Financial Document Name:{" "}
                  <span className="high-light">
                    Please separate multiple file names using commas(,).
                  </span>
                </label>
                <Field
                  placeholder="Specify financial document name"
                  className="form-control"
                  type="input"
                  name="boq_financial_documents"
                  aria-describedby="emailHelp"
                />
                <span className="error">
                  <ErrorMessage name="boq_financial_documents" />
                </span>
              </div>
            )}
          </div>
          {enlistmentNotice && (
            <div className="col-md-6">
              <label className="form-label">
                Last Submission Date /Deadline <span className="red">*</span>
              </label>

              <Field
                autoFocus={true}
                name="submission_date"
                type="date"
                className="form-control"
              />
              <span className="error">
                <ErrorMessage name="submission_date" />
              </span>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-md-6">
            <label className="form-label">Notes</label>
            <CustomCkEditor
              dataValue={values.notes}
              setFieldValue={(data: string) => {
                setFieldValue("notes", data);
              }}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Terms & Conditions</label>
            <CustomCkEditor
              dataValue={values.terms}
              setFieldValue={(data: string) => {
                setFieldValue("terms", data);
              }}
            />
          </div>
        </div>
      </div>

      <div className="left">
        {!enlistmentNotice && (
          <button
            type="button"
            className="btn primary-btn btn-edit"
            onClick={() => {
              setPage?.(1);
            }}
          >
            Back
          </button>
        )}

        <div style={{ float: "right" }}>
          {!enlistmentNotice && (
            <button
              className="btn primary-btn btn-edit"
              type={page === 2 ? "submit" : "button"}
              disabled={
                loading || publishLoad || Object.keys(errors).length > 0
              }
              onClick={() => {
                setFieldValue("saved", true);
              }}
            >
              {loading ? (
                <CustomLoader
                  type="TailSpin"
                  color="#FFFFFF"
                  height={22}
                  width={33}
                />
              ) : (
                <span>Save</span>
              )}
            </button>
          )}

          <button
            className="btn delete-btn btn-edit"
            type="button"
            disabled={loading || publishLoad}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              validateForm().then((errors) => {
                if (Object.keys(errors).length > 0) {
                  toast.error("Fill in all required fields with valid details");
                  return;
                }
                toggleModal();
              })
            }
            }
          >
            {publishLoad ? (
              <CustomLoader
                type="TailSpin"
                color="#ff0026"
                height={22}
                width={50}
              />
            ) : (
              <span>{enlistmentNotice ? "Request Quotation" : "Publish"}</span>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default BoqTable;
