import { mdiPhoneInTalk } from "@mdi/js";
import Icon from "@mdi/react";
import "./ContactSection.scss";

export interface ContactSectionProps {
  profileData: any;
}

export const ContactSection: React.FC<ContactSectionProps> = ({
  profileData,
}) => {
  return (
    <div className="contact-section">
      <div className="card">
        <div className="row">
          <div className="col-md-6 d-flex justify-content-start align-items-end">
            <div className="d-flex">
              <div className="icon-section mr-3">
                <Icon className="mdi-icon" path={mdiPhoneInTalk} />
              </div>
              <h5 className="text-header">{profileData?.company_contact_no}</h5>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-end flex-column align-items-end">
            <h5 className="text-header">Get in Touch</h5>
            <div className="border-div"></div>
            <div className="company-details">
              <div className="d-flex justify-content-end">
                <h4 className="block-title text-nowrap">
                  {profileData?.company_name}
                </h4>
              </div>
              <div className="icon-section">
                <img
                  src={
                    require("../../images/bolpatra verified icon-04.svg")
                      .default
                  }
                  alt="address"
                  className="contact-img"
                />
                <p className="company-detail-content">
                  {profileData?.location as string}
                </p>
              </div>
              <div className="icon-section">
                <img
                  src={
                    require("../../images/bolpatra verified icon-05.svg")
                      .default
                  }
                  alt="email"
                  className="contact-img"
                />
                <p className="company-detail-content">{profileData?.email}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
