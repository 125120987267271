import { mdiAccountCircle, mdiLogout } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import "./TopHeader.scss";
import moment from "moment";
import { ModalContext } from "../Context/ModalContext/ModalContext";
import EditCompanyDetails from "../EditDetails/EditCompanyDetails";

export const TopHeader = () => {
  const navigate = useNavigate();
  const { isAuthenticate, logOut, userData } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [orgDetailsOpen, setOrgDetailsOpen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { TogglePopUpFlags } = useContext(ModalContext);

  const customStylesOrg = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "55%",
      padding: "20px",
      maxHeight: "650px",
    },
  };

  function toggleOrgDetailsModal() {
    setOrgDetailsOpen(!orgDetailsOpen);
  }

  useEffect(() => {
    let redirect_from = searchParams.get("redirect_from");
    if (!isAuthenticate && redirect_from === "email") {
      setIsOpen(!isOpen);
    }
  }, []);

  return (
    <div className="topheader-section" style={{ display: "flex", gap: "1rem" }}>
      {isAuthenticate ? (
        <div className="dropdown">
          <div
            className="dropdown-toggle"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              src={require("../../images/Vector (2).png")}
              alt="logo"
              className="profile-img"
            />
            <p className="profile">Profile</p>
          </div>
          <div
            className=" dropdown-profile-edit dropdown-menu col-md-8 "
            aria-labelledby="dropdownMenuButton"
          >
            <div className="dropdown-item user-profile-area">
              <div className="user-img-area">
                <div className="circle">
                  <p className="circle-font">
                    {userData?.first_name.charAt(0)}
                  </p>
                </div>
                <h6 className="user-name">{userData?.full_name}</h6>
                <h6 className="membership-details">
                  User Type:
                  <span>{userData?.user_type}</span>
                </h6>
                <h6 className="membership-details">
                  Joined Date:
                  <span>
                    {moment(userData?.date_joined).format("YYYY-MM-DD")}
                  </span>
                </h6>
                <h6 className="membership-details">
                  Plan End Date:
                  <span>
                    {userData?.plan_end_date === null
                      ? "N/A"
                      : userData?.plan_end_date}
                  </span>
                </h6>

                {userData?.plan_remaining_days !== null && (
                  <h6 className="membership-details">
                    ({userData?.plan_remaining_days}
                    days remaining)
                  </h6>
                )}
              </div>

              <div className="user-details-area">
                <Link className="dropdown-item" to="/my-profile">
                  <Icon className=" mdi-close" path={mdiAccountCircle} />
                  <span className="icon-text-section"> My Profile</span>
                </Link>

                <span className="dropdown-item logout-btn">
                  <div
                    className="logout-section"
                    onClick={() => {
                      logOut(navigate);
                      setIsOpen(false);
                    }}
                  >
                    <Icon className=" mdi-close" path={mdiLogout} />
                    <span className="icon-text-section">Logout</span>
                  </div>
                </span>
              </div>
            </div>
          </div>

          {orgDetailsOpen && (
            <Modal
              isOpen={orgDetailsOpen}
              onRequestClose={toggleOrgDetailsModal}
              contentLabel="My dialog"
              style={customStylesOrg}
              ariaHideApp={false}
            >
              <EditCompanyDetails
                toggleModal={() => toggleOrgDetailsModal()}
              />
            </Modal>
          )}
        </div>
      ) : (
        <>
          <div className="d-flex">
            <button
              className=" orange-btn"
              onClick={() => {
                TogglePopUpFlags("login");
              }}
            >
              Log in
            </button>
            <Link to="/register-form">
              <button className="search-btn pd-btn">Get started</button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};
