import { useState, useEffect } from "react";
import { AdShow } from "../../Components/AdShow/AdShow";
import { TenderCardAd } from "../../Components/TenderCard/TenderCardAd";
import { Notice } from "./Notice/Notice";
import { NoticeDetail } from "./NoticeDetails/NoticeDetails";
import { useParams } from "react-router-dom";
import "./SingleNoticePage.scss";
import { getSpecificTender } from "../../Components/Api/GetApi";
import { toast } from "react-toastify";
import { MyBidLoader } from "../../Components/MyBidLoader/MyBidLoader";
import { INoticeDetail } from "../../Components/utils/interface";
import { useGlobal } from "../../Components/Context/GlobalContext";

export const SingleNoticePage = () => {
  const { id } = useParams();
  const [noticeDetail, setNoticeDetail] = useState<INoticeDetail>();
  const [noticeLoading, setNoticeLoading] = useState(false);
  const subPathName = window.location.pathname.split("/")[1];
  const { advertisements } = useGlobal();

  const fetchSpecificNotice = (id: string | undefined) => {
    var oldNotice = false;
    if (subPathName === "notices") {
      oldNotice = true;
    }
    setNoticeLoading(true);
    getSpecificTender(id, oldNotice)
      .then((res) => {
        setNoticeDetail(res.data.data);
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setNoticeLoading(false);
      });
  };

  useEffect(() => {
    fetchSpecificNotice(id);
  }, []);

  return (
    <>
      {" "}
      {noticeLoading ? (
        <div className="spinner">
          <MyBidLoader />
        </div>
      ) : (
        <div className="single-notice-page">
          <div className="cardwrapper-section">
            <div className="container">
              <div className="row">
                <div className="col-md-9 pr-0">
                  <TenderCardAd
                    advertisement={advertisements["Notice-PopUp"]?.[0]}
                  />
                  <div className="row">
                    <div className="col-md-8 pr-0">
                      <Notice noticeDetail={noticeDetail} />
                    </div>
                    <div className="col-md-4">
                      <NoticeDetail
                        noticeDetail={noticeDetail}
                        advertisement={advertisements["Notice-PopUp"]?.[1]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <AdShow />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
