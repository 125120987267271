import { findOrganizationLabelByValue } from "../../utils/services";
import { OrganizationDetail } from "./BidderDetail";
import "./BidderDetails.scss";

interface CompanyDetailsProps {
  organizationDetail: OrganizationDetail;
}
export const CompanyDetails: React.FC<CompanyDetailsProps> = ({
  organizationDetail,
}) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "50%" }}>
        <div className="d-flex">
          <p className="detail-content"> Company Name:</p>
          <p className="content-right">{organizationDetail?.company_name}</p>
        </div>
        <div className="d-flex">
          <p className="detail-content">Organization Category:</p>
          <p className="content-right">
            {findOrganizationLabelByValue(organizationDetail?.organization_category ?? "")}
          </p>
        </div>
        <div className="d-flex">
          <p className="detail-content">Office Email:</p>
          <p className="content-right">{organizationDetail?.office_email}</p>
        </div>

        <div className="d-flex">
          <p className="detail-content">Website Url:</p>
          <p className="content-right">{organizationDetail?.website_url}</p>
        </div>
      </div>
      <div style={{ width: "50%" }}>
        <div className="d-flex">
          <p className="detail-content">Company Contact no:</p>
          <p className="content-right">
            {organizationDetail?.company_contact_no}
          </p>
        </div>
        <div className="d-flex">
          <p className="detail-content">Province: </p>
          <p className="content-right">{organizationDetail?.province}</p>
        </div>

        <div className="d-flex">
          <p className="detail-content">District:</p>
          <p className="content-right">{organizationDetail?.district}</p>
        </div>
        <div className="d-flex">
          <p className="detail-content">Municipality</p>
          <p className="content-right">{organizationDetail?.municipality}</p>
        </div>
      </div>
    </div>
  );
};
