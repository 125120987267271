import "./ETenderSideShimmer.scss";

export const ETenderSideShimmer = () => {
  return (
    <div className="etender-side-shimmer-page">
      <div className="card-section">
        <div className="shimmer-overlay"></div>
        <div className="card-top">
          <div className="logo-section"></div>
          <div className="text-area">
            <p className="shimmer-text big-text"></p>
            <p className="shimmer-text big-text"></p>
            <p className="shimmer-text small-text"></p>
          </div>
        </div>
      </div>
      <div className="card-section">
        <div className="shimmer-overlay"></div>
        <div className="card-top">
          <div className="logo-section"></div>
          <div className="text-area">
            <p className="shimmer-text big-text"></p>
            <p className="shimmer-text big-text"></p>
            <p className="shimmer-text small-text"></p>
          </div>
        </div>
      </div>
      <div className="card-section">
        <div className="shimmer-overlay"></div>
        <div className="card-top">
          <div className="logo-section"></div>
          <div className="text-area">
            <p className="shimmer-text big-text"></p>
            <p className="shimmer-text big-text"></p>
            <p className="shimmer-text small-text"></p>
          </div>
        </div>
      </div>
    </div>
  );
};
