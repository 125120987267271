import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAllTenderNotice,
  getSpecificTender,
} from "../../Components/Api/GetApi";
import { useSearch } from "../../Components/Context/SearchContext";
import ENoticeBanner from "../../Components/ENoticeBanner/ENoticeBanner";
import ENoticeContent from "../../Components/ENoticeContent/ENoticeContent";
import {
  ETenderCardProps,
  ETenderSide,
} from "../../Components/ETenderSide/ETenderSide";
import { ResponseTenderCard } from "../../Components/TenderCard/TenderCardGroup";
import { catchError } from "../../Components/utils/catchError";
import { INoticeDetail } from "../../Components/utils/interface";

export const ENoticePage = () => {
  const { id } = useParams();
  const [noticeDetail, setNoticeDetail] = useState<INoticeDetail>();
  const [noticeLoading, setNoticeLoading] = useState(false);
  const subPathName = window.location.pathname.split("/")[1];
  const [loading, setLoading] = useState<Boolean>(true);
  const [eNoticeTenderCard, setENoticeTenderCard] = useState<any>([]);
  const { query, page } = useSearch();

  const fetchSpecificNotice = (id: string | undefined) => {
    var oldNotice = false;
    if (subPathName === "notices") {
      oldNotice = true;
    }
    setNoticeLoading(true);
    getSpecificTender(id, oldNotice)
      .then((res) => {
        setNoticeDetail(res.data.data);
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setNoticeLoading(false);
      });
  };

  useEffect(() => {
    fetchSpecificNotice(id);
  }, []);

  const getENotice = (page: number, notice_type: string) => {
    setLoading(true);
    return getAllTenderNotice(page, query, true, notice_type)
      .then((res) => {
        setENoticeTenderCard(res.data.data.results);
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getENotice(page, "E-Notice");
  }, [page, query]);

  const handleENoticeViewAll = (): void => {
    window.open(`/?tab=2`, "_blank");
  };

  const filteredENoticeData = useMemo(() => {
    const result: ETenderCardProps[] = eNoticeTenderCard
      .map((entry: ETenderCardProps) => {
        const filteredData =
          entry?.data?.filter(
            (item: ResponseTenderCard) => item.id.toString() !== id
          ) || [];
        return {
          date: entry.date,
          data: filteredData,
        };
      })
      .filter((entry: ETenderCardProps) => entry.data && entry.data.length > 0);

    return result;
  }, [id, eNoticeTenderCard]);

  const combinedENoticeData = useMemo(() => {
    return filteredENoticeData.flatMap((item: any) => item.data);
  }, [filteredENoticeData]);

  return (
    <div className="e-notice-page">
      <ENoticeBanner
        noticeDetail={noticeDetail}
        noticeLoading={noticeLoading}
      />
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <ENoticeContent
              noticeTitle={noticeDetail?.title}
              noticeData={noticeDetail?.notes}
              noticeLoading={noticeLoading}
            />
          </div>
          <div className="col-md-4">
            <div className="home-sidebar">
              <div className="sidebar-sticky">
                <div className="my-4">
                  <ETenderSide
                    loading={loading}
                    handleViewAll={handleENoticeViewAll}
                    sectionTitle={"Similar E-Notices"}
                    tenderCard={combinedENoticeData}
                    noticeType={"E-Notice"}
                    theme="Secondary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
