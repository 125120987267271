import Accordian from "../Accordian/Accordian";
import "../NewBanner/NewBanner.scss";
import { useEffect, useState } from "react";
import { OrganizationCategory } from "../utils/OrganizationCategory";
import { SearchQuery, useSearch } from "../Context/SearchContext";
import Select from "react-select";

export interface SelectValues {
  initialLocation: DefaultValues;
  initialProcurement: DefaultValues;
  initialProject: DefaultValues;
  initialNotice: DefaultValues;
  initialOrganization: DefaultValues;
}
export interface DefaultValues {
  label: string;
  value: string;
}
export interface InitialSelects {
  location: DefaultValues;
  notice: DefaultValues;
  procurement: DefaultValues;
  project: DefaultValues;
  date: Array<String>;
  budget: DefaultValues;
  source: DefaultValues;
  company_name: DefaultValues;
  organization: DefaultValues;
}
export const defaultSelectValues = {
  initialLocation: {
    label: "Select Location",
    value: "",
  },

  initialProcurement: {
    label: "Select Procurement Type",
    value: "",
  },
  initialProject: {
    label: "Select Project Type",
    value: "",
  },
  initialNotice: {
    label: "Select Notice",
    value: "",
  },
  initialOrganization: {
    label: "Select Organization Category",
    value: "",
  },
  initialBudget: {
    label: "Select Budget",
    value: "",
  },
  initialSource: {
    label: "Select Source",
    value: "",
  },
  initialCompanyName: {
    label: "Select Company Name",
    value: "",
  },
};
const initailValues: InitialSelects = {
  organization: defaultSelectValues.initialOrganization,
  location: defaultSelectValues.initialLocation,
  notice: defaultSelectValues.initialNotice,
  procurement: defaultSelectValues.initialProcurement,
  project: defaultSelectValues.initialProject,
  budget: defaultSelectValues.initialBudget,
  source: defaultSelectValues.initialSource,
  company_name: defaultSelectValues.initialCompanyName,
  date: [],
};

const NewBanner = () => {
  const [staticTitle, setStaticTitle] = useState("");
  const [staticOrganization, setStaticOrganization] = useState("");
  const [clearData, setClearData] = useState(false);
  const [clear, setClear] = useState(false);

  const { query, setQuery, setPage, resetQuery } = useSearch();
  const queryFlag =
    query &&
    Object.values(query).reduce((p: any, c: any) => {
      return p + c;
    });

  const [initialSelect, setInitialSelect] =
    useState<InitialSelects>(initailValues);

  const clearSearch = () => {
    setClear(false);
    setStaticTitle("");
    setStaticOrganization("");
    setClearData(true);
    resetQuery();
    setInitialSelect(initailValues);
  };

  useEffect(() => {
    resetQuery();
  }, []);

  return (
    <>
      <div className="new-banner">
        <div className="center-div">
          <div className="banner-img">
            <div id="show_bg_2"></div>
            <div className=""></div>
            <div className="centered">
              <div className="heading-section">
                <p className="topic-one">One Procurement Portal </p>
                <p className="topic-one">Covering All Bidding Eco-system</p>
              </div>
              {/* <p className="paragraph-content">
                Nepal’s first end to end e-tendering system
              </p> */}
            </div>
          </div>
          <div className="filter-search">
            <form>
              <div className="card">
                <div className="searchwrapper-content">
                  <div className="input-forms">
                    <div className="image-block">
                      <img
                        src={require("../../images/search.png")}
                        alt="searchicon"
                        className="search-icon"
                      />
                    </div>
                    <div className="search-bar">
                      <input
                        className="form-control input-contents content "
                        // type="search"
                        placeholder="Enter Keyword i.e Road Construction "
                        aria-label="Search"
                        value={staticTitle}
                        onChange={(e) => {
                          setStaticTitle(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="select-section">
                    <Select
                      isClearable={clear}
                      className="select-tab"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      // maxMenuHeight={90}
                      placeholder="Select Organization Category"
                      options={OrganizationCategory}
                      value={initialSelect.organization}
                      onChange={(e) => {
                        setClear(true);
                        setStaticOrganization(e?.value || "");
                        const updatedInitial = {
                          organization: e,
                        };
                        setInitialSelect((p: any) => ({
                          ...p,
                          ...updatedInitial,
                        }));
                      }}
                    />
                  </div>
                  <button
                    className="accordion-search-btn"
                    onClick={(e: any) => {
                      e.preventDefault();
                      const UpdatedValues = {
                        title: staticTitle,
                        organization_category: staticOrganization,
                      };
                      setPage(1);
                      setQuery((p: SearchQuery) => ({
                        ...p,
                        ...UpdatedValues,
                      }));
                    }}
                  >
                    SEARCH
                  </button>
                  <div className="right-content">
                    {queryFlag !== "" ||
                    staticOrganization !== "" ||
                    staticTitle !== "" ? (
                      <div className="content-one">
                        <div
                          className="clear-btn"
                          onClick={() => clearSearch()}
                        >
                          Clear
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <Accordian
                  initialSelect={initialSelect}
                  setInitialSelect={setInitialSelect}
                  clearData={clearData}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewBanner;
