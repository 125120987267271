import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { isNoticeCategoryStandingList } from "../../utils/services";
import { getSpecificTender } from "../Api/GetApi";
import { postEnlistmentQuotation } from "../Api/PostApi";
import BoqTable from "../boqTable/boqTable";
import EnlistmentFormikWrapper, {
  EnlistmentData,
  useEnlistmentFormik,
} from "../FormikWrapper/EnlistmentFormikWrapper";
import { catchError } from "../utils/catchError";
import { INoticeDetail } from "../utils/interface";

export interface BoqTableContentProps {
  tender_id?: string;
}

export const EnlistmentQuotation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [additionalColumns, setAdditionalColumns] = useState<string[]>([]);
  const [publishLoad, setPublishLoad] = useState(false);
  const [noticeDetail, setNoticeDetail] = useState<INoticeDetail>();
  const [selectedBidders, setSelectedBidders] = useState<Array<number>>();

  const publishEnlistment = (
    values: EnlistmentData,
    resetForm: Function,
    isUserVerified: boolean
  ) => {
    const cloned = { ...values };
    setPublishLoad(true);
    const formData: any = new FormData();
    const boq_file: any = cloned.boq_file;

    formData.append("tender_notice_id", id);
    formData.append("bidder_list", selectedBidders);
    formData.append("boq_file", boq_file); // 'file' represents the actual file object
    // Serialize the raw payload as JSON and append it as a form field
    formData.append("payload", JSON.stringify(cloned));

    postEnlistmentQuotation(formData)
      .then((res) => {
        if (res.status === 200) {
          if (!values.saved) {
            navigate(`/`);
          }
          toast.success(res.data.status.status_message);
          resetForm();
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        setPublishLoad(false);
      });
  };

  const fetchSpecificNotice = (id: string | undefined) => {
    getSpecificTender(id, false)
      .then((res) => {
        setNoticeDetail(res.data.data);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    fetchSpecificNotice(id);
  }, []);

  const BoqTableContent: React.FC<BoqTableContentProps> = ({ tender_id }) => {
    const formikConfig = useEnlistmentFormik(); // Access the Formik configuration from context

    return (
      <BoqTable
        page={2}
        loading={false}
        publishLoad={publishLoad}
        edit={false}
        additionalColumns={additionalColumns}
        setAdditionalColumns={setAdditionalColumns}
        formikConfig={formikConfig}
        enlistmentNotice={true}
        tender_id={tender_id}
        setSelectedBidderList={setSelectedBidders}
      />
    );
  };

  return (
    <div className="quotations-wrapper bids-page">
      <div className="container">
        <div className="box card">
          <div className="title-note">
            <h3 className="head-titile">Request Quotation</h3>
          </div>
          {isNoticeCategoryStandingList(noticeDetail?.notice_category ?? "") ? (
            <div className="form-section">
              <div className="W-100">
                <EnlistmentFormikWrapper
                  publishEnlistment={publishEnlistment}
                >
                  <div className="Stepper">
                    <div className="card-section">
                      <div className="form-section">
                        <button type="button" className="prime-btn">
                          Financial
                        </button>
                      </div>
                      <BoqTableContent
                        tender_id={id}
                      />
                    </div>
                  </div>
                </EnlistmentFormikWrapper>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
