import React from "react";
import "./TenderCard.scss";
export interface ResponseAdvertisement {
  big_image_url: string;
  image_div_ratio: number;
  location: string;
  redirect_url: string;
  small_image_url: string;
}

interface ResponseAdvertisementProps {
  advertisement: ResponseAdvertisement | undefined;
}
export const TenderCardAd: React.FC<ResponseAdvertisementProps> = ({
  advertisement,
}) => {
  const handleImageOnClick = (redirect_url: string) => {
    window.open(redirect_url, '_blank');
  }
  return (
    <>
      {advertisement && (
        <div className="tender-in-between-section">
          <div className="tender-in-between-block">
            <img src={advertisement?.big_image_url} alt="Img Not Found" onClick={()=> handleImageOnClick(advertisement?.redirect_url)}/>
            
          </div>
        </div>
      )}
    </>
  );
};
