import React, { useState } from "react";
import "./TechnicalPass.scss";
import { Icon } from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { CustomLoader } from "../Loader/Loader";
import { mdiInformationOutline } from "@mdi/js";

export interface TechnicalPassMessage {
  question1?: string;
  question2?: string;
  warn?: string;
  cancelBtn?: string;
  aprroveBtn?: string;
  disapproveBtn?: string;
}

export interface Props {
  toggleModal?: () => void;
  submitTechnicalStatus?: any;
  bidder_id?: string | number | undefined;
  popUpContents: TechnicalPassMessage;
  passLoader?: boolean;
  failLoader?: boolean;
}

export const TechnicalPass: React.FC<Props> = ({
  toggleModal,
  submitTechnicalStatus,
  popUpContents,
  passLoader,
  failLoader,
}) => {
  const [technicalPassNote, setTechnicalPassNote] = useState("");

  return (
    <div className="technicalbid-popup">
      <div className="container">
        <div className="close-icon" onClick={toggleModal}>
          <Icon className=" mdi-close" path={mdiClose} />
        </div>
        <div className="info-logo">
          <Icon className="mdi-alert" path={mdiInformationOutline}></Icon>
        </div>

        <div>
          <h5 className="text-title">{popUpContents.question1}</h5>
          <p className="sub-header">{popUpContents.question2}</p>
        </div>

        <div className="popup-body">
          {popUpContents.warn && (
            <div className="alert-section">
              <div>
                <Icon className="mdi-alert" path={mdiInformationOutline}></Icon>
              </div>
              <div>
                <p className="withdraw-bid">{popUpContents.warn}</p>
              </div>
            </div>
          )}

          <label className="form-label">Note:</label>
          <textarea
            required
            className="form-control"
            value={technicalPassNote}
            onChange={(e) => setTechnicalPassNote(e.target.value)}
          ></textarea>
        </div>

        <div className="popup-btn">
          <div>
            <button
              className=" btn-cancel"
              onClick={() => {
                submitTechnicalStatus(false, technicalPassNote);
              }}
            >
              {failLoader ? (
                <CustomLoader
                  type="TailSpin"
                  color="#fff"
                  height={22}
                  width={50}
                />
              ) : (
                <span>{popUpContents.disapproveBtn}</span>
              )}
            </button>
          </div>

          <div>
            <button
              className="btn-withdraw"
              onClick={() => {
                submitTechnicalStatus(true, technicalPassNote);
              }}
            >
              {passLoader ? (
                <CustomLoader
                  type="TailSpin"
                  color="#fff"
                  height={22}
                  width={50}
                />
              ) : (
                <span>{popUpContents.aprroveBtn}</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
