export const convertDateToISOString = (date: Date) => {
  return date.toISOString().substring(0, 10);
};

export const formatSelectOptions = (datas: Object) => {
  if (!datas) return;
  return Object.entries(datas).map(([key, value]) => {
    //@ts-ignore
    const options = value.map((item: string, index: number) => {
      return { label: item, value: item };
    });
    return {
      label: key,
      options: [{ label: "All", value: "All-" + key }, ...options],
    };
  });
};

export const addDaysInDate = (date: string, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate.toISOString().split('T')[0];
};
