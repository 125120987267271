import { Download } from "@mui/icons-material";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import React, { useState } from "react";
import { DocumentResponse, IBidDocumentsResponse } from "../EditBids/EditBids";
import { DocumentTile } from "./DocumentTile";
import "./Pdf.scss";
interface PdfDocumentProps {
  documentData: DocumentResponse;
  catalogueFiles: string;
  neededDocuments: string[];
  optionalDocuments: string;
}

export const PdfDoc: React.FC<PdfDocumentProps> = ({
  documentData,
  catalogueFiles,
  neededDocuments,
  optionalDocuments,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  // Function to download PDFs as a zip file
  function downloadPDFsAsZip() {
    // Create a new JSZip instance
    var zip = new JSZip();

    // Array to hold fetch promises
    var fetchPromises: any = [];

    // Iterate through PDF URLs
    selectedFiles.forEach(function (url) {
      // Fetch each PDF fil
      var promise = fetch(url, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*", // Allow requests from any origin (replace '*' with specific origins if needed)
        },
        cache: "no-store",
      })
        .then(function (response) {
          if (!response.ok) {
            throw new Error("Failed to fetch: " + response.statusText);
          }
          // Return the fetched PDF blob
          return response.blob();
        })
        .then(function (blob) {
          // Add the fetched PDF file to the zip with a unique name
          zip.file(url.split("/").pop() ?? "unknown.pdf", blob);
        })
        .catch(function (error) {
          console.error("Error fetching PDF:", error);
        });

      // Add the fetch promise to the array
      fetchPromises.push(promise);
    });

    // When all fetches are complete
    Promise.all(fetchPromises)
      .then(function () {
        // Generate the zip file
        return zip.generateAsync({ type: "blob" });
      })
      .then(function (content) {
        // Trigger the download of the zip file
        saveAs(content, "documents.zip");
        setSelectedFiles([]);
      })
      .catch(function (error) {
        console.error("Error generating zip file:", error);
      });
  }
  const onDocumentSelect = (value: string) => {
    const data = [...selectedFiles];
    const index = selectedFiles.indexOf(value);
    if (index >= 0) {
      data.splice(index, 1);
      setSelectedFiles(data);
    } else {
      setSelectedFiles([...selectedFiles, value]);
    }
  };

  const COMPANY_DOCUMENTS = [
    {
      title: "Registration Certificate Front",
      link: documentData.company_document.registration_certificate_front,
    },
    {
      title: "Pan/Vat Certificate",
      link: documentData.company_document.pan_vat_certificate,
    },
    {
      title: "Registration Certificate Back",
      link: documentData.company_document.registration_certificate_back,
    },
    {
      title: "Liscense front",
      link: documentData.company_document.liscense_front,
    },
    {
      title: "Liscense back",
      link: documentData.company_document.liscense_back,
    },
  ];

  const JOINT_VERTURE_DOCUMENTS = [
    {
      title: "Venture Agreement",
      link: documentData.joint_venture_documents?.venture_agreement,
      key: "venture_agreement",
    },
    {
      title: "Power of Attorny",
      link: documentData.joint_venture_documents.power_of_attorney,
      key: "venture_agreement",
    },
    {
      title: "Partner Document",
      link: documentData.joint_venture_documents.partner_document,
      key: "venture_agreement",
    },
  ];

  const BID_DOCUMENTS = [
    {
      title: "BID LETTER",
      link: documentData.bid_documents?.bid_letter,
      key: "bid_letter",
    },
    {
      title: "DECLARATION LETTER",
      link: documentData.bid_documents?.declaration_letter,
      key: "declaration_letter",
    },
    {
      title: "TAX CLEARANCE CERTIFICATE",
      link: documentData.bid_documents?.tax_clearence,
      key: "tax_clearence",
    },
    {
      title: "APPLICATION INFORMATION",
      link: documentData.bid_documents?.applicants_information,
      key: "applicants_information",
    },
    {
      title: "EXPERIENCE(GENERAL, SPECIFIC & OTHER)",
      link: documentData.bid_documents?.experience,
      key: "experience",
    },
    {
      title: "CAPACITY",
      link: documentData.bid_documents?.capacity,
      key: "capacity",
    },
    {
      title: "QUALIFICATION OF KEY EXPERTS",
      link: documentData.bid_documents?.qualification,
      key: "qualification",
    },
    {
      title: "CITIZENSHIP",
      link: documentData.bid_documents?.citizenship,
      key: "citizenship",
    },
  ];

  const feeDocList = [
    {
      title: "Voucher",
      link: documentData?.fee?.voucher,
    },
    {
      title: `Bid fee Voucher (${documentData?.fee?.bid_fee_voucher_date})`,
      link: documentData?.fee?.bid_fee_voucher,
    },
  ];

  return (
    <div className="document-wrapper">
      <div className="content-section">
        <div className="d-flex"></div>
      </div>
      <>
        {selectedFiles.length ? (
          <div className="btn-download-as-pdf" onClick={downloadPDFsAsZip}>
            <Download />
            Download {selectedFiles.length} file(s) as .zip
          </div>
        ) : (
          <></>
        )}
        <fieldset className="field-border">
          <React.Fragment>
            <legend className="w-auto">
              <div className="venture-topic ">Company</div>
            </legend>
          </React.Fragment>
        </fieldset>
        <fieldset className="body-field">
          <div className="row">
            {COMPANY_DOCUMENTS.map((eachDoc) => {
              return (
                <DocumentTile
                  checked={selectedFiles.includes(eachDoc.link)}
                  key={eachDoc.title}
                  title={eachDoc.title}
                  link={eachDoc.link}
                  onSelect={(value) => {
                    onDocumentSelect(value);
                  }}
                />
              );
            })}
          </div>
        </fieldset>
        {documentData.joint_venture_documents?.venture_agreement && (
          <>
            <fieldset className="field-border">
              <React.Fragment>
                <legend className="w-auto">
                  <div className="venture-topic ">
                    Joint Venture{" "}
                    {documentData?.joint_venture_documents?.joint_venture_name}
                  </div>
                </legend>
              </React.Fragment>
            </fieldset>
            <fieldset className="body-field">
              <div className="row">
                {JOINT_VERTURE_DOCUMENTS.map((eachVenture) => {
                  return (
                    <DocumentTile
                      checked={selectedFiles.includes(eachVenture.link)}
                      key={eachVenture.title}
                      title={eachVenture.title}
                      link={eachVenture.link}
                      onSelect={(value) => {
                        onDocumentSelect(value);
                      }}
                    />
                  );
                })}
              </div>
            </fieldset>
          </>
        )}

        {(documentData?.fee?.voucher || documentData?.fee?.bid_fee_voucher) && (
          <>
            <fieldset className="field-border">
              <React.Fragment>
                <legend className="w-auto">
                  <div className="venture-topic ">Fee:</div>
                </legend>
              </React.Fragment>
            </fieldset>
            <fieldset className="body-field">
              <div className="row">
                {feeDocList?.map(
                  (doc, index) =>
                    doc.link && (
                      <>
                        <DocumentTile
                          key={index}
                          checked={selectedFiles.includes(doc.link)}
                          title={doc.title}
                          link={doc.link}
                          onSelect={(value) => {
                            onDocumentSelect(value);
                          }}
                        />
                      </>
                    )
                )}
              </div>
            </fieldset>
          </>
        )}

        <fieldset className="field-border">
          <React.Fragment>
            <legend className="w-auto">
              <div className="venture-topic ">Bid Document</div>
            </legend>
          </React.Fragment>
        </fieldset>
        <fieldset className="body-field">
          <div className="row">
            {/* filter the BID_DOCUMENTS as per the needed documents */}
            {BID_DOCUMENTS?.filter((eachDocument) =>
              neededDocuments.includes(eachDocument.key)
            ).map((each) => {
              return (
                <DocumentTile
                  checked={selectedFiles.includes(
                    documentData?.bid_documents?.[
                    each.key as keyof IBidDocumentsResponse
                    ] ?? ""
                  )}
                  key={each.key}
                  title={each.title}
                  link={
                    documentData?.bid_documents?.[
                    each.key as keyof IBidDocumentsResponse
                    ]
                  }
                  onSelect={(value) => {
                    onDocumentSelect(value);
                  }}
                />
              );
            })}
            {catalogueFiles.split(",").map((file) => {
              return (
                <>
                  {file && (
                    <DocumentTile
                      checked={selectedFiles.includes(
                        documentData?.boq_catalogue_files?.[file]["file_url"]
                      )}
                      title={file}
                      link={
                        documentData?.boq_catalogue_files?.[file]["file_url"]
                      }
                      onSelect={(value) => {
                        onDocumentSelect(value);
                      }}
                    />
                  )}
                </>
              );
            })}
          </div>
        </fieldset>
        {Boolean(optionalDocuments) && (
          <>
            <fieldset className="field-border">
              <React.Fragment>
                <legend className="w-auto">
                  <div className="venture-topic ">Optional Files:</div>
                </legend>
              </React.Fragment>
            </fieldset>
            <fieldset className="body-field">
              <div className="row">
                {optionalDocuments.split(",")?.map(
                  (file) =>
                    file && (
                      <DocumentTile
                        checked={selectedFiles.includes(
                          documentData?.optional_documents?.[file]?.["file_url"]
                        )}
                        title={file}
                        link={
                          documentData?.optional_documents?.[file]?.["file_url"]
                        }
                        onSelect={(value) => {
                          onDocumentSelect(value);
                        }}
                      />
                    )
                )}
              </div>
            </fieldset>
          </>
        )}
      </>
    </div>
  );
};
