import { useContext } from "react";
import { useAuth } from "../Context/AuthContext";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalContext } from "../Context/ModalContext/ModalContext";
import { isBidder, isPublisher } from "../../utils/services";

export function RequirePublishAuth() {
  const { isAuthenticate, role } = useAuth();
  const location = useLocation();
  if (!isAuthenticate || isBidder(role)) {
    toast.error("Make sure you are logged as publisher! ");
    return <Navigate to="/" replace state={{ from: location }} />;
  }
  return <Outlet />;
}

export function RequireBidderAuth() {
  const { isAuthenticate, role } = useAuth();
  const location = useLocation();

  if (!isAuthenticate || isPublisher(role)) {
    toast.error("Make sure you are logged as bidder! ");
    return <Navigate to="/" replace state={{ from: location }} />;
  }
  return <Outlet />;
}

export function RequireAuth() {
  const { isAuthenticate } = useAuth();
  const location = useLocation();
  const { TogglePopUpFlags } = useContext(ModalContext);
  if (!isAuthenticate) {
    TogglePopUpFlags("login");
    return <Navigate to="/" replace state={{ from: location }} />;
  }
  return <Outlet />;
}

export function EligibleForPublishorBid() {
  const { eligible } = useAuth();
  const location = useLocation();
  if (!eligible) {
    toast.warn("Please upload all require documents for further process. ! ");
    return <Navigate to="/" replace state={{ from: location }} />;
  }
  return <Outlet />;
}

export function ActiveUsers() {
  const { userData, role } = useAuth();
  if (isBidder(role) && userData?.plan_remaining_days === 0) {
    return <Navigate to="/pricing-plan" />;
  }
  return <Outlet />;
}
