import "./MyBidLoader.scss"

export const MyBidLoader = () => {
  return (
    <div className="loader-block">
      <div className="loader"></div>
      <div className="loader-image">
        <img src="https://images-ext-1.discordapp.net/external/9lbALl527T1rvkDCMETa28ol-S0Yh8DQTuRtFO_QFsU/https/i.imgur.com/0nR2olW.png" />
      </div>
    </div>
  )
}
