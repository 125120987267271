import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getEmailVerfied } from "../Api/GetApi";
import { catchError } from "../utils/catchError";
import "./UserEmailVerification.scss";

export const UserEmailVerification = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isMobileView = window.innerWidth < 500;
  const userEmailVerification = (id: string | undefined) => {
    getEmailVerfied(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.status.status_message + " Please login to continue");
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        setTimeout(() => {
          if(isMobileView){
            return navigate("/mobile-view")
          }
          navigate("/");
        }, 1000);
      });
  };
  useEffect(() => {
    userEmailVerification(id);
  }, []);

  return (
    <>
      <div className="verifying-page">
        <div className="varification-card">
          <div className="center-div">
            <div className="navbar-brand">
              <img
                src={require("../../images/bolpart-logo-05.png")}
                alt="logo"
              />
            </div>
            <div>
              <img
                src={require("../../gif/loading.gif")}
                className="loading border-radius"
                alt="loading"
              />
            </div>
            <h5>
                <span>Verifying your email address. Please wait for a few seconds.</span>
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};
