import { useState } from "react";
import Modal from "react-modal";
import bolpatraLogo from "../../images/bolpatra logo-full.png";
import { findOrganizationLabelByValue } from "../../utils/services";
import { useAuth } from "../Context/AuthContext";
import EditCompanyDetails from "../EditDetails/EditCompanyDetails";
import { ImageWithFallback } from "../ImageWithFallback/ImageWithFallback";
import { INoticeDetail } from "../utils/interface";
import "./ENoticeBanner.scss";
import { PublisherBannerShimmer } from "../PublisherBannerShimmer/PublisherBannerShimmer";
import { BannerType } from "../../utils/EnumConstants";

export interface ENoticeBannerProps {
  noticeDetail?: INoticeDetail;
  noticeLoading?: boolean;
}

const ENoticeBanner: React.FC<ENoticeBannerProps> = ({
  noticeLoading,
  noticeDetail,
}) => {
  const { userData } = useAuth();
  const [orgDetailsOpen, setOrgDetailsOpen] = useState<boolean>(false);

  function toggleOrgDetailsModal() {
    setOrgDetailsOpen(!orgDetailsOpen);
  }

  const customStylesOrg = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "55%",
      padding: "20px",
      maxHeight: "650px",
    },
  };

  return (
    <>
      {orgDetailsOpen && (
        <Modal
          isOpen={orgDetailsOpen}
          onRequestClose={toggleOrgDetailsModal}
          contentLabel="My dialog"
          style={customStylesOrg}
          ariaHideApp={false}
        >
          <EditCompanyDetails
            toggleModal={() => toggleOrgDetailsModal()}
          />
        </Modal>
      )}

      <div className="enotice-banner">
        {noticeLoading ? (
          <PublisherBannerShimmer bannerType={BannerType.E_NOTICE} />
        ) : (
          <div className="details-section">
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <div className="company-details-section">
                    <div className="logo-section">
                      <ImageWithFallback
                        src={
                          noticeDetail?.user_info?.additional_info?.logo || ""
                        }
                        fallbackSrc={bolpatraLogo}
                        alt="Company Logo"
                      />
                    </div>
                    <div className="company-details">
                      <div className="d-flex ">
                        <h4 className="block-title text-nowrap">
                          {noticeDetail?.user_info?.company_name}
                        </h4>
                        {noticeDetail?.user_info?.is_verified && (
                          <img
                            src={
                              require("../../images/bolpatra verified icon-01.svg")
                                .default
                            }
                            alt="verified"
                            className="banner-img"
                          />
                        )}
                      </div>
                      <p className="company-detail-content">
                        {findOrganizationLabelByValue(
                          noticeDetail?.user_info?.organization_category ?? ""
                        )}{" "}
                        | {noticeDetail?.user_info?.location}
                      </p>
                      <p className="company-detail-content">
                        Contact: {noticeDetail?.user_info?.full_name} |{" "}
                        {noticeDetail?.user_info?.contact_no}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <h4 className="block-title">{noticeDetail?.title}</h4>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex align-items-start">
                        <p className="detail-content text-nowrap">
                          {" "}
                          Notice Category:
                        </p>
                        <p className="content-right">
                          {noticeDetail?.notice_category}
                        </p>
                      </div>
                      <div className="d-flex align-items-start">
                        <p className="detail-content text-nowrap">
                          Project Category:
                        </p>
                        <p className="content-right">
                           {noticeDetail?.project_category?.map((item) => item?.label).join(', ')}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 align-items-start">
                      <div className="d-flex">
                        <p className="detail-content text-nowrap">
                          Published Date:
                        </p>
                        <p className="content-right">
                          {noticeDetail?.publishing_date.slice(0, 10)}
                        </p>
                      </div>
                      <div className="d-flex align-items-start">
                        <p className="detail-content text-nowrap">
                          Last Submission Date:
                        </p>
                        <p className="content-right">
                          {noticeDetail?.submission_date
                            ?.toLocaleString()
                            .slice(0, 10)}{" "}
                          | 12:00 PM
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ENoticeBanner;
