import { toast } from "react-toastify";
import Axios from "../../Components/Api/AxiosInstance";
import {
  PaymentFeeType,
  PaymentStorageVariable,
} from "../../utils/EnumConstants";
export interface InitiateData {
  amount: number;
  return_url: string;
  fee_for: PaymentFeeType;
  tender_notice_id: string;
}

export const KhaltiInitiate = (data: InitiateData) => {
  return Axios.post("khalti/payment/initiate", data);
};

export const RoutePath = (paymentType?: PaymentFeeType) => {
  let route = "";
  switch (paymentType) {
    case PaymentFeeType.Bid:
      route = "bid-tender";
      break;
    case PaymentFeeType.Publish:
      route = "publish-tender";
      break;
    case PaymentFeeType.Subscription:
      route = "pricing-plan";
      break;
  }
  return route;
};

export const KhaltiPaymentInitiate = (
  pathname: string,
  paymentType?: PaymentFeeType,
  amount?: number,
  tender_notice_id?: number | string
) => {
  //Storing the path for redirecting after the verification process get completed
  window.localStorage.setItem(PaymentStorageVariable.Khalti, pathname);
  const verifyRoute = RoutePath(paymentType);
  const apiData = {
    amount: amount,
    fee_for: paymentType,
    return_url: `${window.location.origin}/${verifyRoute}/khalti/payment_verification`,
    tender_notice_id: tender_notice_id,
  };
  const data = { ...apiData };
  //This is for the subscription part
  if (paymentType === PaymentFeeType.Subscription) {
    delete data.tender_notice_id;
    //@ts-ignore
    data.plan_id = tender_notice_id;
  }
  //@ts-ignore
  KhaltiInitiate(data)
    .then((res) => {
      toast.success("Khalti Initiated");
      setTimeout(() => {
        window.location.href = res.data.data.payment_url;
      }, 1000);
    })
    .catch((err) => {
      toast.error("Error while initating payment");
      localStorage.removeItem(PaymentStorageVariable.Khalti);
    });
};
