import {
  FacebookShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  InstapaperShareButton,
} from "react-share";
import "./ShareButton.scss";

const shareBtnConfig = [
  {
    icon: require("../../images/facebook.png"),
    link: "https://www.facebook.com/bolpatranepal",
  },
  {
    icon: require("../../images/instagram.png"),
    link: "https://www.instagram.com/bolpatranepal/",
  },
  {
    icon: require("../../images/tiktok.png"),
    link: "https://www.tiktok.com/@bolpatranepal",
  },
  {
    icon: require("../../images/linkedin-dark.png"),
    link: "https://www.linkedin.com/company/bolpatra-nepal",
  },
];
export interface ShareButtonProps {
  shareUrl: string;
  size: number;
}

export const ShareButton: React.FC<ShareButtonProps> = ({ shareUrl, size }) => {
  return (
    <div className="media-icon">
      {/* <FacebookShareButton
        url={shareUrl}
        quote="E-bidding"
        className="mdi-icon fb mg-r color-gradient"
      >
        <img src={require("../../images/facebook.png")} alt="fb" />
      </FacebookShareButton>

      <ViberShareButton
        url={shareUrl}
        title="Ebidding"
        className=" mdi-icon insta mg-r color-gradient"
      >
        <img src={require("../../images/instagram.png")} alt="fb" />
      </ViberShareButton>

      <WhatsappShareButton
        url={shareUrl}
        className=" mdi-icon insta mg-r color-gradient"
      >
        <img src={require("../../images/tiktok.png")} alt="fb" />
      </WhatsappShareButton>

      <TwitterShareButton
        url={shareUrl}
        className=" mdi-icon insta mg-r color-gradient"
      >
        <img src={require("../../images/whatsapp.png")} alt="fb" />
      </TwitterShareButton> */}

      {shareBtnConfig?.map((item, index) => {
        return (
          <a href={item?.link} target="_blank" rel="" key={index}>
            <img src={item?.icon} alt="fb" key={index} />
          </a>
        );
      })}
    </div>
  );
};
