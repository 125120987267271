import { createContext, useState, useContext, ReactNode } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { IAdvertisementAttributes } from '../utils/interface'


interface IGlobaleContextInit{
  advertisements:IAdvertisementAttributes;
  setAdvertisement:(advertisement:IAdvertisementAttributes)=>void
  loadingAd: boolean,
  setLoadingAd: (value: boolean) => void
}
const initialValues:IGlobaleContextInit={
  advertisements: {},
  setAdvertisement: (advertisement) => {},
  loadingAd: true,
  setLoadingAd: (value)=>{},

}

const GlobalContext= createContext(initialValues)

interface IGlobalContextProps{
  children: ReactNode;
}

const GlobalContextProvider:React.FC<IGlobalContextProps>=({children,}) => {
  const [advertisements, setAdvertisement] = useState<IAdvertisementAttributes>({});
  const [loadingAd, setLoadingAd] = useState(true)

  return (
    <>
      <GlobalContext.Provider
        value={{
          advertisements,
          setAdvertisement,
          loadingAd,
          setLoadingAd
        }}
        
      >
        {children}
      </GlobalContext.Provider>
    </>
  )
}
export const useGlobal = () => {
  const globalContext = useContext(GlobalContext)
  if (!globalContext) {
    throw new Error('useGlobal must be used within an GlobalProvider')
  }
  return globalContext
}
export { GlobalContext, GlobalContextProvider }
