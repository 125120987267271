interface NoDataFoundProps {
  text1: string
  text2?: string
  img: any
}

export const NoDataFound: React.FC<NoDataFoundProps> = ({
  text1,
  text2,
  img,
}) => {
  return (
    <div className="no-data">
      <div className="comment-section">
        <img className="quote-img" src={img} alt="img" />
        <div className="medium-content bid-title">{text1}</div>
        <div className="medium-content mg-bottom-20">{text2}</div>
      </div>
    </div>
  )
}
