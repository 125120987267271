import { mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import "./UploadedFile.scss";
import Modal from "react-modal";
import { ConfirmationPopUp, ConfirmMessage } from "../PopUp/ConfirmationPopUp";
import { useState } from "react";
import { deleteBidDocument } from "../Api/PostApi";
import { toast } from "react-toastify";
import { catchError } from "../utils/catchError";
import { DocumentType } from "../../utils/EnumConstants";

interface UploadedFileProps {
  url: string;
  tenderId?: string | number | undefined;
  documentType?: DocumentType;
  documentLabel?: string;
  deleteDocument?: boolean;
  reFetchData?: () => void;
}

const DocumentDeletePopUpMessage: ConfirmMessage = {
  question1: "Delete Document",
  question2: "Are you sure you want to delete document?",
  warn: "If you delete document, your action can't be undone.",
  cancelBtn: "No",
  aprroveBtn: "Yes, Delete",
};

export const UploadedFile: React.FC<UploadedFileProps> = ({
  url,
  tenderId,
  documentType,
  documentLabel,
  deleteDocument = false,
  reFetchData
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "510px",
    },
  };

  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const handleDelete = (
    tender_id: string | number | undefined,
    document_type: DocumentType,
    document_label: string
  ) => {
    setIsDeleting(true);

    const formData = new FormData();
    formData.append("tender_notice_id", tender_id?.toString() || "");
    formData.append("document_type", document_type);
    formData.append("document_label", document_label);
    formData.append("document_name", url?.substring(url?.lastIndexOf("/") + 1));
    
    deleteBidDocument(formData)
      .then((res) => {
        toast.success("Document Deleted Successfully");
        setIsDeleting(false);
        reFetchData?.();
        toggleModal();
        window.scrollTo({ top: 0 });
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
        setIsDeleting(false);
        toggleModal();
      });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        style={customStyles}
        ariaHideApp={false}
      >
        <ConfirmationPopUp
          popUpContents={DocumentDeletePopUpMessage}
          toggleModal={() => toggleModal()}
          activeButtonLoading={isDeleting}
          onAprroveButton={() => {
            handleDelete(
              tenderId,
              documentType ?? DocumentType.DOCUMENT,
              documentLabel ?? ""
            );
          }}
        />
      </Modal>
      <p>Uploaded File:</p>
      <div className="uploaded-file-section">
        <div className="upload-file">
          <a href={url} target="_blank" rel="noreferrer">
            <div className="d-flex">
              <img
                className="img-width"
                src={require("../../images/pdf.png")}
                alt="img"
              />
              <p className="document-topic">
                {url?.substring(url?.lastIndexOf("/") + 1)}
              </p>
            </div>
          </a>
        </div>
        {deleteDocument && (
          <div
            className="close-icon"
            onClick={toggleModal}
            title="Delete Document"
          >
            <Icon className=" mdi-trash" path={mdiTrashCan} />
          </div>
        )}
      </div>
    </>
  );
};
