import { toast } from "react-toastify";
import { requestAccountDeletion } from "../Api/PostApi";
import { useState } from "react";
export interface AccountDeletionForm {
  email: string;
  contact_no: string;
  reason: string
}
export const useAccountDeletion = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [formValue, setFormValues] = useState<AccountDeletionForm>({
    email: "",
    contact_no: "",
    reason: ""
  });

  const setFormValue = (e: any) => {
    const value = e.target.value;
    const key = e.target.name;
    setFormValues({ ...formValue, [key]: value } as AccountDeletionForm);
  };

  const accoutDeletionRequest = () => {
    if (!formValue.email) return toast.error("Please enter email");
    if (!formValue.contact_no) return toast.error("Please enter contact no.");

    setLoading(true);
    const body: AccountDeletionForm = formValue;
    requestAccountDeletion(body)
      .then((res) => {
        if (res.status == 200) {
          return toast.success(res.data.status.status_message)
        }
        setError({ res });
      })
      .catch((err) => {
        setError(err);
        toast.error(err.response.data.status.status_message)
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return { accoutDeletionRequest, loading, error, formValue, setFormValue };
};
