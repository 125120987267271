export const OrganizationCategory = [
  {
    label: "Health",
    value: "health",
  },
  {
    label: "Bank/Finance",
    value: "bank/finance",
  },
  {
    label: "Education Institution",
    value: "education_institution",
  },
  {
    label: "NGOS/INGOS",
    value: "NGOS/INGOS",
  },
  {
    label: "Manufacturing",
    value: "manufacturing",
  },
  {
    label: "Service Organization",
    value: "service_organization",
  },
  {
    label: "Importer/Exporter",
    value: "importer/exporter",
  },
  {
    label: "Hydropower",
    value: "hydropower",
  },
  {
    label: "Insurance",
    value: "insurance",
  },
  {
    label: "Hotel",
    value: "hotel",
  },
  {
    label: "Government Sector",
    value: "government_sector",
  },
];
