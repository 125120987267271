import "./OurClients.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { RegisterBid } from "../RegisterBid/RegisterBid";
import { GetClientData } from "../Api/GetApi";
import { useEffect, useState } from "react";
import React from "react";
export interface ClientType {
  id?: number;
  photo?: string;
  name?: string;
}

export const OurClients = () => {
  const [clientData, setClientData] = useState<ClientType[]>();

  const clientsData = () => {
    GetClientData()
      .then((res) => {
        setClientData(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  useEffect(() => {
    clientsData();
  }, []);
  return (
    <div className="clients-section">
      <div className="container">
        <div>
          <p className="client-header">Our Clients </p>
        </div>

        <div>
          {clientData && clientData.length > 0 ? (
            <OwlCarousel
              items={5}
              className="owl-theme"
              loop
              nav
              margin={0}
              autoplay={true}
            >
              {clientData?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <img
                      className="company-images"
                      src={item.photo}
                      alt="images"
                    />
                    {/* <p className="content-testi">{item.name}</p> */}
                  </React.Fragment>
                );
              })}
            </OwlCarousel>
          ) : (
            <div style={{ color: "red", textAlign: "center" }}>
              No Data Found
            </div>
          )}
        </div>

        <RegisterBid />
      </div>
    </div>
  );
};

OurClients.defaultProps = {
  clientUser: [
    {
      image: require("../../images/jcb-1.png") as string,
    },
    {
      image: require("../../images/jcb-1.png") as string,
    },
    {
      image: require("../../images/jcb-1.png") as string,
    },
    {
      image: require("../../images/jcb-1.png") as string,
    },
    {
      image: require("../../images/jcb-1.png") as string,
    },
    {
      image: require("../../images/jcb-1.png") as string,
    },
  ],
};
