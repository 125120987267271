  
       
export const generateToken=()=>{
    const alphabet = "abcdefghijklmnopqrstuvwxyz"
     const capitalAlphabet="ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    var token=""
     for(let i=0;i<32;i++){
         if(i%2===0){
             token=token+alphabet[Math.floor(Math.random() * alphabet.length)]
         }
         if(i%parseInt((Math.random()*10).toLocaleString())===0){
              token=token+capitalAlphabet[Math.floor(Math.random() * capitalAlphabet.length)]
         }
         token=token+parseInt((Math.random()*10).toLocaleString())
     }
     
     return token
}


export const EsewaDatas=()=>{
    
}

