import React, { useEffect, useMemo, useState } from "react";
import { MyBids } from "../../Pages/MyTenderAndBids/MyBids";
import "./MyBidsPage.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import { useAuth } from "../Context/AuthContext";
import { getMyBids, getTenderAttrs } from "../Api/GetApi";
import { catchError } from "../utils/catchError";
import { NoDataFound } from "../NoDataFound/NoDataFound";
import { ResponseTenderCard } from "../TenderCard/TenderCardGroup";
import { MyBidShimmer } from "../../Pages/MyBidShimmer/MyBidShimmer";
import { isBidder, isPublisher } from "../../utils/services";
import { BidStatus } from "../../utils/EnumConstants";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { Option } from "../SelectComponent/SelectComponent";
import usePagination from "../Pagination/usePagination";
import { useSearch } from "../Context/SearchContext";
import { Pagination } from "@mui/material";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

interface BidItem {
  data: any[];
  live_or_expired: number;
  total_or_submitted: number;
}

interface BidObject {
  [key: string]: BidItem;
}

export interface UserInfo {
  id: number;
  full_name: string;
  email: string;
  contact_no: string;
  company_name: string;
  company_contact_no: string;
  location: string;
  organization_category: string;
  is_verified?: boolean;
  additional_info: {
    introduction: string;
    estd: string;
    employee_no: string;
    logo: string;
  };
}

export interface MyBidsPageProps {
  isPublisherProfile?: boolean;
  userID?: string;
}

const tabsBidder = [
  "Unsubmitted Notice",
  "EOI Status",
  "Submitted Notice",
  "Withdrawn Notice",
];

const tabsPublisherFromBidderView = {
  "E-bids": BidStatus.SUBMITTED,
  "Vendor Listing": BidStatus.ENLISTMENT,
  "E-Notice": BidStatus.E_NOTICE,
  "Awarded Notice": BidStatus.WITH_DRAWN_OR_AWARDED,
};

const tabsPublisherFromPublisherView = {
  ...tabsPublisherFromBidderView,
  "Unpublished Notice": BidStatus.IN_PROGRESS,
};

export const MyBidsPage: React.FC<MyBidsPageProps> = ({
  isPublisherProfile = false,
  userID,
}) => {
  const perPageLength = 25;
  const [data, setData] = useState<BidObject>();
  const [loading, setLoading] = useState(true);
  const { role, userData } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);
  const [initialCount, setInitialCount] = useState(0);
  const [finalCount, setFinalCount] = useState(perPageLength);
  const [searchParams] = useSearchParams();
  const { page, setPage } = useSearch();
  const [staticTitle, setStaticTitle] = useState("");
  const [projectCat, setProjectCat] = useState<Option[]>([]);
  const [selectedProjectCategory, setSelectedProjectCategory] =
    useState<Option | null>();
  const [selectedKey, setSelectedKey] = useState<string>();

  const tabsPublisher =
    userID === userData?.id
      ? tabsPublisherFromPublisherView
      : tabsPublisherFromBidderView;

  const FetchMyBids = () => {
    setLoading(true);
    const apiCall = userID ? getMyBids(userID) : getMyBids();
    apiCall
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.data?.status);
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reFetchMyBids = () => {
    const apiCall = userID ? getMyBids(userID) : getMyBids();
    apiCall
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.data?.status);
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };

  useEffect(() => {
    FetchMyBids();
  }, []);

  const reOrderData = (
    responseData: BidObject,
    tabMapping: { [key: string]: BidStatus }
  ) => {
    const orderedKeys = Object.values(tabMapping); // Extract the keys from the mapping
    return orderedKeys.reduce((acc, statusKey) => {
      if (responseData?.[statusKey]) {
        acc[statusKey] = responseData[statusKey];
      }
      return acc;
    }, {} as { [key: string]: BidItem });
  };

  const organizedData =
    userID || isPublisher(role)
      ? reOrderData(data as BidObject, tabsPublisherFromPublisherView)
      : data;

  const selectedTabData = useMemo(() => {
    if (selectedKey) {
      return data?.[selectedKey]?.data;
    } else {
      return [];
    }
  }, [data, selectedKey]);

  const filteredData = useMemo(() => {
    if (organizedData) {
      let temp = Object.keys(organizedData).reduce<
        Record<string, (typeof organizedData)[BidStatus]["data"]>
      >((acc, key) => {
        const keyAsBidStatus = key as BidStatus;
        const tabData = organizedData?.[keyAsBidStatus]?.data || [];
        if (tabData.length > 0) {
          const tempData = tabData.filter((each) => {
            const matchesTitle = each.title
              .toLowerCase()
              .includes(staticTitle.toLowerCase());
            const matchesCategory = selectedProjectCategory
              ? each.project_category.some(
                  (category: { value: number; label: string }) =>
                    category.value === selectedProjectCategory.value
                )
              : true;
            return matchesTitle && matchesCategory;
          });
          acc[key] = tempData;
        } else {
          acc[key] = [];
        }
        return acc;
      }, {});
      return temp;
    }
  }, [staticTitle, organizedData, selectedProjectCategory]);

  const totalDataFound = useMemo(() => {
    if (filteredData) {
      return Object.values(filteredData).reduce(
        (acc, array) => acc + array.length,
        0
      );
    }
  }, [filteredData]);

  const handleChange = (e: any, p: any) => {
    window.scrollTo({ top: 0 });
    setPage(p);
    if (p === 1) {
      setInitialCount(0);
      setFinalCount(perPageLength);
    } else if (page < p) {
      setInitialCount((prev) => {
        return prev + perPageLength;
      });
      setFinalCount((prev) => {
        return prev + perPageLength;
      });
    } else {
      setInitialCount((prev) => {
        return prev - perPageLength;
      });
      setFinalCount((prev) => {
        return prev - perPageLength;
      });
    }
    _DATA.jump(p);
  };

  useEffect(() => {
    const tabValue = searchParams.get("tab");
    if (tabValue) {
      setTabIndex(Number(tabValue));
    }
  }, [searchParams]);

  const fetchCategory = async () => {
    try {
      const res = await getTenderAttrs();
      if (res.status === 200) {
        setProjectCat(res.data.data.project_category);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    const tempKey = Object.keys(tabsPublisher).map((item: string) => {
      const statusKey = tabsPublisher[item as keyof typeof tabsPublisher];
      return statusKey;
    });
    setSelectedKey(tempKey[0]);
  }, [tabsPublisher]);

  const count: number = useMemo(() => {
    if (selectedKey) {
      return Math.ceil(
        Number(filteredData?.[selectedKey]?.length || 1) / Number(perPageLength)
      );
    } else {
      let temp = 1;
      return temp;
    }
  }, [filteredData, selectedKey]);

  const _DATA = usePagination(selectedTabData || [], perPageLength);

  return (
    <div className="bids-page">
      <div className="container">
        <div className="bids-tabsection">
          <div className="card card-bid">
            <div className="d-flex justify-content-between">
              {isPublisherProfile ? (
                <>
                  {isPublisher(role) ? (
                    <p className="bid-title">Welcome to your profile</p>
                  ) : (
                    <p className="bid-title">Publisher Profile</p>
                  )}
                </>
              ) : (
                <p className="bid-title">My Bids</p>
              )}
              <div className="d-flex ">
                <div className="input-forms">
                  <div className="image-block">
                    <img
                      src={require("../../images/search.png")}
                      alt="searchicon"
                      className="search-icon"
                    />
                  </div>
                  <div className="search-bar">
                    <input
                      className="form-control input-contents content "
                      // type="search"
                      placeholder="Search By Title"
                      aria-label="Search"
                      value={staticTitle}
                      onChange={(e) => {
                        setStaticTitle(e.target.value);
                      }}
                    />
                  </div>
                  {staticTitle !== "" && (
                    <div
                      onClick={() => {
                        setStaticTitle("");
                      }}
                      className="icon-section"
                    >
                      <Icon className="mdi-close" path={mdiClose} />
                    </div>
                  )}
                </div>
                <div className="input-block ml-3">
                  <div>
                    <Select
                      isClearable={true}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      maxMenuHeight={200}
                      placeholder="Select Project Category"
                      onChange={(e) => {
                        if (e) {
                          setSelectedProjectCategory(e);
                        } else {
                          setSelectedProjectCategory(null);
                        }
                      }}
                      options={projectCat}
                    />
                  </div>
                </div>
              </div>
            </div>
            {staticTitle !== "" || selectedProjectCategory ? (
              <p className="content result-found">
                <i>"{totalDataFound}" Results Found</i>
              </p>
            ) : (
              ""
            )}
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => {
                setTabIndex(index);
                setInitialCount(0);
                setFinalCount(perPageLength);
                setPage(1);
              }}
            >
              <TabList>
                {isBidder(role) && !isPublisherProfile && (
                  <>
                    {tabsBidder.map((item: string, id: number) => {
                      return (
                        <React.Fragment key={id}>
                          <Tab>{item}</Tab>
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
                {(isPublisher(role) || isPublisherProfile) &&
                  Object.keys(tabsPublisher).map(
                    (item: string, index: number) => {
                      const statusKey =
                        tabsPublisher[item as keyof typeof tabsPublisher];
                      const itemData = data?.[statusKey];
                      return (
                        <React.Fragment key={index}>
                          <Tab
                            onClick={() => {
                              setSelectedKey(statusKey);
                            }}
                          >
                            <div className="publisher-tab">
                              <p className="tab-title">{item}</p>
                              <hr />
                              <div className="d-flex justify-content-between">
                                {item === "Unpublished Notice" ? (
                                  <p className="tab-content">
                                    Drafts: {itemData?.live_or_expired}
                                  </p>
                                ) : (
                                  <>
                                    {item === "Awarded Notice" ? (
                                      <p className="tab-content">
                                        {/* Pending: {itemData?.live_or_expired} */}
                                        -
                                      </p>
                                    ) : (
                                      <p className="tab-content">
                                        Live: {itemData?.live_or_expired}
                                      </p>
                                    )}
                                  </>
                                )}
                                {item === "Unpublished Notice" ? (
                                  <p className="tab-content">
                                    Submitted: {itemData?.total_or_submitted}
                                  </p>
                                ) : (
                                  <p className="tab-content">
                                    Total: {itemData?.total_or_submitted}
                                  </p>
                                )}
                              </div>
                            </div>
                          </Tab>
                        </React.Fragment>
                      );
                    }
                  )}
              </TabList>

              <div className="outer-border">
                {loading ? (
                  <>
                    <MyBidShimmer />
                  </>
                ) : (
                  <>
                    {filteredData ? (
                      <>
                        {Object.keys(filteredData).map((key, id) => {
                          const keyAsBidStatus = key as BidStatus;
                          const tabData = filteredData?.[keyAsBidStatus] || [];
                          return (
                            <>
                              <TabPanel key={id}>
                                {tabData.length > 0 ? (
                                  tabData
                                    .slice(initialCount, finalCount)
                                    .map(
                                      (
                                        item: ResponseTenderCard,
                                        id: number
                                      ) => {
                                        return (
                                          <div className="card-bottom" key={id}>
                                            <MyBids
                                              key={item.id}
                                              type={key}
                                              reFetchMyBids={reFetchMyBids}
                                              tenderNoticeData={item}
                                              isPublisherProfile={
                                                isPublisherProfile
                                              }
                                            />
                                          </div>
                                        );
                                      }
                                    )
                                ) : (
                                  <NoDataFound
                                    text1={`No ${
                                      isBidder(role) ? "Bids" : "Tenders"
                                    }`}
                                    img={require("../../images/nodata.png")}
                                  />
                                )}
                              </TabPanel>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <NoDataFound
                        text1="No Bids"
                        text2="You can see all your bids here"
                        img={require("../../images/nodata.png")}
                      />
                    )}
                    {selectedKey && filteredData && filteredData?.[selectedKey]?.length > 0 && (
                      <Pagination
                        count={count}
                        size="large"
                        page={page}
                        shape="rounded"
                        onChange={handleChange}
                        siblingCount={0}
                      />
                    )}
                  </>
                )}
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};
