import { Checkbox } from "@mui/material";
import { FileImage } from "../../utils/EnumConstants";

interface DocumentTileProps {
  title: string;
  link?: string;
  onSelect?: (link: string) => void;
  checked?: boolean;
  isMultiDownload?: boolean
}

export const DocumentTile: React.FC<DocumentTileProps> = ({
  title,
  link,
  onSelect,
  checked,
  isMultiDownload
}) => {
  const handleChange = (value: boolean) => {
    if (onSelect) {
      if (link) {
        onSelect && onSelect(link);
      }
    }
  };

  return (
    <div className="col-md-4">
      <div className="pdf-content">
        <div className="mg-top-20 document-title centered-flex-between">
          <p>{title}</p>
          {isMultiDownload && link && (
            <Checkbox
              checked={checked}
              onChange={(e) => handleChange(e.target.checked)}
            />
          )}
        </div>
        {link ? (
          <div className="box-content">
            <div className="d-flex">
              <div className="img-content">
                {/* <img src={require("../../images/pdf.png")} alt="images" /> */}
                <img
                  src={getFileImage(link?.split(".").pop() as string)}
                  alt="images"
                />
              </div>
              <a href={link} target="_blank" rel="noreferrer">
                <div className="paragraph-content">
                  <p className="document-topic">{link.split("/").pop()}</p>
                  <img
                    src={require("../../images/Vector(7).png")}
                    alt="images"
                  />
                </div>
              </a>
            </div>
          </div>
        ) : (
          <div className="padding-top">
            <b className="document-topic">Not Available</b>
          </div>
        )}
      </div>
    </div>
  );
};

const getFileImage = (fileType: string) => {
  if (fileType === "pdf") {
    return FileImage.pdf;
  } else if (fileType === "msword") {
    return FileImage.word;
  } else if (fileType === "zip") {
    return FileImage.zip;
  } else if (["excel", "sheet", "text/csv"].includes(fileType)) {
    return FileImage.xls;
  } else return FileImage.pic;
};
