import { findOrganizationLabelByValue } from "../../utils/services";
import { ContactSection } from "../ContactSection/ContactSection";
import { UserInfo } from "../MyBidsPage/MyBidsPage";
import "./About.scss";

export interface AboutProps {
  profileData: UserInfo;
}

export const About: React.FC<AboutProps> = ({ profileData }) => {
  const introduction = profileData?.additional_info?.introduction;
  const organization_category = findOrganizationLabelByValue(
    profileData?.organization_category ?? ""
  );
  const employee_no = profileData?.additional_info?.employee_no;
  const estd = profileData?.additional_info?.estd;

  return (
    <div className="about-section">
      <div className="container">
        <div className="card">
          <h5 className="text-header">About</h5>
          <div className="border-div"></div>
          <div className="text-content">
            {introduction ? introduction : "N/A"}
          </div>
          <div className="about-body">
            <div className="row">
              <div className="col-md-3">
                <div className="d-flex">
                  <div className="icon-section mr-3">
                    <img
                      src={
                        require("../../images/bolpatra verified icon-06.svg")
                          .default
                      }
                      alt="nature"
                      className="about-img"
                    />
                  </div>
                  <div className="text-section">
                    <p className="text-title">Nature of Business</p>
                    <p className="text-content">
                      {organization_category ? organization_category : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex">
                  <div className="icon-section mr-3">
                    <img
                      src={
                        require("../../images/bolpatra verified icon-07.svg")
                          .default
                      }
                      alt="employees"
                      className="about-img"
                    />
                  </div>
                  <div className="text-section">
                    <p className="text-title">Total Number of Employees</p>
                    <p className="text-content">
                      {employee_no ? employee_no : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex">
                  <div className="icon-section mr-3">
                    <img
                      src={
                        require("../../images/bolpatra verified icon-08.svg")
                          .default
                      }
                      alt="estd"
                      className="about-img"
                    />
                  </div>
                  <div className="text-section">
                    <p className="text-title">Year of Establishment</p>
                    <p className="text-content">{estd ? estd : "N/A"}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex">
                  <div className="icon-section mr-3">
                    <img
                      src={
                        require("../../images/bolpatra verified icon-08.svg")
                          .default
                      }
                      alt="verified"
                      className="about-img"
                    />
                  </div>
                  <div className="text-section">
                    <p className="text-title">Verified By:</p>
                    <p className="text-content">
                      {profileData?.is_verified ? "Bolpatra Nepal" : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ContactSection profileData={profileData} />
        </div>
        
      </div>
    </div>
  );
};
