import * as React from "react";
import { combineClasses } from "../utils/css-utils";
import "./CheckButton.scss";

export interface Project {
  value: string;
  label: string;
  proc_id: string;
}
export interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label?: string;
  value?: string;
  isChecked?: boolean;
}

export const CheckButton: React.FC<Props> = ({
  label,
  isChecked,
  className,
  value,
  ...rest
}) => {
  return (
    // <button
    //   className={combineClasses(
    //     `check-button ${isChecked && "checked"}`,
    //     className
    //   )}
    //   {...rest}
    // >
    //   {isChecked && (
    //     <img src={require("../../images/mdi_check.png")} alt="icon" />
    //   )}

    //   <span>{label}</span>
    // </button>
    <div className="checkbutton-section">
      <button
        className={combineClasses(
          `check-button ${isChecked && "checked"}`,
          className
        )}
        {...rest}
      >
        <input type="checkbox" checked={isChecked} />

        <span className="main-heading">{label}</span>
      </button>

      <div></div>
    </div>
  );
};

CheckButton.defaultProps = {
  label: "Good",
};
