import { AdShimmer } from "./AdShimmer";
import "./AdShow.scss";
import { useGlobal } from "../Context/GlobalContext";

export interface IResponseAdvertisement {
  big_image_url: string;
  image_div_ratio: number;
  location: string;
  redirect_url: string;
  small_image_url: string;
}

export const AdShow = () => {
  const { advertisements, loadingAd } = useGlobal();

  const handleImageOnClick = (redirect_url: string) => {
    window.open(redirect_url, "_blank");
  };
  return (
    <div className="right-side-card-margin">
      {loadingAd ? (
        <>
          <AdShimmer />
        </>
      ) : (
        <div className="right-side-card-section">
          {advertisements["Right-Section"]?.map(
            (item: IResponseAdvertisement, index: number) => {
              return (
                <div className="right-side-card-block" key={index}>
                  <img
                    src={item.small_image_url}
                    alt="No-Img"
                    // style={{ height: `${item.image_div_ratio * 200}px` }}
                    onClick={() => handleImageOnClick(item?.redirect_url)}
                  />
                </div>
              );
            }
          )}
        </div>
      )}
    </div>
  );
};
