import { useEffect, useState } from "react"
import "../UserEmailVerification/UserEmailVerification"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { toast } from "react-toastify"
import { postEsewaBidFee, postEsewaPublishFee } from "../Api/PostApi"
import { useAuth } from "../Context/AuthContext"
import { catchError } from "../utils/catchError"
import { paymentPromiseHandler } from "../../utils/services"
import { PaymentStorageVariable } from "../../utils/EnumConstants"

export const EsewaFeeVerification = () => {
  const [loading, setLoading] = useState(true)
  let [searchParams, setSearchParams] = useSearchParams()
  const oid = searchParams.get("oid")
  const amt = searchParams.get("amt")
  const refId = searchParams.get("refId")
  const data = {
    oid: oid,
    amt: amt,
    refId: refId,
  }
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const esewaBidFee = () => {
    const redirectPath = window.localStorage.getItem(
      PaymentStorageVariable.Esewa
    )
    const bidVerify = pathname.includes("bid-tender")
    const pricingVerify = pathname.includes("pricing-plan")

    const data = {
      oid: searchParams.get("oid"),
      amt: searchParams.get("amt"),
      refId: searchParams.get("refId"),
    }
    if (pricingVerify || bidVerify) {
      postEsewaBidFee(data)
        .then((res) => {
          setLoading(false)
          if (pricingVerify) {
            paymentPromiseHandler(
              "Success",
              "/",
              navigate,
              PaymentStorageVariable.Esewa,
              res
            )
            return
          }

          paymentPromiseHandler(
            "Success",
            redirectPath as string,
            navigate,
            PaymentStorageVariable.Esewa,
            res
          )
        })
        .catch((err) => {
          paymentPromiseHandler(
            "Failed",
            redirectPath as string,
            navigate,
            PaymentStorageVariable.Esewa
          )
        })
        .finally(() => {
          setLoading(false)
        })
      return
    } else {
      postEsewaPublishFee(data)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false)
            paymentPromiseHandler(
              "Success",
              redirectPath as string,
              navigate,
              PaymentStorageVariable.Esewa,
              res
            )
          }
        })
        .catch((err) => {
          paymentPromiseHandler(
            "Failed",
            redirectPath as string,
            navigate,
            PaymentStorageVariable.Esewa
          )
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    esewaBidFee()
  }, [])

  return (
    <>
      <div className="verifying-page">
        <div className="varification-card">
          <div className="center-div">
            <div className="navbar-brand">
              <img src={require("../../images/bolpart-logo-05.png")} alt="logo" />
            </div>
            <div className="mg-20">
              <img
                src={require("../../gif/money.gif")}
                className="loading border-radius"
                alt="loading"
              />
            </div>
            <h3 className="big-font-title">
              {loading ? (
                <span>Payment verifying...</span>
              ) : (
                <span>Redirecting to page...</span>
              )}
            </h3>
          </div>
        </div>
      </div>
    </>
  )
}
