import "./ETenderContentShimmer.scss";

export const ETenderContentShimmer = () => {
  return (
    <div className="etender-shimmer-page">
      <div className="card-section">
        <div className="shimmer-overlay"></div>
        <div className="card-top">
          <div className="text-area">
            <p className="shimmer-text"></p>
            <p className="shimmer-text big-text"></p>
            <p className="shimmer-text big-text"></p>
            <p className="shimmer-text big-text"></p>
            <p className="shimmer-text text-40"></p>
          </div>
        </div>
      </div>
    </div>
  );
};
