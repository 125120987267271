import { Formik, Form, Field, ErrorMessage } from "formik"
import { useNavigate, useParams } from "react-router-dom"
import { ResetPassword } from "../../Components/Api/PutApi"
import * as Yup from "yup"
import { mdiClose, mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js"
import Icon from "@mdi/react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import "./PasswordResetPage.scss"
import { useState } from "react"
export interface ResetPasswordData {
  password: string
  confirm_password: string
}

const passwordValidation = Yup.object({
  password: Yup.string()
    .min(8, "More than 8 characters or more")
    .required("Required"),
  confirm_password: Yup.string()
    .required("Required")
    .when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], "Password Doesnot Match"),
    }),
})

export const PasswordResetPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [confirmShow, setConfirmShow] = useState(false)

  const resetpassword = (values: ResetPasswordData, token: any) => {
    ResetPassword(values, token)
      .then((res) => {
        toast.success(res.data.status.status_message)
        navigate("/")
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <Formik
      initialValues={{
        password: "",
        confirm_password: "",
      }}
      onSubmit={(values) => {
        resetpassword(values, id)
      }}
      validationSchema={passwordValidation}
    >
      <Form>
        <div className="reset-page">
        <div className="reset-popup">
          <div className="popup-nav">
            <div>
              <Link to="/" className="navbar-brand">
                <img
                  className="logo-size"
                  src={require("../../images/mero-bid2.png")}
                  alt="logo"
                />
              </Link>
            </div>
            <div className="close-icon">
              <Icon className=" mdi-close" path={mdiClose} />
            </div>
          </div>
          <div>
            <h5 className="text-title">Reset password</h5>
            <p className="form-label mg-bottom-20">
              Use 6 or more characters with a mix of letters, numbers & symbols
            </p>
          </div>
          <div className="mg-bottom">
            <label className="form-label">New Passwrod:</label>
            <Field
              name="password"
              type={show ? "text" : "password"}
              placeholder="Enter your password"
              className="form-control text-area"
            />
            <div
              className="close-icon"
              onClick={() => {
                setShow(!show)
              }}
            >
              {show ? (
                <Icon className=" mdi-eye" path={mdiEyeOutline} />
              ) : (
                <Icon className=" mdi-eye" path={mdiEyeOffOutline} />
              )}
            </div>
            <span className="error">
              <ErrorMessage name="password" component="div" />
            </span>
          </div>

          <div className="mg-bottom">
            <label className="form-label">Confirm Password</label>
            <Field
              name="confirm_password"
              type={confirmShow ? "text" : "password"}
              placeholder="Confirm your password"
              className="form-control text-area"
            />
            <div
              className="close-icon"
              onClick={() => {
                setConfirmShow(!confirmShow)
              }}
            >
              {confirmShow ? (
                <Icon className=" mdi-eye" path={mdiEyeOutline} />
              ) : (
                <Icon className=" mdi-eye" path={mdiEyeOffOutline} />
              )}
            </div>
            <span className="error">
              <ErrorMessage name="confirm_password" component="div" />
            </span>
          </div>
          <div className="bottom-btn">
            <button type="button" className="show-bid padd-btn">
              Cancel
            </button>
            <button type="submit" className="search-btn padd-btn">
              Save
            </button>
          </div>
          </div>
        </div>
      </Form>
    </Formik>
  )
}
