import "./MyBidShimmer.scss";

export const MyBidShimmer = () => {
  return (
    <div className="mybid-shimmer-page">
      <div className="card-section">
        <div className="shimmer-overlay"></div>
        <div className="card-top">
          <div className="text-area">
            <p className="shimmer-text small-text"></p>
            <p className="shimmer-text"></p>

            <p className="shimmer-text text-40"></p>
          </div>
        </div>
        <div className="footer">
          <div className="btn"></div>
          <div className="btn"></div>
          <div className="btn"></div>
        </div>
      </div>
      <div className="card-section margin-top-15">
        <div className="shimmer-overlay"></div>
        <div className="card-top">
          <div className="text-area">
            <p className="shimmer-text"></p>
            <p className="shimmer-text small-text"></p>
            <p className="shimmer-text text-40"></p>
          </div>
        </div>

        <div className="footer">
          <div className="btn"></div>
          <div className="btn"></div>
          <div className="btn"></div>
        </div>
      </div>
    </div>
  );
};
