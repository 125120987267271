import React, { useState, useEffect } from "react";
import "./ConfirmationPopUp.scss";
import { mdiInformationOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { CustomLoader } from "../Loader/Loader";
import OtpInput from "react-otp-input";
import { useTimer } from "../../Hooks/useTimer";

export interface Props {
  toggleModal?: () => void;
  onAprroveButton?: any;
  tender_id?: string | number | undefined;
  popUpContents: ConfirmMessage;
  activeButtonLoading?: boolean;
  otp?: string;
  setOtp?: React.Dispatch<React.SetStateAction<string>>;
  onResendOTP: any;
}
export interface ConfirmMessage {
  question1?: string;
  question2?: string;
  warn?: string;
  cancelBtn?: string;
  aprroveBtn?: string;
  content?: string;
}

export const OTPPopUp: React.FC<Props> = ({
  tender_id,
  toggleModal,
  onAprroveButton,
  popUpContents,
  activeButtonLoading = false,
  otp,
  setOtp,
  onResendOTP,
}) => {
  const RESEND_INTERVAL_S = 0;
  const [disableResendOtp, setDisableResentOtp] = useState(true);
  const { time, reStartTimer } = useTimer();

  useEffect(() => {
    if (disableResendOtp) {
      reStartTimer();
    }
  }, [disableResendOtp]);

  useEffect(() => {
    if (time === RESEND_INTERVAL_S) {
      setDisableResentOtp(false);
    }
  }, [time]);

  return (
    <div className="confirmation-popup">
      <div className="close-icon" onClick={toggleModal}>
        <Icon className=" mdi-close" path={mdiClose} />
      </div>
      <div className="info-logo">
        <Icon className="mdi-alert" path={mdiInformationOutline}></Icon>
      </div>
      <div>
        <h5 className="text-title">{popUpContents.question1}</h5>
        <p className="sub-header">{popUpContents.question2}</p>
      </div>
      <div className="popup-body">
        <div className="alert-section">
          <div>
            <Icon className="mdi-alert" path={mdiInformationOutline}></Icon>
          </div>
          <div>
            <p className="withdraw-bid">{popUpContents.warn}</p>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <p className="mb-2">
            <b>PLEASE ENTER OTP</b>
          </p>
          <OtpInput
            inputStyle="form-control otp-input-custom mb-2"
            inputType="tel"
            value={otp}
            onChange={(value) => (setOtp ? setOtp(value) : {})}
            numInputs={6}
            renderSeparator={<span>&nbsp;&nbsp;</span>}
            renderInput={(props) => <input {...props} />}
          />
          {disableResendOtp ? (
            <span className="text-secondary">
              You can resend otp after {time} sec
            </span>
          ) : (
            <span
              role="button"
              className="text-secondary"
              onClick={() => {
                onResendOTP(tender_id);
                setDisableResentOtp(true);
              }}
            >
              <u>Resend OTP</u>
            </span>
          )}
        </div>
      </div>

      <div className="popup-btn">
        <div>
          <button className="btn-cancel" onClick={toggleModal}>
            {popUpContents.cancelBtn}
          </button>
        </div>
        <div>
          <button
            className="btn-withdraw"
            disabled={!otp || otp?.length < 6 || activeButtonLoading}
            onClick={() => {
              onAprroveButton(tender_id);
            }}
          >
            {activeButtonLoading ? (
              <CustomLoader
                type="TailSpin"
                width={42}
                height={20}
                color="#FFFFFF"
              />
            ) : (
              <>{popUpContents.aprroveBtn}</>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
