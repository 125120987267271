import React, { createContext, useState } from "react"

interface PopupFlagsType {
  login: boolean
  forgetPw: boolean
  editCompanyDetail: boolean
}

interface ModalContextValues {
  popupFlags: PopupFlagsType
  setPopupFlags: React.Dispatch<React.SetStateAction<PopupFlagsType>>
  TogglePopUpFlags: (popupName: keyof PopupFlagsType) => void
}
const initialValues: ModalContextValues = {
  popupFlags: { login: false, forgetPw: false, editCompanyDetail: false },
  setPopupFlags: () => {},
  TogglePopUpFlags: (popupName: keyof PopupFlagsType) => {},
}

interface ModalContextProviderProps {
  children: any
}

export const ModalContext = createContext<ModalContextValues>(initialValues)
export const ModalContextProvider: React.FC<ModalContextProviderProps> = ({
  children,
}) => {
  const [popupFlags, setPopupFlags] = useState<PopupFlagsType>({
    login: false,
    forgetPw: false,
    editCompanyDetail: false,
  })

  const TogglePopUpFlags = (popupName: keyof PopupFlagsType) => {
    const cloned = { ...popupFlags }
    for (let i in cloned) {
      if (i !== popupName) {
        //@ts-ignore
        cloned[i] = false
      } else {
        cloned[popupName] = !cloned[popupName]
      }
    }
    setPopupFlags(cloned)
  }

  return (
    <>
      <ModalContext.Provider
        value={{ popupFlags, setPopupFlags, TogglePopUpFlags }}
      >
        {children}
      </ModalContext.Provider>
    </>
  )
}

// export const useModal = () => {
//   const modalContext = useContext(ModalContext)
//   if (!modalContext) {
//     return "useModal must be used with ModalContext"
//   }
//   return modalContext
// }
// export default ModalContextProvider
