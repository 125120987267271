import React from "react";
import "./AddJointVenture.scss";
import { DropZoneBox } from "../pdfDropzone/pdfDropzone";
import { Field, ErrorMessage } from "formik";
import { DocumentResponse } from "../EditBids/EditBids";
import { UploadedFile } from "../UploadedFile/UploadedFile";
import { useBidFormikContext } from "../FormikWrapper/BidFornikWrapper";
import { useParams } from "react-router-dom";
import { DocumentType } from "../../utils/EnumConstants";

export interface AddJointVentureProps {
  resDocument?: DocumentResponse | undefined;
  reFetchData?: () => void;
}

export const AddJointVenture: React.FC<AddJointVentureProps> = ({
  resDocument,reFetchData
}) => {
  const { errors } = useBidFormikContext();
  const { id } = useParams();

  return (
    <div className="add-venture">
      <div className="container">

        <div>
          <h5 className="drop-title">Joint Venture Name</h5>
        </div>
        <div>
          <div className="upload-venture">
            <Field
              name="jointVenture.joint_venture_name"
              type="text"
              className="form-control"
              placeholder=" Enter joint venture name"
            />
            <span className="error">
              <ErrorMessage name="jointVenture.joint_venture_name" component="span" />
            </span>
          </div>
        </div>

        <div>
          <h5 className="drop-title">Joint Venture Agreement</h5>
        </div>
        <div>
          <div className="upload-venture">
            <DropZoneBox
              name="jointVenture.venture_agreement"
              placeholder="Drag file here or Choose files from your device"
              label="Upload the joint venture agreement"
            />
            <span className="error">
              {errors?.jointVenture?.venture_agreement}
              {/* <ErrorMessage name="jointVenture.venture_agreement" /> */}
            </span>
            {resDocument?.joint_venture_documents?.venture_agreement && (
              <UploadedFile
                url={resDocument?.joint_venture_documents?.venture_agreement}
                tenderId={id}
                documentType={DocumentType.VENTURE}
                documentLabel={"venture_agreement"}
                deleteDocument={true}
                reFetchData={reFetchData}
              />
            )}
          </div>
        </div>

        <div className="border-line"></div>

        <div>
          <h5 className="drop-title">Power Of Attorney</h5>
        </div>
        <div>
          <div className="upload-venture">
            <DropZoneBox
              name="jointVenture.power_of_attorney"
              placeholder="Drag file here or Choose files from your device"
              label="Upload the attachment"
            />
            <span className="error">
              {errors?.jointVenture?.power_of_attorney}
            </span>
            {resDocument?.joint_venture_documents?.power_of_attorney && (
              <UploadedFile
                url={resDocument?.joint_venture_documents?.power_of_attorney}
                documentType={DocumentType.VENTURE}
                documentLabel={"power_of_attorney"}
                deleteDocument={true}
                reFetchData={reFetchData}
                tenderId={id}
              />
            )}
          </div>
        </div>

        <div className="border-line"></div>

        <div>
          <h5 className="drop-title">Partner Document</h5>
        </div>
        <div>
          <div className="upload-venture">
            <DropZoneBox
              name="jointVenture.partner_document"
              placeholder="Drag file here or Choose files from your device"
              label="Upload the attachment"
            />
            <span className="error">
              {errors?.jointVenture?.partner_document}
            </span>
            {resDocument?.joint_venture_documents?.partner_document && (
              <UploadedFile
                url={resDocument?.joint_venture_documents?.partner_document}
                documentType={DocumentType.VENTURE}
                documentLabel={"partner_document"}
                deleteDocument={true}
                reFetchData={reFetchData}
                tenderId={id}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
