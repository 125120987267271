import "./CompanyPolicy.scss";
export const AboutUs = () => {
  return (
    <div className="container company-content">
      <h2 style={{ textAlign: "center" }}>
        <strong>About Us</strong>
      </h2>
      <div className="row col-sm-12">
        <div className="mar">
          <p>
            <b>Bolpatra Nepal</b> provides you an easy and convenient way of viewing
            different tenders from across the nation. It is one of the most used
            and trusted platform by the bidders in Nepal. Get state of art
            functionality which provides a convenient solution as well as saves
            your precious time of searching the tender information.
          </p>
          <h4>Our Features</h4>
          <ul>
            <li>
              Tender information from <b>70 + National & Local Newspaper</b>
            </li>
            <li>
              Tender information related to <b>PPMO</b>
            </li>
            <li>Push Notification & Email Notification</li>
            <li>Tender Award / Result Information</li>
            <li>Save Bids for Easy Access</li>
            <li>Sub Contract Facilities</li>
            <li>Flexible Plans</li>
          </ul>
          <p>
          <b>Bolpatra Nepal</b> facilitates beyond tender information by providing
            you an extensive access to sub contracting and vendor searching
            facilities. Download the App Today to learn more.
          </p>
        </div>
      </div>
    </div>
  );
};
