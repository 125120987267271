import React, { useEffect } from "react";
import "./TableRows.scss";
import { FieldArray, Field, ErrorMessage } from "formik";
import { useTenderFormik } from "../FormikWrapper/TenderFormikWrapper";
import { CalculateBoqTotal } from "../utils/BoqTotal";
import { fixedValue } from "../../utils/services";

interface Props {
  index: number;
  name: string;
  removeCategory: () => void;
  additionalColumns?: string[];
  // values: any
}
const TableRows: React.FC<Props> = ({
  index,
  name,
  removeCategory,
  additionalColumns,
}) => {
  const { values, setFieldValue, setFieldTouched } = useTenderFormik();

  const totalAmount = (index: number, id: number) => {
    let amount =
      values.boq[index].item[id].rate *
      parseFloat(values.boq[index].item[id].quantity);
    if (isNaN(amount)) {
      return 0;
    }
    return amount.toFixed(2);
  };

  let arrayHelpersInstance;

  useEffect(() => {
    if (values.boq[index].item.length === 0) {
      removeCategory();
    }
  }, [values.boq[index].item.length]);

  const handleBoqDetails = () => {
    const val = { ...values };
    const cloned = [...values.boq];
    if (cloned.length > 0) {
      const total = fixedValue(Number(CalculateBoqTotal(cloned)));
      const vat = val?.is_vat ? fixedValue(Number(0.13 * total)) : 0;

      const grand_total = val?.is_vat ? fixedValue(Number(total + vat)) : total;
      setFieldValue("boq_details.boq_total", total);
      setFieldValue("boq_details.vat", vat);
      setFieldValue("boq_details.grand_total", grand_total);
    }
  };

  useEffect(() => {
    handleBoqDetails();
  }, [values]);

  const btnWidth = !additionalColumns ? 6 : additionalColumns.length + 6;
  return (
    // imp
    <>
      {values.boq[index].item.length > 0 &&
        values.boq[index].item.map((val: any, id: any) => {
          return (
            <tr key={index}>
              <FieldArray
                name={name}
                render={(arrayHelpers) => {
                  arrayHelpersInstance = arrayHelpers;
                  return (
                    <>
                      <td>
                        <p>{id + 1}</p>
                      </td>
                      <td>
                        <div>
                          <Field
                            autoFocus={true}
                            className="form-control"
                            type="text"
                            name={`boq[${index}].item[${id}].item_description`}
                            placeholder="Enter the Item/Work Description"
                          />
                          <span className="error">
                            <ErrorMessage
                              component="div"
                              name={`boq[${index}].item[${id}].item_description`}
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <Field
                          autoFocus={true}
                          className="form-control"
                          type="text"
                          name={`boq[${index}].item[${id}].unit`}
                          placeholder="Unit"
                        />
                        <span className="error">
                          <ErrorMessage
                            component="div"
                            name={`boq[${index}].item[${id}].unit`}
                          />
                        </span>
                      </td>
                      <td>
                        <Field
                          autoFocus={true}
                          className="form-control sm-area"
                          name={`boq[${index}].item[${id}].quantity`}
                          placeholder="0"
                          type="text"
                          onFocus={() => {
                            // Manually trigger validation on field focus
                            setFieldTouched(
                              `boq[${index}].item[${id}].quantity`,
                              true
                            );
                          }}
                        />
                        <span className="error">
                          <ErrorMessage
                            component="div"
                            name={`boq[${index}].item[${id}].quantity`}
                          />
                        </span>
                      </td>
                      <td>
                        <Field
                          className="form-control mid-area"
                          name={`boq[${index}].item[${id}].rate`}
                          type="text"
                          onChange={(e: any) => {
                            const fieldName = `boq[${index}].item[${id}].rate`;
                            const fieldValue = e.target.value;
                            setFieldValue(fieldName, fieldValue);
                            if (fieldValue > 0) {
                              setFieldValue(
                                `boq[${index}].item[${id}].blank`,
                                false
                              );
                            } else {
                              setFieldValue(
                                `boq[${index}].item[${id}].blank`,
                                true
                              );
                            }
                          }}
                          onFocus={(e: any) => {
                            const fieldName = `boq[${index}].item[${id}].rate`;
                            const fieldValue = values.boq[index].item[id].rate;

                            if (fieldValue === 0) {
                              setFieldValue(fieldName, "");
                            }

                            setFieldTouched(fieldName, true);
                          }}
                          onBlur={(e: any) => {
                            const fieldName = `boq[${index}].item[${id}].rate`;
                            const fieldValue = values.boq[index].item[id].rate;

                            if (fieldValue.toString() === "") {
                              setFieldValue(fieldName, 0);
                              setFieldValue(
                                `boq[${index}].item[${id}].blank`,
                                true
                              );
                            } else {
                              setFieldValue(fieldName, e.target.value);
                              setFieldValue(
                                `boq[${index}].item[${id}].blank`,
                                false
                              );
                            }
                          }}
                        />
                        <div className="d-flex">
                          <span className="error">
                            <ErrorMessage
                              component="div"
                              name={`boq[${index}].item[${id}].rate`}
                            />
                          </span>
                        </div>
                        <div className="d-flex">
                          <div className="checkbox-group">
                            <div className="checkbox-list">
                              <Field
                                name={`boq[${index}].item[${id}].fixed`}
                                className="checkbox"
                                type="checkbox"
                              />
                              <label>Fixed</label>
                            </div>
                          </div>
                        </div>
                      </td>
                      {additionalColumns?.map(() => {
                        return <td></td>;
                      })}
                      <td>
                        <div className="d-flex justify-sapce-between">
                          <div>{totalAmount(index, id)}</div>
                          {values.boq[0].item.length === 1 &&
                          values.boq.length === 1 ? (
                            ""
                          ) : (
                            <button
                              type="button"
                              style={{
                                fontSize: "1rem",
                              }}
                              className="x-btn"
                              onClick={() => {
                                arrayHelpers.remove(id);
                                //remove additional data fron field
                                var newAdditionalColumns = [
                                  ...values.boq[index].additional_columns,
                                ];
                                newAdditionalColumns.splice(id, 1);
                                setFieldValue(
                                  `boq[${index}].additional_columns`,
                                  newAdditionalColumns
                                );
                              }}
                            >
                              <i className="bi bi-x-circle"></i>
                            </button>
                          )}
                        </div>
                      </td>
                    </>
                  );
                }}
              />
            </tr>
          );
        })}
      <tr>
        <td colSpan={btnWidth}>
          <button
            type="button"
            className="btn-show mg-btn"
            onClick={() => {
              arrayHelpersInstance?.push({
                item_description: "",
                unit: "",
                quantiy: "",
                rate: 0,
                fixed: false,
                blank: true,
              });
              var newAdditionalColumns = values.boq[index].additional_columns;
              var newAdditionalColumnObj = {};
              if (additionalColumns && additionalColumns.length > 0) {
                additionalColumns.map((newColumn) => {
                  newAdditionalColumnObj = {
                    ...newAdditionalColumnObj,
                    [newColumn]: "",
                  };
                });
                newAdditionalColumns.push(newAdditionalColumnObj);
                setFieldValue(
                  `boq[${index}].additional_columns`,
                  newAdditionalColumns
                );
              }
            }}
          >
            <i className="bi bi-plus"></i>Add New Item
          </button>
        </td>
      </tr>
    </>
  );
};

export default TableRows;
