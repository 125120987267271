import { RegisterInfo } from "../RegisterInfo/RegisterInfo";
import { RegisterStepper } from "../RegisterStepper/RegisterStepper";
import "./RegisterForm.scss";

export const RegisterForm = () => {
  return (
    <div className="register-form">
      <div className="container">
        <div className="row">
          <div className="col-md-4 pr-0 ">
            <RegisterInfo />
          </div>
          <div className="col-md-8 pl-0">
            <div className="right-box">
              <div className="container">
                <h3 className="head-titile">Register</h3>
                <RegisterStepper />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
