import React, { useState } from "react"
import Icon from "@mdi/react"
import { mdiClose, mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js"
import "../../Components/EditDetails/ChangePassword.scss"
import { ErrorMessage, Field, Formik, Form } from "formik"
import * as Yup from "yup"
import { CustomLoader } from "../Loader/Loader"
export interface Props {
  toggleModal?: () => void
  onSubmit: any
}

export interface Password {
  password1: string
  password2: string
}

const validationSchema = Yup.object({
  // old_password: Yup.string()
  //   .min(8, "More than 8 characters or more")
  //   .required("Required"),
  password: Yup.string()
    .min(8, "More than 8 characters or more")
    .required("Required"),
  confirm_password: Yup.string()
    .required("Required")
    .when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], "Password Doesnot Match"),
    }),
})

const ChangePassword: React.FC<Props> = ({ toggleModal, onSubmit }) => {
  // const [oldPasswordShow, setoldPasswordShow] = useState(false)
  const [newPasswordShow, setNewPasswordShow] = useState(false)
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)

  // const toggleOldPassword = () => {
  //   setoldPasswordShow(!oldPasswordShow)
  // }
  const toggleNewPassword = () => {
    setNewPasswordShow(!newPasswordShow)
  }
  const toggleConfirmPassword = () => {
    setConfirmPasswordShow(!confirmPasswordShow)
  }

  return (
    <div className="change-password-section">
      <div className="top-head">
        <div className="update-password">Update password</div>
        <div className="close-icon" onClick={toggleModal}>
          <Icon className=" mdi-close" path={mdiClose} />
        </div>
      </div>

      <Formik
        initialValues={{
          confirm_password: "",
          password: "",
          // old_password: "",
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
         {({ isSubmitting }) => (
        <Form>
          <div className="col-md-12">
            {/* <div className="material-textfield">
              <Field
                name="old_password"
                className="text-area"
                type={oldPasswordShow ? "text" : "password"}
                placeholder="Old Password"
              />
              <span className="error">
                <ErrorMessage name="old_password" />
              </span>
              <span className="eyeoff-outline" onClick={toggleOldPassword}>
                <Icon
                  path={oldPasswordShow ? mdiEyeOutline : mdiEyeOffOutline}
                />
              </span>
            </div> */}
            <div className="material-textfield">
              <Field
                name="password"
                className="text-area"
                type={newPasswordShow ? "text" : "password"}
                placeholder="New Password"
              />
              <span className="error">
                <ErrorMessage name="password" />
              </span>
              <span className="eyeoff-outline" onClick={toggleNewPassword}>
                <Icon
                  path={newPasswordShow ? mdiEyeOutline : mdiEyeOffOutline}
                />
              </span>
            </div>
            <div className="material-textfield">
              <Field
                name="confirm_password"
                className="text-area"
                type={confirmPasswordShow ? "text" : "password"}
                placeholder="Confirm Password"
              />
              <span className="error">
                <ErrorMessage name="confirm_password" />
              </span>
              <span className="eyeoff-outline" onClick={toggleConfirmPassword}>
                <Icon
                  path={confirmPasswordShow ? mdiEyeOutline : mdiEyeOffOutline}
                />
              </span>
            </div>
          </div>

          <div className="submit-btn">
            <button
                className="search-btn pd-btn"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div>
                    <CustomLoader
                      color="white"
                      width={40}
                      height={25}
                      type="TailSpin"
                    />
                  </div>
                ) : (
                  <span>Update</span>
                )}
              </button>
          </div>
        </Form>
      )}
      </Formik>
    </div>
  )
}

export default ChangePassword
