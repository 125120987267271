import { FileImage } from "../../utils/EnumConstants";

export const getFileImage = (fileType: string) => {
  if (fileType === "application/pdf") {
    return FileImage.pdf;
  } else if (fileType === "application/msword") {
    return FileImage.word;
  } else if (
    ["application/zip", "application/x-zip-compressed"].includes(fileType)
  ) {
    return FileImage.zip;
  } else if (
    [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ].includes(fileType)
  ) {
    return FileImage.xls;
  } else return FileImage.pic;
};
