import Icon from "@mdi/react"
import { mdiClose } from "@mdi/js"
import "./SubscriptionPlan.scss"
import Modal from "react-modal"

import { faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { KhaltiPaymentInitiate } from "../../Khalti/KhaltiNewIntegration/KhaltiIntergationConfig"
import { useLocation } from "react-router-dom"
import { PaymentFeeType } from "../../utils/EnumConstants"
import { generateToken } from "../utils/GenerateToken"
import { esewaPaymentInitiation } from "../EsewaPayment/EsewaPayemnt"

export interface SubscriptionProps {
  toggleModal: any
  selectedPlan: {
    amount: number
    id: number | null
  }
}

export const SubscriptionPlan: React.FC<SubscriptionProps> = ({
  toggleModal,
  selectedPlan,
}) => {
  const [openKhalti, setOpenKhalti] = useState(false)
  const [openEsewa, setOpenEsewa] = useState(false)
  const { pathname } = useLocation()

  const toggleKModal = () => {
    setOpenKhalti(!openKhalti)
  }

  const toggleEModal = () => {
    setOpenEsewa(!openEsewa)
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
      padding: "25px",
    },
  }
  const token = generateToken()

  return (
    <div className="subscription-plan-section">
      <div
        className="close-icon"
        onClick={() => {
          toggleModal()
        }}
      >
        <div className="modal-title"> Payment Options</div>
        <Icon className=" mdi-close" path={mdiClose} />
      </div>
      <div className="payment-section">
        <div className="payment-method">
          <img
            src={require("../../images/khalti.png")}
            alt="khalti"
            onClick={() => {
              setOpenKhalti(!openKhalti)
              KhaltiPaymentInitiate(
                pathname,
                PaymentFeeType.Subscription,
                selectedPlan.amount,
                selectedPlan.id as number
              )
            }}
          />
          <img
            src={require("../../images/esewa.png")}
            alt="esewa"
            onClick={() => {
              esewaPaymentInitiation(
                selectedPlan.amount,
                selectedPlan.id as number,
                token,
                PaymentFeeType.Subscription,
                pathname
              )
            }}
          />
        </div>

        <div className="bank-details">
          <p className="sub-title ">Direct Bank Deposit / Transfer</p>

          <div className="direct-bank-content">
            <div className="row">
              <div className="col-md-8">
                <img
                  src={require("../../images/MBL.jpg")}
                  className="mbl-img"
                  alt="mbl"
                />
                <ul>
                  <li>BOLPATRA NEPAL PVT LTD</li>
                  <li>A/C No: 0320083649100014</li>
                  <li>Branch: Pepsicola, Kathmandu</li>
                </ul>
              </div>
              <div className="col-md-4">
                <div className="details-list">
                  <label>eSewa ID</label>

                  <p>9849447788</p>

                  <label>Khalti ID</label>

                  <p>9849135266</p>
                </div>
              </div>
            </div>

            <ul className="list-unstyled pl-0">
              <li>
                <FontAwesomeIcon className="icon-bg" icon={faCaretRight} />
                Contact to 9849447788/9823090145 after payments.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="payment-modal-footer">
        <p>
          For any information, please contact our support team
          <span>
            <img
              src={require("../../images/crisp.png")}
              className="crisp-img"
              alt="crisp"
            />
          </span>
        </p>
      </div>
    </div>
  )
}
