import "./AdShimmer.scss";

export const AdShimmer = () => {
  return (
    <div className="right-side-card-section">
      <div className="right-side-card-content"></div>
      <div className="right-side-card-content"></div>
      <div className="right-side-card-content"></div>
    </div>
  );
};
