import { toast } from "react-toastify";

export const catch500Error = (status: number, status_message: any) => {
  if (status === 400 || status === 401) {
    if (typeof status_message === "string") {
      return toast.error(status_message);
    }
    if (typeof status_message === "object") {
      Object.entries(status_message).forEach(([key, value]) => {
        (value as string[]).map((msg: string) => {
          var upperMsg = key.toUpperCase() + ":" + msg.toUpperCase();
          return toast.error(upperMsg);
        });
      });
    }
  } else if (status === 500) {
    return toast.error(status_message);
  } else {
    return toast.error("Something Went Wrong!");
  }

  return null;
};

export const catchError = (status: number, status_message: string | object) => {
  if (status === 400 || status === 401) {
    if (typeof status_message === "string") {
      return toast.error(status_message);
    }
    if (typeof status_message === "object") {
      Object.entries(status_message).forEach(([key, value]) => {
        (value as string[]).map((msg: string) => {
          var upperMsg = key.toUpperCase() + ": " + msg.toUpperCase();
          return toast.error(upperMsg);
        });
      });
    }
  } else {
    return toast.error("Something Went Wrong!");
  }
};
