import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getPetty } from "../../../Components/Api/GetApi";
import { ResultShimmer } from "../../Results/ResultShimmer";
import "./SubContract.scss";

export interface PettyProps {
  loadApi: boolean;
}
export interface ResponsePetty {
  work: string;
  address: string;
  phone: string;
  rate: string;
  company: string;
}
export const SubContract: React.FC<PettyProps> = ({ loadApi }) => {
  const [petty, setPetty] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPettyApi();

    if (loadApi === true) {
      getPettyApi();
    }
  }, [loadApi]);

  const getPettyApi = () => {
    setLoading(true);
    getPetty()
      .then((res) => {
        setPetty(res.data.data);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="subcontract-section">
      <div className="subcontract-margin">
        {/* <p className="contract-date small-content">Mon, Nov 20 (Today)</p> */}

        {loading ? (
          <ResultShimmer />
        ) : (
          <div className="subcontract-card">
            {petty?.map((item: ResponsePetty) => {
              return (
                <div className="card card-section">
                  <div className="subcontract-number-content">
                    <div className="subcontract-header ">
                      <div className="d-flex subcontract-content">
                        <img
                          src={require("../../../images/mdi-map.png")}
                          alt="location-img"
                        />
                        <p className="filter-content  contract-tag">
                          Location: <span>{item?.address}</span>
                        </p>
                      </div>

                      <div className="d-flex subcontract-content">
                        <img
                          src={require("../../../images/call.png")}
                          alt="location-img"
                        />

                        <p className="filter-content  contract-tag">
                          Phone No: <span>{item?.phone}</span>
                        </p>
                      </div>
                      <div className="d-flex subcontract-content">
                        <img
                          src={require("../../../images/rate.png")}
                          alt="location-img"
                        />

                        <p className="filter-content  contract-tag">
                          Rate: <span>Rs {item?.rate}</span>
                        </p>
                      </div>
                    </div>
                    {/* 
                  <img
                    className="threedot"
                    src={require("../../../images/threedot.png")}
                    alt="threedot"
                  /> */}
                  </div>

                  <div className="subcontract-details">
                    <p className="medium-content main-title">{item?.work}</p>

                    <p className="sub-title contract-title">{item?.company}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
