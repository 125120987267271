import { toast } from "react-toastify"
import { PaymentFeeType, PaymentStorageVariable } from "../../utils/EnumConstants"
import { postEsewaTransaction } from "../Api/PostApi"
import { EseewaTransactionData } from "../BidFee/BidFee"
import { catchError } from "../utils/catchError"

export interface EsewayParams{
    amt: number,
    psc: number
    pdc: number
    txAmt: number
    tAmt: number
    pid: any
    scd: string
    su: string
    fu: string
}

const initiationParams=(fee_for:PaymentFeeType,token:string,amount:number|string,pathname:string)=>{
  const baseURl=window.location.origin
  const commonPath='esewa/payment_verification'
  const params:any={
    amt:amount,
    psc: 0,
    pdc: 0,
    txAmt: 0,
    tAmt: amount,
    pid: token,
    scd: process.env.REACT_APP_ESEWA_ACTUAL_SCD,
    fu: `${baseURl}${pathname}`,
  }
  switch(fee_for){
    case PaymentFeeType.Bid:
      params.su=`${window.location.origin}/bid-tender/${commonPath}`
      break;
    case PaymentFeeType.Publish:
      params.su=`${window.location.origin}/publish-tender/${commonPath}`
      break;
    case PaymentFeeType.Subscription:
      params.su=`${window.location.origin}/pricing-plan/${commonPath}`
      break;
    default:
      return
  }
  return params
}


export const esewaPaymentInitiation = (amount:number,id:number|string,token:string, fee_for:PaymentFeeType, pathname:any) => {
  window.localStorage.setItem(PaymentStorageVariable.Esewa, pathname)
  const initiationData:EseewaTransactionData={
    amount:amount,
    tender_notice_id:id,
    fee_for:fee_for,
    token:token
  }
  const params=initiationParams(fee_for,token,amount,pathname)
  const data={...initiationData}
  if(fee_for===PaymentFeeType.Subscription){
    delete data.tender_notice_id
    data.plan_id=id
  }
  postEsewaTransaction(data)
    .then((res) => {
      if (res.status === 200) {
        toast.success(res.data.status.status_message)
        setTimeout(() => {
          esewaPayment(params)
        }, 1000)
      }
    })
    .catch((err) => {
      window.localStorage.removeItem(PaymentStorageVariable.Esewa)
      catchError(err.response.status, err.response.data.status.status_message)
    })
}

export function esewaPayment( params: EsewayParams) {
  var path = "https://esewa.com.np/epay/main"
    var form = document.createElement("form")
    form.setAttribute("method", "POST")
    form.setAttribute("action", path)

    for (var key  in params) {
      var hiddenField = document.createElement("input")
      hiddenField.setAttribute("type", "hidden")
      hiddenField.setAttribute("name", key)
      hiddenField.setAttribute("value", params[key as keyof EsewayParams])
      form.appendChild(hiddenField)
    }
    document.body.appendChild(form)
    form.submit()
  }
