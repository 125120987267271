import { useEffect, useState } from "react";


export const ImageWithFallback: React.FC<{
    src: string;
    fallbackSrc: string;
    alt: string;
  }> = ({ src, fallbackSrc, alt }) => {
    const [imageSrc, setImageSrc] = useState(src);
  
    const handleError = () => {
      setImageSrc(fallbackSrc);
    };
  
    useEffect(() => {
      setImageSrc(src);
    }, [src]);
  
    return <img src={imageSrc} alt={alt} onError={handleError} />;
  };
  