import { mdiInformationOutline } from "@mdi/js"
import Icon from "@mdi/react"
import './index.scss'

export const Note=({children}:{children:React.ReactNode| string}):JSX.Element=>{

return <div className="row">
      <div className="info-flex col">
        <Icon
          className="mdi-information"
          path={mdiInformationOutline}
        />
        <p className="info-content">
        {children}
        </p>
      </div>
    </div>

}