import "../NewBanner/NewBanner.scss"

const SavedBidBanner = () => {
  return (
    <>
      <div className="new-banner" style={{ backgroundImage: "url(https://i.imgur.com/Hehx4E6.jpg)" }}>
      </div>
    </>
  )
}

export default SavedBidBanner
