export const Provinces=[
    {
      "value": "Province No. 1",
      "label": "Province No. 1"
    },
    {
      "value": "Province No. 2",
      "label": "Province No. 2"
    },
    {
      "value": "Province No. 3",
      "label": "Province No. 3"
    },
    {
      "value": "Gandaki",
      "label": "Gandaki"
    },
    {
      "value": "Province No. 5",
      "label": "Province No. 5"
    },
    {
      "value": "Karnali",
      "label": "Karnali"
    },
    {
      "value": "SudurPaschim",
      "label": "SudurPaschim"
    }
  ]