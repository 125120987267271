import React, { createContext, useContext, useEffect, useState } from "react";
import { ResponseBoqObject } from "../../utils/interface";

export type BidDocumentsTab = {
  [key: string]: string;
};
export interface ISubmitData {
  documentTab: BidDocumentsTab;
  boqTab: {
    boq: ResponseBoqObject[];
    boq_catalogue_name: string;
  };
  feeTab: {
    payment: string;
  };
}

export interface BidContextType {
  setCompleteBidDetails: React.Dispatch<
    React.SetStateAction<ISubmitData | undefined>
  >;
  completeBidDetails: ISubmitData | undefined;
  setBidErrors: React.Dispatch<React.SetStateAction<Object>>;
  bidErrors: Object;
}

// type Children = {
//   children: React.ReactNode; // 👈️ type children
// };

const initialValues: BidContextType = {
  setCompleteBidDetails: () => {},
  completeBidDetails: {} as ISubmitData,
  bidErrors: {},
  setBidErrors: () => {},
};

export const BidContext = createContext<BidContextType>(initialValues);

export const BidProvider: React.FC<any> = (props) => {
  const [completeBidDetails, setCompleteBidDetails] = useState<ISubmitData>();
  const [bidErrors, setBidErrors] = useState<Object>({});

  return (
    <>
      <BidContext.Provider
        value={{
          setCompleteBidDetails,
          completeBidDetails,
          bidErrors,
          setBidErrors,
        }}
      >
        {props.children}
      </BidContext.Provider>
    </>
  );
};

export const useBid = () => {
  const bidContext = useContext(BidContext);
  if (!bidContext) {
    throw new Error("useBid must be used with BidContext");
  }
  return bidContext;
};
export default BidProvider;
