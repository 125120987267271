import React, { useState, useEffect, useRef } from "react";
import "./NewTender.scss";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { getTenderAttrs } from "../Api/GetApi";
import { NepaliDistricts } from "../../Datas/Districts";
import {
  PublishTenderData,
  initialValues,
} from "../FormikWrapper/TenderFormikWrapper";
import CustomSelect, { Option } from "../SelectComponent/SelectComponent";
import { NoteComponent } from "../NoteComponent/NoteComponent";
import { addDaysInDate } from "../utils/generic";
import { UploadedFile } from "../UploadedFile/UploadedFile";
import { DropZoneBox } from "../pdfDropzone/pdfDropzone";
import {
  getDateDifferenceInDays,
  isNoticeCategoryStandingList,
} from "../../utils/services";
import { BidCategory, TenderAwardType, NoticeCategory } from "../../utils/EnumConstants";
import { ConfirmationPopUp } from "../PopUp/ConfirmationPopUp";
import { toast } from "react-toastify";
import { CustomLoader } from "../Loader/Loader";
import Modal from "react-modal";

interface Props {
  page: number;
  setPage: (e: number) => void;
  setPublishingDate?: any;
  bankDetail?: any;
  resDocument?: any;
  type: string;
  loading?: any;
  publishLoad?: boolean;
}
export interface BankDetail {
  bank_name: string;
  account_number: number;
  account_name: string;
  branch: string;
  amount_extra?: boolean;
}

const BidBondValidityDays = [
  {
    label: 30,
    value: 30,
  },
  {
    label: 60,
    value: 60,
  },
  {
    label: 90,
    value: 90,
  },
  {
    label: 120,
    value: 120,
  },
  {
    label: 150,
    value: 150,
  },
  {
    label: 180,
    value: 180,
  },
  {
    label: 210,
    value: 210,
  },
];

const category = [
  {
    label: "Single-Envelope",
    value: BidCategory.SINGLE_ENVELOPE,
  },
  {
    label: "Double-Envelope",
    value: BidCategory.DOUBLE_ENVELOPE,
  },
];

const awardType = [
  {
    label: "Single Bidder",
    value: TenderAwardType.SINGLE_BIDDER,
  },
  {
    label: "Multiple Bidder",
    value: TenderAwardType.MULTIPLE_BIDDER,
  },
];

const BidFee = [
  { label: "No Cost", value: 0 },
  { label: "Rs. 2000", value: 2000.00 },
  { label: "Rs. 3000", value: 3000.00 },
  { label: "Rs. 4000", value: 4000.00 },
  { label: "Rs. 5000", value: 5000.00 },
  { label: "Rs. 6000", value: 6000.00 },
  { label: "Rs. 7000", value: 7000.00 },
  { label: "Rs. 8000", value: 8000.00 },
  { label: "Rs. 9000", value: 9000.00 },
  { label: "Rs. 10000", value: 10000.00 },
];

export const NewTender: React.FC<Props> = ({
  bankDetail,
  resDocument,
  type,
  loading,
  publishLoad,
}) => {
  const { values, setFieldValue, setValues, errors, handleSubmit, validateForm } =
    useFormikContext<typeof initialValues>();

  const [noticeCat, setNoticeCat] = useState<Option[]>([]);
  const [projectCat, setProjectCat] = useState<Option[]>([]);
  const [estimated_cost, setEstimatedCost] = useState<Option[]>([]);
  const [filterNoticeCat, setFilterNoticeCat] = useState<Option[]>([]);
  const [note, setNote] = useState("");
  const [minSubmissionDate, setMinSubmissionDate] = useState("");
  const [minPublishingDate, setMinPublishingDate] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const isNoticeStandingList = isNoticeCategoryStandingList(type);

  let noticeBasedCategoryRef = useRef([
    NoticeCategory.TENDER_QUOTATION,
    NoticeCategory.AUCTION,
    NoticeCategory.RENT,
  ]);

  const handleChangeCatalogueExtra = (value: boolean) => {
    setFieldValue("catalogue_extra", value);
    if (!value) {
      setFieldValue("boq_catalog_name", "");
    }
  };

  const handleChangeOptionalDocument = (value: boolean) => {
    setFieldValue("has_optional_document", value);
    if (!value) {
      setFieldValue("optional_documents", "");
    }
  };

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const fetchCategory = async () => {
    try {
      const res = await getTenderAttrs();
      if (res.status === 200) {
        setNoticeCat(res.data.data.notice_category);
        setProjectCat(res.data.data.project_category);
        setEstimatedCost(res.data.data.estimated_cost);

        //set the notice category label if edit
        if (values.notice_category?.value) {
          setFieldValue(
            "notice_category.label",
            res.data.data.notice_category.find(
              (each: Option) => each.value === values.notice_category?.value
            ).label
          );
        } else if (isNoticeStandingList) {
          const noticeCategoryOption = res.data?.data?.notice_category?.find(
            (each: Option) => each.label.toLowerCase().includes(type)
          );
          setFieldValue("notice_category.label", noticeCategoryOption.label);
          setFieldValue("notice_category.value", noticeCategoryOption.value);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const bankDetailSetting = () => {
    if (values.same_as_previous) {
      setFieldValue("bank_name", values.bank_name || bankDetail?.bank_name);
      setFieldValue("branch", values.branch || bankDetail?.branch);
      setFieldValue(
        "account_number",
        values.account_number || bankDetail?.account_number
      );
      setFieldValue(
        "account_name",
        values.account_name || bankDetail?.account_name
      );
    } else {
      setFieldValue("bank_name", "");
      setFieldValue("branch", "");
      setFieldValue("account_number", "");
      setFieldValue("account_name", "");
    }
  };

  useEffect(() => {
    bankDetailSetting();
  }, [values.same_as_previous]);

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    if (values.category === BidCategory.DOUBLE_ENVELOPE) {
      noticeBasedCategoryRef.current = [
        NoticeCategory.TENDER_QUOTATION,
        NoticeCategory.RFP,
        NoticeCategory.EOI,
      ];
    }
    let noticeCategory = noticeCat.filter((item: Option) =>
      noticeBasedCategoryRef.current.some((value) =>
        item.label.toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilterNoticeCat(noticeCategory);
  }, [noticeCat, values.category]);

  // for technical result
  useEffect(() => {
    let noteExtraContent = "";
    if (values.category === BidCategory.DOUBLE_ENVELOPE) {
      noteExtraContent = "for technical result ";
    }
    setNote(
      `Last Bids submission time will be 12:00 PM and opening date ${noteExtraContent}will be 2:00 PM on same date of submission day.`
    );
  }, [values.category]);

  const handleSubmisstionDateUpdate = (
    publishingDate: string,
    submissionDate: string
  ) => {
    const differenceInDays = getDateDifferenceInDays(
      publishingDate,
      submissionDate
    );
    if (differenceInDays < 5) {
      const submissionDate = addDaysInDate(publishingDate, 5);
      setMinSubmissionDate(submissionDate);
      setFieldValue("submission_date", submissionDate);
      return;
    }
    setFieldValue("submission_date", submissionDate);
  };

  const handleBidBondSecurityChange = () => {
    const newValues: PublishTenderData = {
      ...values,
      bid_bond_security: false,
      bid_bond_validity: 0,
      bid_bond_security_amount: "",
    };
    setValues(newValues);
  };

  const handleBidFeeAmount = () => {
    const newValues: PublishTenderData = {
      ...values,
      has_bid_fee: false,
      publisher_bid_fee: null,
    };
    setValues(newValues);
  };

  useEffect(() => {
    if (resDocument) {
      const submissionDate = addDaysInDate(values.publishing_date, 5);
      setMinSubmissionDate(submissionDate);
    }
  }, [resDocument, values.publishing_date]);

  useEffect(() => {
    const currentDate = new Date();
    if (resDocument) {
      currentDate.setDate(new Date(values.publishing_date).getDate());
    } else {
      currentDate.setDate(currentDate.getDate());
    }
    const minPublishingDate = currentDate.toISOString().split("T")[0];
    setMinPublishingDate(minPublishingDate);
    setMinSubmissionDate(addDaysInDate(minPublishingDate, 5));
  }, []);

  const customLoginStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "40%",
    },
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        style={customLoginStyles}
        ariaHideApp={false}
      >
        <ConfirmationPopUp
          popUpContents={{
            question1: "Are you sure want to publish notice?",
            // warn: "Your tender would be published",
            aprroveBtn: "Yes",
            cancelBtn: "Cancel",
          }}
          toggleModal={toggleModal}
          activeButtonLoading={publishLoad}
          onAprroveButton={() => {
            setFieldValue("saved", false);
            toggleModal();
            if (errors && Object.keys(errors).length > 0) {
              toast.error("You have got unresolved error");
              return;
            }
            handleSubmit();
          }}
          acceptTerm={true}
          publisherTerm={true}
        />
      </Modal>

      <div className="new-tender">
        <div className="mb-3">
          <div className="outer-box">
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">
                  Notice Id <span className="red">*</span>
                </label>
                <Field
                  placeholder="Enter Notice Id"
                  className="form-control"
                  name="tender_number"
                  aria-describedby="emailHelp"
                />
                <span className="error">
                  <ErrorMessage name="tender_number" />
                </span>
              </div>
              {!isNoticeStandingList && (
                <>
                  <div className="col-md-6">
                    <label className="form-label">
                      Bidding Category<span className="red">*</span>
                    </label>
                    <Field
                      className=""
                      name="category"
                      options={category}
                      component={CustomSelect}
                      placeholder="Select category"
                      isMulti={false}
                      onChange={(selected: Option) => {
                        setFieldValue("category", selected.value);
                      }}
                    />
                    <span className="error">
                      <ErrorMessage name="category" />
                    </span>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      Award Type<span className="red">*</span>
                    </label>
                    <Field
                      className=""
                      name="award_type"
                      options={awardType}
                      component={CustomSelect}
                      placeholder="Select award type"
                      isMulti={false}
                    />
                    <span className="error">
                      <ErrorMessage name="award_type" />
                    </span>
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">
                      Notice Category<span className="red">*</span>
                    </label>
                    <Field
                      className=""
                      value={values.notice_category}
                      options={filterNoticeCat}
                      component={CustomSelect}
                      placeholder="Select notice category"
                      isMulti={false}
                      onChange={(selected: Option) => {
                        if (!isNoticeStandingList)
                          setFieldValue("notice_category", selected);
                      }}
                    />
                    <span className="error">
                      <ErrorMessage name="notice_category" />
                    </span>
                  </div>
                </>
              )}

              <div className="col-md-6">
                <label className="form-label">
                  Project Type<span className="red">*</span>
                </label>
                <Field
                  className=""
                  name="project_category"
                  options={projectCat}
                  component={CustomSelect}
                  placeholder="Select Project Category"
                  isMulti={true}
                />

                <span className="error">
                  <ErrorMessage name="project_category" />
                </span>
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  Project Location<span className="red">*</span>
                </label>
                <Field
                  className=""
                  name="work_locations"
                  options={NepaliDistricts}
                  component={CustomSelect}
                  placeholder="Select work location"
                  isMulti={true}
                />
                <span className="error">
                  <ErrorMessage name="work_locations" />
                </span>
              </div>

              <div className="col-md-6">
                <label className="form-label">
                  Publishing Date <span className="red">*</span>
                </label>
                <Field
                  type="date"
                  placeholder="Select Publishing Date"
                  className="form-control"
                  value={values?.publishing_date}
                  onChange={(e: any) => {
                    setFieldValue("publishing_date", e.target.value);
                    handleSubmisstionDateUpdate(
                      e.target.value,
                      values?.submission_date as string
                    );
                  }}
                  min={minPublishingDate}
                />
                <span className="error">
                  <ErrorMessage name="publishing_date" />
                </span>
              </div>
              {!isNoticeStandingList && (
                <div className="col-md-6">
                  <label className="form-label">
                    Estimated Bid Cost <span className="red">*</span>
                  </label>
                  <Field
                    className=""
                    name="estimated_cost"
                    options={estimated_cost}
                    component={CustomSelect}
                    placeholder="Select Estimated Cost"
                    isMulti={false}
                  />
                  <span className="error">
                    <ErrorMessage name="estimated_cost" />
                  </span>
                </div>
              )}
              <div className="col-md-6">
                <label className="form-label">
                  Last Submission Date /Deadline <span className="red">*</span>
                </label>

                <Field
                  name="submission_date"
                  type="date"
                  className="form-control"
                  value={values?.submission_date}
                  min={minSubmissionDate}
                />
                <span className="error">
                  <ErrorMessage name="submission_date" />
                </span>
                <NoteComponent note={note} traingle={true} />
              </div>

            </div>
            <div className="line"></div>
            {!isNoticeStandingList && (
              <>
                <p className="sub-content">Bid Bond Security</p>
                <div className="col-md-4 pl-0">
                  <div className="mg-top-5">
                    <p className=" form-label catalog-label mg-top-15">
                      Do you need <b>Bid Bond</b> from Bidders?
                      <span className="error">
                        <ErrorMessage name="bid_bond_security" />
                      </span>
                    </p>
                  </div>
                  <div className="mg-top-5">
                    <div className="check-section">
                      <div className="radio-box">
                        <input
                          type="radio"
                          id="yes"
                          className="radio"
                          value="true"
                          //@ts-ignore
                          checked={values.bid_bond_security}
                          onChange={() => {
                            setFieldValue("bid_bond_security", true);
                          }}
                        />
                        <label htmlFor="yes">Yes</label>
                      </div>

                      <div className="radio-box">
                        <input
                          value="false"
                          type="radio"
                          className="radio"
                          id="no"
                          checked={!values.bid_bond_security}
                          onChange={handleBidBondSecurityChange}
                        />

                        <label htmlFor="no">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bidbond-section">
                  <div className="col-md-4 mg-top-5">
                    <label className="form-label">
                      Bid Bond Security Amount{" "}
                      <span className="high-light">
                        (Hint- 2.5% of Tender Amount)
                      </span>
                    </label>

                    <Field
                      name="bid_bond_security_amount"
                      type="text"
                      placeholder="Enter Bid Bond Security Amount"
                      className="form-control"
                    />
                    <span className="error">
                      <ErrorMessage name="bid_bond_security_amount" />
                    </span>
                  </div>

                  <div className="col-md-4 mg-top-5 mg-left-20">
                    <label className="form-label">Bid Bond Validity</label>
                    <Field
                      name="bid_bond_validity"
                      options={BidBondValidityDays}
                      component={CustomSelect}
                      placeholder="Select Bid Bond Validity Days"
                      isMulti={false}
                      isSearchable={false}
                    />

                    <span className="error">
                      <ErrorMessage name="bid_bond_validity" />
                    </span>
                  </div>
                </div>
              </>
            )}
            <div className="col-md-4 mg-top-5">
              <p className=" form-label">
                Select Your Publishing Plan
                <span className="error">
                  <ErrorMessage name="bid_bond_security" />
                </span>
              </p>
              <div className="mg-top-5">
                <div className="check-section">
                  <div className="radio-box">
                    <input
                      type="radio"
                      className="radio"
                      value="true"
                      id="paid"
                      //@ts-ignore
                      checked={values.has_bid_fee}
                      onChange={() => {
                        setFieldValue("has_bid_fee", true);
                      }}
                    />
                    <label htmlFor="paid">Paid Plan</label>
                  </div>

                  <div className="radio-box">
                    <input
                      value="false"
                      type="radio"
                      className="radio"
                      id="free"
                      checked={!values.has_bid_fee}
                      onChange={handleBidFeeAmount}
                    />

                    <label htmlFor="free">Free Plan</label>
                  </div>
                </div>
              </div>
              <div className="mg-top-5">
                <label className="form-label">Select your Bid Fee</label>
                <Field
                  name="publisher_bid_fee"
                  options={BidFee}
                  component={CustomSelect}
                  placeholder="Enter Bid Fee amount"
                />
                <span className="error">
                  <ErrorMessage name="publisher_bid_fee" />
                </span>
              </div>
            </div>
            {/* <div className="line"></div> */}
            <p className="sub-content mt-24">Bank Details</p>
            <div className="bigbond-checkbox mg-top-5">
              <Field name="same_as_previous" type="checkbox" />
              <label className="form-label same-as-previous">
                Same as previous
              </label>
            </div>
            <div className="">
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label">Bank Name</label>
                  <Field
                    name="bank_name"
                    type="text"
                    placeholder="Enter Bank Name"
                    className="form-control"
                  />
                  <span className="error">
                    <ErrorMessage name="bank_name" />
                  </span>
                </div>

                <div className="col-md-3">
                  <label className="form-label">Branch</label>
                  <Field
                    name="branch"
                    placeholder="Enter branch"
                    className="form-control"
                  />
                  <span className="error">
                    <ErrorMessage name="branch" />
                  </span>
                </div>
                <div className="col-md-3">
                  <label className="form-label">Account Number</label>
                  <Field
                    name="account_number"
                    type="text"
                    placeholder="Enter account number"
                    className="form-control"
                  />
                  <span className="error">
                    <ErrorMessage name="account_number" />
                  </span>
                </div>
                <div className="col-md-3">
                  <label className="form-label">Account Name</label>
                  <Field
                    name="account_name"
                    type="text"
                    placeholder="Enter account name"
                    className="form-control"
                  />
                  <span className="error">
                    <ErrorMessage name="account_name" />
                  </span>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className="">
              <div className="require-document">
                <p className="sub-content form-label">
                  Select the Document that you require from the Bidders
                  <span className="mt-4">
                    {" "}
                    <NoteComponent
                      note="All Bidder Company Documents such as company registration
                    certificate, PAN/VAT Certificate & Tax Clearance will be
                    automatically submitted by Bidder"
                      traingle={false}
                    />
                  </span>
                  <span className="error" style={{ fontWeight: "600" }}>
                    <ErrorMessage name="document_needed.bid_letter" />
                  </span>
                </p>
              </div>

              <div className="row mb-10">
                <div className="col-md-3 form-check">
                  <Field
                    name="document_needed.bid_letter"
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label className="form-check-label">
                    Letter of Application (Bid Letter)
                  </label>
                </div>
                <div className="col-md-3  form-check ">
                  <Field
                    name="document_needed.declaration_letter"
                    className="form-check-input "
                    type="checkbox"
                  />
                  <label className="form-check-label mg-left">
                    Declaration Letter
                  </label>
                </div>
                <div className="col-md-3 form-check">
                  <Field
                    name="document_needed.applicants_information"
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label className="form-check-label">
                    Applicant Information
                  </label>
                </div>
                <div className="col-md-3 form-check">
                  <Field
                    name="document_needed.experience"
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label className="form-check-label">
                    Experience(General,Specific & Other)
                  </label>
                </div>
                <div className="col-md-3 form-check">
                  <Field
                    name="document_needed.capacity"
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label className="form-check-label">Capacity</label>
                </div>
                <div className="col-md-3 form-check">
                  <Field
                    name="document_needed.qualification"
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label className="form-check-label">
                    Qualification of key experts
                  </label>
                </div>
                <div className="col-md-3 form-check">
                  <Field
                    name="document_needed.citizenship"
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label className="form-check-label">Citizenship</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="base-content">
                <p className="catalog-label mg-top-5">
                  Do you want any other compulsory documents from participants?
                </p>
                <div className="mg-top-15">
                  <div className="check-section">
                    <div id="checkbox1" className="radio-box">
                      <input
                        type="radio"
                        className="radio"
                        value="true"
                        id="catalogue_yes"
                        checked={values.catalogue_extra}
                        onChange={() => handleChangeCatalogueExtra(true)}
                      />
                      <label htmlFor="catalogue_yes">Yes</label>
                    </div>

                    <div id="radio1" className="radio-box">
                      <input
                        value="false"
                        type="radio"
                        id="catalogue_no"
                        className="radio"
                        checked={!values.catalogue_extra}
                        onChange={() => handleChangeCatalogueExtra(false)}
                      />

                      <label htmlFor="catalogue_no">No</label>
                    </div>
                  </div>
                </div>

                {values.catalogue_extra && (
                  <div className="mg-top-5">
                    <label className="catalog-label form-label">
                      Catalog Name:{" "}
                      <span className="high-light">
                        Please separate multiple file names using commas(,).
                      </span>
                    </label>
                    <Field
                      placeholder="Specify document or catalogue name"
                      className="form-control"
                      type="input"
                      name="boq_catalog_name"
                    />
                    <span className="error">
                      <ErrorMessage name="boq_catalog_name" />
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="base-content">
                <p className="catalog-label mg-top-5">
                  Do you want any other optional documents from participants?
                </p>
                <div className="mg-top-15">
                  <div className="check-section">
                    <div id="checkbox1" className="radio-box">
                      <input
                        type="radio"
                        className="radio"
                        id="optional_yes"
                        value="true"
                        checked={values.has_optional_document}
                        onChange={() => handleChangeOptionalDocument(true)}
                      />
                      <label htmlFor="optional_yes">Yes</label>
                    </div>

                    <div id="radio1" className="radio-box">
                      <input
                        value="false"
                        type="radio"
                        className="radio"
                        id="optional_no"
                        checked={!values.has_optional_document}
                        onChange={() => handleChangeOptionalDocument(false)}
                      />

                      <label htmlFor="optional_no">No</label>
                    </div>
                  </div>
                </div>
                {values.has_optional_document && (
                  <div className="mg-top-5">
                    <label className="catalog-label form-label">
                      Optional Documents Name:{" "}
                      <span className="high-light">
                        Please separate multiple file names using commas(,).
                      </span>
                    </label>
                    <Field
                      placeholder="Specify optional document name"
                      className="form-control"
                      type="input"
                      name="optional_documents"
                    />
                    <span className="error">
                      <ErrorMessage name="optional_documents" />
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="line"></div>
            <div className="row">
              <div className="col-md-7">
                <div className="mg-top-15">
                  <p className="sub-content">
                    Upload your Bid Document(Terms & Conditions, Requirements,
                    Required Specification & Sample)
                  </p>
                  <DropZoneBox
                    name="specification_terms_format"
                    placeholder="Drag file here or Choose files from your device "
                    label="Upload the attachment"
                  />
                  <span className="d-flex error">
                    <ErrorMessage name="specification_terms_format" />
                  </span>
                  {resDocument?.specification_terms_format && (
                    <UploadedFile
                      url={resDocument?.specification_terms_format}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* <div className="col-md-4">
              <div className="mg-top-15 mb-4">
                <p className="sub-content">
                  Do you want to show bidder detail to others?
                </p>
                <div className="check-section mg-top-5">
                  <div id="checkbox1" className="check-box">
                    <input
                      type="radio"
                      className="radio"
                      value="true"
                      checked={values.hide_bidder === "Yes"}
                      onChange={() => {
                        setFieldValue("hide_bidder", "Yes");
                      }}
                    />
                    <label htmlFor="radio1">Yes</label>
                  </div>

                  <div id="radio1" className="radio-box">
                    <input
                      value="false"
                      type="radio"
                      className="radio"
                      checked={values.hide_bidder === "No"}
                      onChange={() => {
                        setFieldValue("hide_bidder", "No");
                      }}
                    />
                    <label htmlFor="checkbox1">No</label>
                  </div>
                </div>
              </div>
              <span className="error">
                <ErrorMessage name="hide_bidder" />
              </span>
            </div> */}

            <div className="col-md-12 right">
              {isNoticeStandingList && (
                <button
                  className="btn transparent-btn btn-edit mr-2"
                  type="submit"
                  onClick={() => {
                    setFieldValue("saved", true);
                  }}
                >
                  {loading ? (
                    <CustomLoader
                      type="TailSpin"
                      color="#FFFFFF"
                      height={22}
                      width={33}
                    />
                  ) : (
                    <span>Save</span>
                  )}
                </button>
              )}
              {isNoticeStandingList ? (
                <button
                  type="button"
                  disabled={
                    loading || publishLoad
                  }
                  className="btn btn-edit delete-btn"
                  onClick={() => {
                    validateForm().then((errors) => {
                      if (errors && Object.keys(errors).length > 0) {
                        toast.error("Fill in all required fields with valid details");
                        return;
                      }
                      toggleModal();
                    });
                  }}
                >
                  Publish
                </button>
              ) : (
                <button onClick={() => {
                  if (errors && Object.keys(errors).length > 0) {
                    toast.error("Fill in all required fields with valid details");
                    return;
                  } else {
                    handleSubmit();
                  }
                }} className="btn btn-edit primary-btn">
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
