import { ErrorMessage, Field, useFormikContext } from "formik";
import React, { Dispatch, SetStateAction } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Role, SIGNUPAS } from "../../utils/EnumConstants";
import { IPhoneInput } from "../EditDetails/EditPersonalDetails";
import { initialvalues } from "../FormikWrapper/RegisterFormikWrapper";
import "./StepperOne.scss";
import { CustomLoader } from "../Loader/Loader";

export interface IOnboardingOne {
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  Page?: number;
  label?: string;
  phoneNumber?: string;
  setPhoneNumber?: (phoneNumber: string) => void;
  check1: boolean;
  setCheck1: (e: boolean) => void;
  check2: boolean;
  setCheck2: (e: boolean) => void;
  setSkipValue: Dispatch<SetStateAction<boolean>>;
  otpLoading: boolean;
}

export const OnbordingOne: React.FC<IOnboardingOne> = ({
  setPage,
  Page,
  label,
  check1,
  setCheck1,
  check2,
  setCheck2,
  setSkipValue,
  otpLoading,
}) => {
  const { values, setValues } = useFormikContext<typeof initialvalues>();

  const { setFieldValue } = useFormikContext();

  return (
    <>
      {Page === 2 ? (
        ""
      ) : (
        <div className="register-form ">
          <div className=" outer-box">
            <div className="row">
              <div className="col-lg-12">
                <label className="form-label">Register as</label>
                <div className="d-flex">
                  <div
                    id="radio1"
                    className={check1 ? "active radio-box" : "radio-box"}
                    onClick={() => {
                      setCheck1(true);
                      setCheck2(false);
                      setFieldValue("role", Role.Bidder);
                    }}
                  >
                    <div className="d-flex">
                      <Field
                        type="radio"
                        name="role"
                        value={Role.Bidder}
                        checked={check1}
                      />
                      <label htmlFor="radio1">As a Bidder</label>
                    </div>
                    <p className="sub-title">
                      Who wants to bid or view the latest tenders.
                    </p>
                  </div>
                  <div
                    id="radio1"
                    className={check2 ? "active radio-box" : "radio-box"}
                    onClick={() => {
                      setCheck2(true);
                      setCheck1(false);
                      setFieldValue("role", Role.Publisher);
                      setSkipValue(false);
                      setFieldValue("skip", "true");
                      setFieldValue("sign_up_as", "");
                    }}
                  >
                    <div className="d-flex">
                      <Field
                        name="role"
                        value="Publisher"
                        type="radio"
                        checked={check2}
                      />
                      <label htmlFor="radio1">As a Publisher</label>
                    </div>
                    <p className="sub-title">
                      Who wants to publish their tender.
                    </p>
                  </div>
                </div>

                <span className="error">
                  <ErrorMessage component="div" name="role" />
                </span>
              </div>

              <div className="col-xl-4 col-lg-12">
                <div className="mg-bottom">
                  <label className="form-label">
                    First Name<span className="red">*</span>
                  </label>
                  <Field
                    name="first_name"
                    type="text"
                    placeholder="Enter first name"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                  <span className="error">
                    <ErrorMessage name="first_name" />
                  </span>
                </div>
              </div>
              <div className="col-xl-4 col-lg-12">
                <div className="mg-bottom">
                  <label className="form-label">Middle Name</label>
                  <Field
                    name="middle_name"
                    type="text"
                    placeholder="Enter middle name"
                    className="form-control"
                  />
                  <span className="error">
                    <ErrorMessage name="middle_name" />
                  </span>
                </div>
              </div>
              <div className="col-xl-4 col-lg-12">
                <div className="mg-bottom">
                  <label className="form-label">
                    Last Name<span className="red">*</span>
                  </label>
                  <Field
                    name="last_name"
                    type="text"
                    placeholder="Enter last name"
                    className="form-control"
                  />
                  <span className="error">
                    <ErrorMessage name="last_name" />
                  </span>
                </div>
              </div>

              <div className="col-xl-6 col-lg-12">
                <div className="mg-bottom">
                  <label className="form-label">
                    Contact Number<span className="red">*</span>
                  </label>
                  <div className="">
                    {label && <span className="label">{label}</span>}
                    <div className="">
                      <div className="react-tel-input">
                        <PhoneInput
                          country={"np"}
                          value={values.contact_no}
                          onChange={(value, data: IPhoneInput) => {
                            setValues({
                              ...values,
                              contact_no: value,
                              country_code: data?.dialCode,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <span className="error">
                    <ErrorMessage name="contact_no" />
                  </span>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12">
                <div className="mg-bottom">
                  <label className="form-label">
                    Email<span className="red">*</span>
                  </label>
                  <Field
                    name="email"
                    type="email"
                    placeholder="Enter email"
                    className="form-control"
                  />
                  <span className="error">
                    <ErrorMessage name="email" />
                  </span>
                </div>
              </div>

              <div className="col-xl-6 col-lg-12">
                <div className="mg-bottom">
                  <label className="form-label">
                    Password<span className="red">*</span>
                  </label>
                  <Field
                    name="password"
                    type="password"
                    placeholder="Enter password"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                  <span className="error">
                    <ErrorMessage name="password" />
                  </span>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12">
                <div className="mg-bottom">
                  <label className="form-label">
                    Confirm Password<span className="red">*</span>
                  </label>
                  <Field
                    name="confirmpassword"
                    type="password"
                    placeholder="Confirm password"
                    className="form-control"
                  />
                  <span className="error">
                    <ErrorMessage name="confirmpassword" />
                  </span>
                </div>
              </div>
              {values.role === Role.Bidder && (
                <div className="col-lg-12">
                  <div className="d-flex personnel-section">
                    <label className="form-label text-nowrap">
                      Sign Up as:<span className="red">*</span>
                    </label>
                    <div
                      id="radio3"
                      className={
                        values.sign_up_as === SIGNUPAS.COMPANY
                          ? "active radio-box"
                          : "radio-box"
                      }
                      onClick={() => {
                        setFieldValue("sign_up_as", SIGNUPAS.COMPANY);
                      }}
                    >
                      <div className="d-flex">
                        <Field
                          type="radio"
                          name="sign_up_as"
                          value="Company"
                          checked={values.sign_up_as === SIGNUPAS.COMPANY}
                        />
                        <label htmlFor="radio3">Company</label>
                      </div>
                    </div>
                    <div
                      id="radio4"
                      className={
                        values.sign_up_as === SIGNUPAS.INDIVIDUAL
                          ? "active radio-box"
                          : "radio-box"
                      }
                      onClick={() => {
                        setFieldValue("sign_up_as", SIGNUPAS.INDIVIDUAL);
                      }}
                    >
                      <div className="d-flex">
                        <Field
                          name="sign_up_as"
                          value="Individual"
                          type="radio"
                          checked={values.sign_up_as === SIGNUPAS.INDIVIDUAL}
                        />
                        <label htmlFor="radio4">Individual</label>
                      </div>
                    </div>
                  </div>
                  <span className="error">
                    <ErrorMessage component="div" name="sign_up_as" />
                  </span>
                </div>
              )}
              <div className="col-md-12">
                <hr />
              </div>
              <div className="col-lg-12">
                <div className="line-section primary-section">
                  <button
                    className="btn primary-btn"
                    type="submit"
                    disabled={otpLoading}
                  >
                    {otpLoading ? (
                      <CustomLoader
                        type="TailSpin"
                        color="#FFFFFF"
                        height={20}
                        width={48}
                      />
                    ) : (
                      <span>Next</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
