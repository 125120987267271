import { Form, Formik, Field, ErrorMessage } from "formik"
import { mdiClose } from "@mdi/js"
import Icon from "@mdi/react"
import "react-toastify/dist/ReactToastify.css"
import * as Yup from "yup"
import "./ForgetPw.scss"
import { useContext, useState } from "react"
import { postForgetPassword } from "../../Api/PostApi"
import { toast } from "react-toastify"
import { CustomLoader } from "../../Loader/Loader"
import { catchError } from "../../utils/catchError"
import { ModalContext } from "../../Context/ModalContext/ModalContext"

export interface Props {
  toggleFPModal?: () => void
}
export interface DataEmail {
  email: string
}
export const ForgetPw: React.FC<Props> = ({ toggleFPModal }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { TogglePopUpFlags } = useContext(ModalContext)

  function showForm() {
    setIsOpen(!isOpen)
  }
  const EmailPost = (data: DataEmail) => {
    setLoading(true)
    postForgetPassword(data)
      .then((res) => {
        toast.success(res.data.status.status_message)
        TogglePopUpFlags("forgetPw")
      })
      .catch((err) => {
        console.log(err.response)
        catchError(err.response.status, err.response.data.status.status_message)
      })
      .finally(() => setLoading(false))
  }
  return (
    <>
      <div className="forgot-pw">
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Invalid email address")
              .required("Required"),
          })}
          onSubmit={(values) => {
            EmailPost(values)
          }}
        >
          <Form>
            <div className="card">
              <div
                className="close-icon"
                onClick={() => {
                  TogglePopUpFlags("forgetPw")
                }}
              >
                <Icon className=" mdi-close" path={mdiClose} />
              </div>
              <div>
                <h4 className="text-title">Forgot Password?</h4>
                <div className="blue-highlight mg-bottom-20">
                  <p>
                    We will send you a recovery email with a link to reset your
                    password.
                  </p>
                </div>
              </div>

              <div className="material-textfield field-box">
                <Field
                  name="email"
                  className="text-area"
                  type="text"
                  placeholder=""
                />
                <label className="text-field">Email Id</label>
                <span className="error">
                  <ErrorMessage name="email" />
                </span>
              </div>
              <div className="bottom-btn">
                <button
                  type="button"
                  className="show-bid padd-btn"
                  onClick={() => {
                    TogglePopUpFlags("login")
                  }}
                >
                  Back To Sign In
                </button>
                <button
                  type="submit"
                  className="search-btn padd-btn"
                  disabled={loading}
                >
                  {loading ? (
                    <CustomLoader
                      type="TailSpin"
                      color="#000BFF"
                      width={30}
                      height={30}
                    />
                  ) : (
                    <span>Send Link</span>
                  )}
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  )
}
