import { useContext, useState } from "react";
import "./Login.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { mdiClose, mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { CustomLoader } from "../Loader/Loader";
import { ModalContext } from "../Context/ModalContext/ModalContext";

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState<Boolean>(false);
  const { LogIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const { TogglePopUpFlags } = useContext(ModalContext);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const location: any = useLocation();
  let navigation = location.state?.from || "/";
  const patternToMatch = /^\/bids\/view\/\d+$/;
  if (patternToMatch.test(location.pathname)){
    navigation = location
  }
  return (
    <>
      <div className="login-page">
        <Formik
          initialValues={{
            email: "",
            password: "",
            remember_me: false,
          }}
          validationSchema={Yup.object({
            email: Yup.string().required("Required"),
            password: Yup.string().min(8, "More than 8 characters or more"),
          })}
          onSubmit={(values) => {
            LogIn(values, navigate, navigation, loading, setLoading);
          }}
        >
          <Form>
            <div className="card">
              <div className="popup-nav">
                <div>
                  <Link to="/" className="navbar-brand">
                    <img
                      className="logo-size"
                      src={require("../../images/bolpart-logo-05.png")}
                      alt="logo"
                    />
                  </Link>
                </div>
                <div
                  className="close-icon"
                  onClick={() => {
                    TogglePopUpFlags("login");
                  }}
                >
                  <Icon className=" mdi-close" path={mdiClose} />
                </div>
              </div>

              <div>
                <h5 className="large-content">Log In</h5>
              </div>
              <div className="material-textfield">
                <Field
                  name="email"
                  className="text-area"
                  type="text"
                  placeholder=" "
                />
                <label className="text-field">Phone Number or Email Id</label>
                <span className="error">
                  <ErrorMessage name="email" />
                </span>
              </div>

              <div className="material-textfield">
                <Field
                  name="password"
                  className="text-area"
                  type={passwordShown ? "text" : "password"}
                  placeholder=" "
                />
                <label className="text-field">Password</label>
                <span className="error">
                  <ErrorMessage name="password" />
                </span>
                <span className="eyeoff-outline" onClick={togglePassword}>
                  <Icon
                    path={passwordShown ? mdiEyeOutline : mdiEyeOffOutline}
                  />
                </span>
              </div>

              <div className="checkbox-content">
                <div className="text-center">
                  <div className="checkbox-header">
                    <label className="box-field ">
                      <span className="custom-content">Remember me</span>

                      <Field name="remember_me" type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div
                  onClick={() => {
                    TogglePopUpFlags("forgetPw");
                  }}
                >
                  <p className="small-content">Forgot Password?</p>
                </div>
              </div>

              <div>
                <button className="submit-btn" type="submit" disabled={loading}>
                  {loading ? (
                    <>
                      <CustomLoader
                        type="TailSpin"
                        color="#FFFFFF"
                        height={25}
                        width={52}
                      />
                    </>
                  ) : (
                    <span>Log In</span>
                  )}
                </button>
              </div>
              <div className="title-header">
                <span className="custom-content">
                  New User?
                  <Link to="/register-form" className="small-content">
                    <span
                      onClick={() => {
                        TogglePopUpFlags("login");
                      }}
                    >
                      Register for free
                    </span>
                  </Link>
                </span>
                <hr></hr>
                <p>
                  Login / Register गर्दा समस्या भएमा कृप्या सम्पर्क गर्नु होला ।
                  9849447788 / 9802347788
                </p>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};
