import { TailSpin, ThreeDots } from "react-loader-spinner"
import "./Loader.scss"
export interface LoaderProps {
  type?: string
  width: number
  height: number
  color: string
}

export const CustomLoader: React.FC<LoaderProps> = ({
  type,
  width,
  height,
  color,
}) => {
  if (type === "TailSpin") {
    return (
      <div className="custom-loader">
        <TailSpin color={color} width={width} height={height} />
      </div>
    )
  }
  if (type === "ThreeDots") {
    return (
      <div className="custom-loader">
        <ThreeDots color={color} width={width} height={height} />
      </div>
    )
  }

  return (
    <div className="custom-loader">
      <TailSpin color="#00BFFF" width={30} height={30} />
    </div>
  )
}
