import "./BidderForm.scss";
import "../BOQ/BOQ.scss";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { useTable } from "react-table";
import {
  getBidOpenDateTime,
  isNoticeCategoryStandingList,
  isPublisher,
} from "../../utils/services";
import { BidCategory } from "../../utils/EnumConstants";

const currentDate = new Date();

export const BiddersTable = ({ bidders, bidDetail }: any) => {
  // const [fileName, setFileName] = useState("");
  const isNoticeStandingList = isNoticeCategoryStandingList(
    bidDetail.notice_category
  );

  const hideTotal =
    isNoticeStandingList ||
    (bidDetail?.category === BidCategory.DOUBLE_ENVELOPE &&
      (bidDetail?.opening_date === null ||
        getBidOpenDateTime(bidDetail?.opening_date).getTime() >
          currentDate.getTime()));

  const technicalPass =
    bidDetail?.category === BidCategory.DOUBLE_ENVELOPE || isNoticeStandingList;

  const param = useParams();
  const { role, userData } = useAuth();

  const renderTechnicalPass = (value: any) => {
    switch (value) {
      case true:
        return <span className="green-highlight">Pass</span>;
      case false:
        return <span className="red-highlight">Failed</span>;
      default:
        return <span className="yellow-highlight">Pending</span>;
    }
  };

  const renderStatus = (value: string) => {
    switch (value) {
      case "Awarded":
        return <span className="green-highlight">{value}</span>;
      default:
        return <span className="red-highlight">{value}</span>;
    }
  };

  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        Header: "Organization Name",
        accessor: "organization_name",
        show: true,
      },
      {
        Header: "Location",
        accessor: "location",
        show: true,
      },
      {
        Header: "Technical Evaluation",
        accessor: "technical_pass",
        show: technicalPass,
        Cell: ({ row }: { row: any }) =>
          renderTechnicalPass(row?.values.technical_pass),
      },
      {
        Header: "Grand Total",
        accessor: "grand_total",
        show: !hideTotal,
      },
      {
        Header: "Status",
        accessor: "status",
        show: true,
        Cell: (cell: any) => renderStatus(cell.row.values.status),
      },
      {
        Header: "Remarks",
        accessor: "remark",
        show: true,
      },
      {
        Header: "Action",
        accessor: "bidder_id",
        show: isPublisher(role) && userData.id === bidDetail.publisherId,
        Cell: (cell: any) => (
          <p
            onClick={() => {
              navigate(`/bids/view/${param.id}/${cell.row.values.bidder_id}`, {
                state: bidDetail,
              });
            }}
            className="link-font "
          >
            View Document
          </p>
        ),
      },
    ],
    []
  );

  const data = React.useMemo(() => bidders, [bidders]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      initialState: {
        hiddenColumns: columns
          .filter((col) => col.show === false)
          .map((col) => col.accessor),
      },
    });

  return (
    <>
      <div className="bidder-wrapper">
        <div className="container">
          <table
            id="print-table"
            className="table table-bordered table-striped"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => {
                return (
                  <tr
                    className="table-header"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(
                      (column) => {
                        return (
                          <th
                            className="text-center"
                            {...column.getHeaderProps()}
                          >
                            {column.render("Header")}

                            {/* Add a sort direction indicator */}
                            {/* <span className="float-right">
                              {
                                //@ts-ignore
                                column.isSorted ? (
                                  <Icon
                                    className="icon-size"
                                    path={mdiArrowDownBold}
                                  />
                                ) : (
                                  <Icon
                                    className="icon-size"
                                    path={mdiArrowUpBold}
                                  />
                                )
                                //@ts-ignore
                                //   column.isSortedDesc
                                //   ? " 🔽"
                                //   : " 🔼"
                                // : ""
                              }
                            </span> */}
                          </th>
                        );
                      }

                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                    )}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="content text-center"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <td colSpan={6} className="text-center">
                  No Bidders Yet!
                </td>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
