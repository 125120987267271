import { useEffect, useState } from "react"
import "../UserEmailVerification/UserEmailVerification"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { toast } from "react-toastify"
import {
  postEsewaBidFee,
  postKhaltiBidFeeVerify,
  postKhaltPublishFeeVerify,
} from "../Api/PostApi"
import { useAuth } from "../Context/AuthContext"
import { catchError } from "../utils/catchError"
import { PaymentStorageVariable } from "../../utils/EnumConstants"
import { paymentPromiseHandler } from "../../utils/services"

export interface VerificationDataTypes {
  pidx: string
  amount: number | string
  mobile: string
  purchase_order_id: string
  purchase_order_name: string
  transaction_id: string
}

export const KhaltiBidFeeVerification = () => {
  const [loading, setLoading] = useState(true)
  let [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()

  const navigate = useNavigate()

  const khaltiBidFee = () => {
    //Fetching the redirect path
    const redirectPath = window.localStorage.getItem(
      PaymentStorageVariable.Khalti
    )
    //Check if error param is found
    const checkError = searchParams.get("tendermessage")
    //Verify which payment fee type it is
    const bidVerify = pathname.includes("bid-tender")
    const pricingVerify = pathname.includes("pricing-plan")

    if (checkError) {
      //Checking error from the khalti integration as message param will appear if error found
      toast.error("Error in Fee payment")
      navigate(redirectPath as string)
      window.localStorage.removeItem(PaymentStorageVariable.Khalti)
      return
    }
    const verficationData: VerificationDataTypes = {
      pidx: searchParams.get("pidx") as string,
      amount: searchParams.get("amount") as string,
      mobile: searchParams.get("mobile") as string,
      purchase_order_id: searchParams.get("purchase_order_id") as string,
      purchase_order_name: searchParams.get("purchase_order_name") as string,
      transaction_id: searchParams.get("transaction_id") as string,
    }
    //Same api for bid and pricing
    if (bidVerify || pricingVerify) {
      postKhaltiBidFeeVerify(verficationData)
        .then((res) => {
          if (pricingVerify)
            return paymentPromiseHandler(
              "Success",
              "/",
              navigate,
              PaymentStorageVariable.Khalti,
              res
            ) //Navigating to main page when its pricing plan subscription
          paymentPromiseHandler(
            "Success",
            redirectPath as string,
            navigate,
            PaymentStorageVariable.Khalti,
            res
          )
        })
        .catch((err) => {
          paymentPromiseHandler(
            "Failed",
            redirectPath as string,
            navigate,
            PaymentStorageVariable.Khalti
          )
        })
      return
    } else {
      postKhaltPublishFeeVerify(verficationData)
        .then((res) => {
          paymentPromiseHandler(
            "Success",
            redirectPath as string,
            navigate,
            PaymentStorageVariable.Khalti,
            res
          )
        })
        .catch((err) => {
          paymentPromiseHandler(
            "Failed",
            redirectPath as string,
            navigate,
            PaymentStorageVariable.Khalti
          )
        })
    }
  }

  useEffect(() => {
    khaltiBidFee()
  }, [])

  return (
    <>
      <div className="verifying-page">
        <div className="varification-card">
          <div className="center-div">
            <div className="navbar-brand">
              <img src={require("../../images/bolpart-logo-05.png")} alt="logo" />
            </div>
            <div className="mg-20">
              <img
                src={require("../../gif/money.gif")}
                className="loading border-radius"
                alt="loading"
              />
            </div>
            <h3 className="big-font-title">
              {loading ? (
                <span>Bid Payment verifying...</span>
              ) : (
                <span>Redirecting to page...</span>
              )}
            </h3>
          </div>
        </div>
      </div>
    </>
  )
}
