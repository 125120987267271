import { FieldProps } from "formik"
import Select, { ActionMeta } from "react-select"
export interface Option {
  label: string
  value: string | number
}

interface CustomSelectProps extends FieldProps {
  options: any
  isMulti?: boolean
  className?: string
  placeholder?: string
  isSearchable?: boolean
  onChange?: ((newValue: unknown, actionMeta: ActionMeta<unknown>) => void) | undefined,
  value?:any

}

export const CustomSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  isSearchable,
  onChange:priorChange,
  value:priorValue
}: CustomSelectProps) => {
  const onChange = (option: any) => {
    form.setFieldValue(
      field.name,
      isMulti
        ? (option as Option[]).map((item: Option) => item.value)
        : (option as Option).value
    )
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option: Option) => field.value.indexOf(option.value) >= 0
          )
        : options.find((option: Option) => option.value === field.value) || null
    } else {
      return isMulti ? [] : ("" as any)
    }
  }

  return (
    <Select
      className={className}
      name={field.name}
      value={priorValue??getValue()}
      onChange={priorChange ?? onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      isSearchable={isSearchable}
    />
  )
}

export default CustomSelect
