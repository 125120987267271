import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSpecificTender } from "../Api/GetApi";
import { PublishENoticeData } from "../FormikWrapper/ENoticeFormikWrapper";
import { MyBidLoader } from "../MyBidLoader/MyBidLoader";
import { NotFound } from "../NotFound/NotFound";
import PublishENotice from "../PublishENotice/PublishENotice";
import { catchError } from "../utils/catchError";
import { ITenderAttributes } from "../utils/interface";

export const EditENotice = () => {
  const [noticeData, setNoticeData] = useState<PublishENoticeData>();
  const [loading, setLoading] = useState(true);
  const [deposit, setDeposit] = useState<boolean>();
  const param = useParams();

  const fetchSpecificNotice = () => {
    setLoading(true);
    getSpecificTender(param.id, false)
      .then((res) => {
        const reqData = res.data.data;
        setDeposit(reqData.deposit_paid);
        setNoticeData((p) => ({
          title: reqData?.title,
          notice_category: {
            label: reqData.notice_category,
            value: reqData.notice_category_id,
          },
          project_category: reqData.project_category.map(
            (category: ITenderAttributes) => category.value
          ),
          work_locations: reqData?.work_locations,
          publishing_date: reqData?.publishing_date,
          submission_date: reqData.submission_date.slice(0, 10),
          notes: reqData?.notes,
          notice_type: "E-Notice",
          saved: reqData?.saved,
        }));
      })
      .catch((err) => {
        catchError(
          err?.response?.status,
          err?.response?.data?.status?.status_message
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    window.localStorage.removeItem("pathname-publish");
    fetchSpecificNotice();
  }, []);

  return (
    <>
      <div className="W-100">
        {loading ? (
          <div className="spinner">
            <MyBidLoader />
          </div>
        ) : (
          <>
            {noticeData ? (
              <PublishENotice deposit={deposit} initialValuesProps={noticeData} />
            ) : (
              <NotFound message="Something Went Wrong" />
            )}
          </>
        )}
      </div>
    </>
  );
};
