import "./ResultShimmer.scss";

export const ResultShimmer = () => {
  return (
    <div className="result-shimmer-page">
      <div className="card-section">
        <div className="shimmer-overlay"></div>
        <div className="card-top">
          <div className="text-area">
            <p className="shimmer-text"></p>
            <p className="shimmer-text small-text"></p>
            <p className="shimmer-text text-40"></p>
          </div>
        </div>
        {/* <div className="shimmer-box"></div>
         */}
        {/* <div className="icon-section">
          <p className="shimmer-text text-25"></p>
          <div className="float-right">
            <div className="icon-shimmer"></div>
            <div className="icon-shimmer"></div>
            <div className="icon-shimmer"></div>
          </div>
        </div> */}
      </div>
      <div className="card-section ">
        <div className="shimmer-overlay"></div>
        <div className="card-top">
          <div className="text-area">
            <p className="shimmer-text"></p>
            <p className="shimmer-text small-text"></p>
            <p className="shimmer-text text-40"></p>
          </div>
        </div>
        {/* <div className="shimmer-box"></div>
         */}
        {/* <div className="icon-section">
          <p className="shimmer-text text-25"></p>
          <div className="float-right">
            <div className="icon-shimmer"></div>
            <div className="icon-shimmer"></div>
            <div className="icon-shimmer"></div>
          </div>
        </div> */}
      </div>


      <div className="card-section ">
        <div className="shimmer-overlay"></div>
        <div className="card-top">
          <div className="text-area">
            <p className="shimmer-text"></p>
            <p className="shimmer-text small-text"></p>
            <p className="shimmer-text text-40"></p>
          </div>
        </div>
        {/* <div className="shimmer-box"></div>
         */}
        {/* <div className="icon-section">
          <p className="shimmer-text text-25"></p>
          <div className="float-right">
            <div className="icon-shimmer"></div>
            <div className="icon-shimmer"></div>
            <div className="icon-shimmer"></div>
          </div>
        </div> */}
      </div>


      <div className="card-section ">
        <div className="shimmer-overlay"></div>
        <div className="card-top">
          <div className="text-area">
            <p className="shimmer-text"></p>
            <p className="shimmer-text small-text"></p>
            <p className="shimmer-text text-40"></p>
          </div>
        </div>
        {/* <div className="shimmer-box"></div>
         */}
        {/* <div className="icon-section">
          <p className="shimmer-text text-25"></p>
          <div className="float-right">
            <div className="icon-shimmer"></div>
            <div className="icon-shimmer"></div>
            <div className="icon-shimmer"></div>
          </div>
        </div> */}
      </div>
      
    </div>
  );
};
