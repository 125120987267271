import React, { useState } from "react";
import "./ConfirmationPopUp.scss";
import { mdiInformationOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { CustomLoader } from "../Loader/Loader";

export interface Props {
  toggleModal?: () => void;
  onAprroveButton?: any;
  tender_id?: string | number | undefined;
  bidder_id?: string | number | undefined;
  popUpContents: ConfirmMessage;
  activeButtonLoading?: boolean;
  acceptTerm?: boolean;
  publisherTerm?: boolean;
  awardNote?: string;
  setAwardNote?: React.Dispatch<React.SetStateAction<string>>;
  wantToWriteNote?: boolean;
}
export interface ConfirmMessage {
  question1?: string;
  question2?: string;
  warn?: string;
  cancelBtn?: string;
  aprroveBtn?: string;
  content?: string;
}

export const ConfirmationPopUp: React.FC<Props> = ({
  tender_id,
  toggleModal,
  onAprroveButton,
  popUpContents,
  activeButtonLoading = false,
  acceptTerm,
  publisherTerm = false,
  awardNote,
  setAwardNote,
  wantToWriteNote = false,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const awardButtonDisableCases = () => {
    if (wantToWriteNote && !Boolean(awardNote)) {
      return true;
    }
    return activeButtonLoading || (acceptTerm && !isChecked);
  };

  let termNote = publisherTerm
    ? "I accept that I have read and accept the <a href='/publisher-policy' target='_blank'><b>Publisher Policy</b></a>"
    : "I accept that I have read and accept the <a href='/bidder-term' target='_blank'><b>Terms & Conditions</b></a> and <a href='/bidder-policy' target='_blank'><b>Bidder Policy</b></a>";

  return (
    <div className="confirmation-popup">
      <div className="close-icon" onClick={toggleModal}>
        <Icon className=" mdi-close" path={mdiClose} />
      </div>
      <div className="info-logo">
        <Icon className="mdi-alert" path={mdiInformationOutline}></Icon>
      </div>
      <div>
        <h5 className="text-title">{popUpContents.question1}</h5>
        <p className="sub-header">{popUpContents.question2}</p>
      </div>
      <div className="popup-body">
        {(!publisherTerm || popUpContents.warn) && (
          <div className="alert-section">
            <div>
              <Icon className="mdi-alert" path={mdiInformationOutline}></Icon>
            </div>
            <div>
              <p className="withdraw-bid">{popUpContents.warn}</p>
            </div>
          </div>
        )}
        {acceptTerm && (
          <div className="check-section">
            <input
              onChange={() => handleCheckboxChange()}
              id="radio1"
              type="checkbox"
              className="checkbox mg-right-8"
            />
            <label
              className="checkbox-label"
              htmlFor="radio1"
              style={{ marginBottom: "0px" }}
            >
              {<div dangerouslySetInnerHTML={{ __html: termNote }} />}
            </label>
          </div>
        )}

        {wantToWriteNote && (
          <div>
            <h1 className="form-label">Award Reason:</h1>
            <textarea
              className="form-control"
              value={awardNote}
              onChange={(e) => setAwardNote?.(e.target.value)}
            ></textarea>
          </div>
        )}
      </div>

      <div className="popup-btn">
        <div>
          <button className="btn-cancel" onClick={toggleModal}>
            {popUpContents.cancelBtn}
          </button>
        </div>
        <div>
          <button
            className="btn-withdraw"
            disabled={awardButtonDisableCases()}
            onClick={() => {
              onAprroveButton(tender_id);
            }}
          >
            {activeButtonLoading ? (
              <CustomLoader
                type="TailSpin"
                width={42}
                height={20}
                color="#FFFFFF"
              />
            ) : (
              <>{popUpContents.aprroveBtn}</>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
