import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { FileSize } from "../../utils/EnumConstants";
import { useAuth } from "../Context/AuthContext";
import { AdditionalAttributes, TenderBOQ } from "./TenderFormikWrapper";

export interface EnlistmentData {
  submission_date: string;
  saved?: boolean;
  show_boq_item_rate: boolean;
  boq_file?: string;
  boq_details?: {
    boq_total: number;
    vat: number;
    grand_total: number;
  };
  additional_attributes: AdditionalAttributes[];
  boq: TenderBOQ[];
  notes?: string;
  terms?: string;
  has_boq_financial_documents?: boolean;
  boq_financial_documents?: string;
  is_vat: boolean;
}

const initialValues: EnlistmentData = {
  submission_date: "",
  saved: false,
  show_boq_item_rate: false,
  boq_file: "",
  additional_attributes: [],
  boq: [
    {
      category: "",
      item: [
        {
          item_description: "",
          unit: "",
          quantity: "",
          rate: 0,
          fixed: false,
          blank: true,
          additional_attributes: [],
        },
      ],
      additional_columns: [],
    },
  ],
  boq_details: {
    boq_total: 0,
    vat: 0,
    grand_total: 0,
  },
  notes: "",
  terms: "",
  has_boq_financial_documents: false,
  boq_financial_documents: "",
  is_vat: true,
};

interface EnlistmentFormikWrapperProps {
  children: any;
  publishEnlistment: (
    values: EnlistmentData,
    resetForm: Function,
    isUserVerified: boolean
  ) => void;
}

const currentDate = new Date().toISOString().split("T")[0];

const validationSchema = Yup.object({
  boq_file: Yup.mixed()
    .test(
      "boq-file-quotation",
      FileSize.MAX_FILE_SIZE_WORD,
      (value) => !value || (value && value.size <= FileSize.MAX_FILE_SIZE)
    )
    .test("fileFormat", "CSV only", (value) => {
      return (
        !value || ["text/csv", "application/vnd.ms-excel"].includes(value.type)
      );
    }),

  boq_financial_documents: Yup.string()
    .when("has_boq_financial_documents", {
      is: (val: boolean) => val === true,
      then: Yup.string().required("Required"),
    })
    .matches(
      /^(?![\s\S]*[^\w, -]+)[\s\S]*?$/,
      "Invalid Financial Documents Name"
    )
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces"),

  boq: Yup.array().when(["boq_file", "has_boq_financial_documents"], {
    is: (boqFile: string, hasBOQFinancialDoc: boolean) => {
      if (hasBOQFinancialDoc) {
        return false;
      }
      if (!boqFile) {
        return true;
      }
    },
    then: Yup.array().of(
      Yup.object({
        category: Yup.string().required("Required"),
        item: Yup.array().of(
          Yup.object({
            item_description: Yup.string().required("Required"),
            unit: Yup.string().required("Required"),
            quantity: Yup.string()
              .required("Required")
              .test("is-numeric", "Invalid Quantity!", (value: any) =>
                /^[0-9]+(\.[0-9]{1,4})?$/.test(value)
              ),
          })
        ),
      })
    ),
    otherwise: Yup.array().notRequired(),
  }),

  submission_date: Yup.date()
    .min(currentDate, "Submission date cannot be before current date")
    .test(
      "is-submission-date-valid",
      "Submission date must be at least 5 days after current date",
      function (value) {
        const minSubmissionDate = new Date(currentDate as string);
        minSubmissionDate.setDate(minSubmissionDate.getDate() + 5);
        return (value as Date) >= minSubmissionDate;
      }
    )
    .required("Submission date is required"),
});

export const useEnlistmentFormik = () => {
  const formik = useFormikContext<EnlistmentData>();
  if (!formik) {
    throw new Error(
      "useEnlistmentFormik must be used within a EnlistmentFormikWrapper"
    );
  }
  return formik;
};

const EnlistmentFormikWrapper: React.FC<EnlistmentFormikWrapperProps> = ({
  children,
  publishEnlistment,
}) => {
  const { id } = useParams();
  const { userData } = useAuth();

  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        initialTouched={{
          submission_date: true,
          boq_financial_documents: true,
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const modifyValues = {
            ...values,
            tender_notice_id: id,
          };
          publishEnlistment(modifyValues, resetForm, userData.is_verified);
        }}
        validationSchema={validationSchema}
      >
        <Form>{children}</Form>
      </Formik>
    </>
  );
};

export default EnlistmentFormikWrapper;
