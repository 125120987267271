import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import {
  findOrganizationLabelByValue,
  shouldShowEditCompanyPopup,
} from "../../utils/services";
import { useAuth } from "../Context/AuthContext";
import EditCompanyDetails from "../EditDetails/EditCompanyDetails";
import "./NewPublisherBanner.scss";
import bolpatraLogo from "../../images/bolpatra logo-full.png";
import { PublisherBannerShimmer } from "../PublisherBannerShimmer/PublisherBannerShimmer";
import { BannerType } from "../../utils/EnumConstants";
import { UserInfo } from "../MyBidsPage/MyBidsPage";

export interface NewPublisherBannerProps {
  profileData: UserInfo;
  loading?: boolean;
}

const ImageWithFallback: React.FC<{
  src: string;
  fallbackSrc: string;
  alt: string;
}> = ({ src, fallbackSrc, alt }) => {
  const [imageSrc, setImageSrc] = useState(src);

  const handleError = () => {
    setImageSrc(fallbackSrc);
  };

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  return <img src={imageSrc} alt={alt} onError={handleError} />;
};

const NewPublisherBanner: React.FC<NewPublisherBannerProps> = ({
  profileData,
  loading,
}) => {
  const { userData, eligible, isAuthenticate } = useAuth();
  const [orgDetailsOpen, setOrgDetailsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { showCompanyPopup } = shouldShowEditCompanyPopup(
    isAuthenticate,
    eligible,
    userData?.email
  );
  function toggleOrgDetailsModal() {
    setOrgDetailsOpen(!orgDetailsOpen);
  }

  const customStylesOrg = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "55%",
      padding: "20px",
      maxHeight: "650px",
    },
  };

  useEffect(() => {
    if (showCompanyPopup) {
      toggleOrgDetailsModal();
    }
  }, [showCompanyPopup]);

  const handleLaterClick = () => {
    const companyPopupData = localStorage.getItem("loginDataArray");

    if (companyPopupData) {
      let parsedData = JSON.parse(companyPopupData);

      parsedData = parsedData.filter(
        (data: { firstLoginEmail: string }) =>
          data.firstLoginEmail !== userData?.email
      );

      localStorage.setItem("loginDataArray", JSON.stringify(parsedData));
    }
    toggleOrgDetailsModal?.();
  };

  return (
    <>
      {showCompanyPopup || !eligible ? (
        <Modal
          isOpen={orgDetailsOpen}
          onRequestClose={() => {
            if (!eligible) {
              handleLaterClick();
            } else {
              toggleOrgDetailsModal();
            }
          }}
          contentLabel="My dialog"
          style={customStylesOrg}
          ariaHideApp={false}
        >
          <EditCompanyDetails
            toggleModal={() => toggleOrgDetailsModal()}
            handleLaterClick={handleLaterClick}
          />
        </Modal>
      ) : (
        <></>
      )}
      <div className="new-publisher-banner">
        {loading ? (
          <PublisherBannerShimmer bannerType={BannerType.PUBLISHER} />
        ) : (
          <div className="details-section">
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <div className="company-details-section">
                    <div className="logo-section">
                      <ImageWithFallback
                        src={profileData?.additional_info?.logo}
                        fallbackSrc={bolpatraLogo}
                        alt="Company Logo"
                      />
                    </div>
                    <div className="company-details">
                      <div className="d-flex ">
                        <h4 className="block-title text-nowrap">
                          {profileData?.company_name
                            ? profileData?.company_name
                            : "N/A"}
                        </h4>
                        {profileData?.is_verified && (
                          <img
                            src={
                              require("../../images/bolpatra verified icon-01.svg")
                                .default
                            }
                            alt="verified"
                            className="banner-img"
                          />
                        )}
                      </div>
                      <p className="company-detail-content">
                        {findOrganizationLabelByValue(
                          profileData?.organization_category ?? ""
                        )}{" "}
                        |{" "}
                        {profileData?.location ? profileData?.location : "N/A"}
                      </p>
                      <p className="company-detail-content">
                        Contact:{" "}
                        {profileData?.full_name
                          ? profileData?.full_name
                          : "N/A"}{" "}
                        |{" "}
                        {profileData?.contact_no
                          ? profileData?.contact_no
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
                {profileData?.id === userData?.id && (
                  <div className="col-md-7">
                    <div className=" dropdown btn-dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Publish Notice
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div
                          className="menu-title"
                          onClick={() => {
                            if (eligible) {
                              navigate("/publish-tender");
                            } else {
                              toggleOrgDetailsModal();
                            }
                          }}
                        >
                          E-Bidding
                          <p>(Tender | Quotation | EOI | RFP) </p>
                        </div>

                        <div
                          className="menu-title"
                          onClick={() => {
                            if (eligible) {
                              navigate("/publish-tender?type=enlistment");
                            } else {
                              toggleOrgDetailsModal();
                            }
                          }}
                        >
                          Enlistment<p> (Standing List | Vendor Listing) </p>
                        </div>

                        <div
                          className="menu-title"
                          onClick={() => {
                            if (eligible) {
                              navigate("/publish-e-notice");
                            } else {
                              toggleOrgDetailsModal();
                            }
                          }}
                        >
                          E-Notice<p> (All Procurement Notice) </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NewPublisherBanner;
