import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  getBidOpenDateTime,
  getDateTime,
  isNoticeCategoryStandingList,
} from "../../utils/services";
import {
  OrganizationDetail,
  TenderNoticeDetail,
} from "../BidderDetail/BidderDetail";
import { BOqModel } from "../BoqModel/BoqModel";
import { useAuth } from "../Context/AuthContext";
import { DocumentResponse } from "../EditBids/EditBids";
import { PdfDoc } from "../PdfDocument/PdfDoc";
import "./BidDocument.scss";
import { ResponseBoq } from "../utils/interface";
import { BidCategory } from "../../utils/EnumConstants";
import React, { useState } from "react";
import { DocumentTile } from "../PdfDocument/DocumentTile";

export interface BidDocumentBoqProps {
  boq: ResponseBoq | undefined;
  documentData: DocumentResponse;
  organizationDetail: OrganizationDetail;
  tenderNoticeData?: TenderNoticeDetail;
}

const BidDocumentBoq: React.FC<BidDocumentBoqProps> = ({
  boq,
  documentData,
  organizationDetail,
  tenderNoticeData,
}) => {

  const { role } = useAuth();
  const isStandardListing = isNoticeCategoryStandingList(
    tenderNoticeData?.notice_category ?? ""
  );

  const hideBOQ = () => {
    return (
      (isStandardListing && tenderNoticeData?.is_main) ||
      (tenderNoticeData?.category === BidCategory.DOUBLE_ENVELOPE &&
        (tenderNoticeData.opening_date === null ||
          getBidOpenDateTime(tenderNoticeData.opening_date).getTime() >
          new Date().getTime()))
    );
  };

  return (
    <div className="tab-content card">
      <div className=" container bid-document-boq">
        <div className="Stepper tab-box pdf-content-section">
          <div className="card-section">
            <div className="form-section">
              <Tabs>
                <TabList>
                  <Tab>Document</Tab>
                  {!(isStandardListing && tenderNoticeData?.is_main) && (
                    <Tab>Financial</Tab>
                  )}
                </TabList>

                <TabPanel>
                  <PdfDoc
                    documentData={documentData}
                    catalogueFiles={
                      tenderNoticeData?.boq_catalog_name as string
                    }
                    neededDocuments={
                      tenderNoticeData?.document_needed?.split(",") ?? []
                    }
                    optionalDocuments={
                      tenderNoticeData?.optional_documents as string
                    }
                  />
                </TabPanel>
                <TabPanel>
                  {hideBOQ() ? (
                    <div className="expire-bid">
                      <div className="imageCenter">
                        <img
                          alt="bidder-lock"
                          className="expiry-img"
                          src={require("../../images/bidders-lock.png")}
                        />
                      </div>
                      <div>
                        <div>
                          <p>
                            You can view <b>BOQ</b> after
                            <span style={{ color: "red" }}>
                              (
                              {tenderNoticeData?.opening_date
                                ? getDateTime(tenderNoticeData.opening_date)
                                  .toLocaleString()
                                  .slice(0, 10) + " 2:00:00 PM"
                                : "Technical Evaluation"}
                              )
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="document-wrapper">
                      {boq && Boolean(boq.boq_details.length) &&
                        <BOqModel
                          organizationDetail={organizationDetail}
                          boq={boq}
                          show_boq_item_rate={true}
                          role={role}
                          notes={tenderNoticeData?.notes}
                          terms={tenderNoticeData?.terms}
                          tender_id={tenderNoticeData?.id}
                          title={tenderNoticeData?.title}
                          additional_attributes={
                            tenderNoticeData?.additional_attributes
                          }
                          hideNoteTerms={true}
                        />
                      }
                      {tenderNoticeData?.boq_financial_documents && (
                        <>
                          <fieldset className="field-border">
                            <React.Fragment>
                              <legend className="w-auto">
                                <div className="venture-topic ">BOQ Financial:</div>
                              </legend>
                            </React.Fragment>
                          </fieldset>
                          <fieldset className="body-field">
                            <div className="row">
                              {tenderNoticeData?.boq_financial_documents.split(",")?.map(
                                (file) =>
                                  file && (
                                    <DocumentTile
                                      isMultiDownload={false}
                                      title={file}
                                      link={
                                        documentData?.boq_financial_files?.[file]["file_url"]
                                      }

                                    />
                                  )
                              )}
                            </div>
                          </fieldset>
                        </>
                      )}
                    </div>
                  )}
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidDocumentBoq;
