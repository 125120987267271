import "./Footer.scss";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { ShareButton } from "../ShareButton/ShareButton";

const quickLinkConfig = [
  {
    title: "How to Bid",
    link: "https://www.youtube.com/watch?v=Mm-cYwsRjME",
  },
  {
    title: "How to Publish",
    link: "https://www.youtube.com/watch?v=Mm-cYwsRjME",
  },
  {
    title: "Publish Tender / Auction",
    link: require("../../Files/Publish_Tender.pdf"),
  },
  {
    title: "Subscribe Bolpatra",
    link: require("../../Files/Subscribe_for_Bolpatra.pdf"),
  },
  {
    title: "Advertise on Bolpatra",
    link: require("../../Files/Advertisement_Proposal.pdf"),
  },
];

export const Footer = () => {
  const { role } = useAuth();
  const { pathname } = useLocation();
  const shareUrl = window.location.origin;

  const handleItemClick = (e: any, item: any) => {
    e.preventDefault();
    const newWindow = window.open(item?.link, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  if (
    pathname.includes("/payment_verification") ||
    pathname.includes("/user")
  ) {
    return null;
  }
  return (
    <div className="footer-content">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="footer-logo-section">
              <img src={require("../../images/logo.svg").default} alt="logo" />
            </div>
            <div className="medium-content mb-0">
              Best Tender Notice app in Nepal. Get access to over 200+ daily
              tender related notices from National & Local Newspaper & PPMO.
            </div>

            <ShareButton size={32} shareUrl={shareUrl} />
            <div className="platforms">
              <a
                href="https://play.google.com/store/apps/details?id=com.bolpatranepal.axios&hl=en&gl=US"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  width={200}
                  className="app-logo"
                  src={require("../../images/playstoreimg.png")}
                  alt="img"
                />
              </a>

              <a
                href="https://apps.apple.com/np/app/bolpatra-nepal/id1517972097"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  width={200}
                  className="app-logo"
                  src={require("../../images/appstoreimg.png")}
                  alt="img"
                />
              </a>
            </div>
          </div>

          <div className="col-md-2 col-sm-6">
            <div className="title-space">
              <Link to="/" className="title">
                Quick Links
              </Link>
            </div>
            {quickLinkConfig?.map((item, index) => (
              <a
                href="#"
                onClick={(e) => handleItemClick(e, item)}
                className="nav-link active"
                key={index}
              >
                <p className="medium-content unset-margin-top">{item.title}</p>
              </a>
            ))}
          </div>
          <div className="col-md-2 col-sm-6">
            <div className="title-space">
              <Link to="/" className="title">
                Company
              </Link>
            </div>
            <Link
              to="/about-us"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link active"
            >
              <p className="medium-content unset-margin-top">About Us</p>
            </Link>
            <Link
              to="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link active"
            >
              <p className="medium-content unset-margin-top">Privacy Policy</p>
            </Link>
            <Link
              to="/publisher-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
            >
              <p className="medium-content unset-margin-top">
                Publisher Policy
              </p>
            </Link>

            <Link
              to="/bidder-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
            >
              <p className="medium-content unset-margin-top">Bidder Policy</p>
            </Link>
            <Link
              to="/bidder-term"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
            >
              <p className="medium-content unset-margin-top">Bidder Term</p>
            </Link>
          </div>
          <div className="col-md-3 contact-content">
            <div className="title-space">
              <Link to="/" className="title">
                Contact
              </Link>
            </div>

            <div className="list-items">
              <p className="medium-content list-height">+977 9849447788</p>
              <p className="medium-content list-height">+977 9802347788</p>
            </div>
            <div className="location-block">
              <img
                className="map-logo"
                src={require("../../images/map.png")}
                alt="img"
              />
              <p className="medium-content list-height">
                Subidhanagar, Tinkune Kathmandu. Nepal
              </p>
            </div>
          </div>
        </div>
        <footer className="footer-area">
          © 2019-2024 All Rights Reserved.
        </footer>
      </div>
    </div>
  );
};
