import * as Yup from "yup";
import { FileSize, PaymentStatus } from "../../utils/EnumConstants";
import { DocumentResponse, IBoqCatalogueFiles } from "../EditBids/EditBids";

const ifDocumentExist = (
  filledDocuments: any,
  key: keyof typeof filledDocuments
) => {
  return filledDocuments[key] === "" || filledDocuments[key] === undefined;
};

//this service would dynamically generate the object schema (required feild) with lloking up the filled document states and catalogue file state
export const generateCatalogueFileValidation = (document: string) => {
  const schemaObject = {};
  document.split(",").forEach((key) => {
    //@ts-ignore
    schemaObject[key] = Yup.mixed()
      .test(
        key,
        FileSize.MAX_FILE_SIZE_WORD,
        (value: any) =>
          !value || (value && value.size <= FileSize.MAX_FILE_SIZE)
      )
      .test("isRequired", "Required", (value: any, context: any) => {
        const document_needed = context.parent.catalogueFiles;
        const filledDocuments = context.parent.filledDocuments;
        if (filledDocuments && !ifDocumentExist(filledDocuments, key)) {
          return true;
        }
        if (document_needed && document_needed.includes(key)) {
          if (!value) {
            return false;
          }
        }
        return true;
      })
      .test("fileFormat", "PDF only", (value: any) => {
        return !value || ["application/pdf"].includes(value.type);
      });
  });
  return Yup.object().shape(schemaObject);
};

const acceptedFileTypes = "csv, zip, pdf, eccel, sheet, msword";
const multiFormats = [
  "text/csv",
  "application/zip",
  "application/x-zip-compressed",
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/msword",
];

const pdfFormat = ["application/pdf"];

//this service would dynamically generate the object schema (not required) of documents and catalogue
export const generatePartialBidDocumentsValidation = (
  documents: string,
  acceptedFileType: "pdf" | "multi"
) => {
  const allowedFormats =
    acceptedFileType === "multi" ? multiFormats : pdfFormat;

  const errorMsz =
    acceptedFileType === "multi"
      ? `Upload one of these file types: ${acceptedFileTypes}`
      : "PDF only";

  const schemaObject = {};
  documents.split(",").forEach((key) => {
    //@ts-ignore
    schemaObject[key] = Yup.mixed()
      .test(
        key,
        FileSize.MAX_FILE_SIZE_WORD,
        (value) => !value || (value && value.size <= FileSize.MAX_FILE_SIZE)
      )
      .test("fileFormat", errorMsz, (value) => {
        return !Boolean(value) || allowedFormats.includes(value.type);
      });
  });
  // return Yup.object().shape(schemaObject);
  return schemaObject;
};

const boqFinancialFilesValidation = (
  documents: string,
  boqFinancialResDocs: IBoqCatalogueFiles | undefined
) => {
  const schemaObject = {};
  const docKeys = documents?.split(",");
  if (!boqFinancialResDocs) {
    docKeys.forEach((key) => {
      //@ts-ignore
      schemaObject[key] = Yup.mixed().test(
        "file",
        "Please upload file",
        (value) => {
          return Boolean(value);
        }
      );
    });
  } else {
    docKeys.forEach((key) => {
      if (boqFinancialResDocs[key]?.file_url === "") {
        //@ts-ignore
        schemaObject[key] = Yup.mixed().test(
          "file",
          "Please upload file",
          (value) => {
            return value && boqFinancialResDocs?.[key];
          }
        );
      }
    });
  }

  return schemaObject;
};

//this service would dynamically generate the object schema (required field) of documents
export const generateBidDocumentComparingToFilledDocument = (
  document: string
) => {
  const schemaObject = {};
  document.split(",").forEach((key) => {
    //@ts-ignore
    schemaObject[key] = Yup.mixed()
      .test(
        key,
        FileSize.MAX_FILE_SIZE_WORD,
        (value: any) =>
          !value || (value && value.size <= FileSize.MAX_FILE_SIZE)
      )
      .test("isRequired", "Required", (value: any, context: any) => {
        const document_needed = context.parent.document_needed;
        const filledDocuments = context.parent.filledDocuments;
        if (filledDocuments && !ifDocumentExist(filledDocuments, key)) {
          return true;
        }
        if (document_needed && document_needed.includes(key)) {
          if (!value) {
            return false;
          }
        }
        return true;
      })
      .test("fileFormat", "PDF only", (value: any) => {
        return !value || ["application/pdf"].includes(value.type);
      });
  });
  return Yup.object().shape(schemaObject);
};

//this is required validation when submit button will triggered not save button
export const completeJointVentureValidationSchema = Yup.object({
  joint_venture_name: Yup.string().when(["add_venture"], {
    is: true,
    then: Yup.string().min(4, "Name too small").required("Required"),
  }),
  venture_agreement: Yup.mixed().when(["add_venture"], {
    is: true,
    then: Yup.mixed()
      .test(
        "Fichier taille",
        FileSize.MAX_FILE_SIZE_WORD,
        (value) => !value || (value && value.size <= FileSize.MAX_FILE_SIZE)
      )
      .test("fileFormat", "Required! PDF only", (value) => {
        return !value || ["application/pdf"].includes(value.type);
      })
      .test("isRequired", "Required", (value: any, context: any) => {
        const filledDocuments = context.parent.filledDocuments;
        if (
          filledDocuments &&
          !ifDocumentExist(filledDocuments, "venture_agreement")
        ) {
          return true;
        }
        if (!value) {
          return false;
        }
        return true;
      }),
  }),

  power_of_attorney: Yup.mixed().when(["add_venture"], {
    is: true,
    then: Yup.mixed()
      .test(
        "Fichier taille",
        FileSize.MAX_FILE_SIZE_WORD,
        (value) => !value || (value && value.size <= FileSize.MAX_FILE_SIZE)
      )
      .test("fileFormat", "Required! PDF only", (value) => {
        return !value || ["application/pdf"].includes(value.type);
      })
      .test("isRequired", "Required", (value: any, context: any) => {
        const filledDocuments = context.parent.filledDocuments;
        if (
          filledDocuments &&
          !ifDocumentExist(filledDocuments, "power_of_attorney")
        ) {
          return true;
        }
        if (!value) {
          return false;
        }
        return true;
      }),
  }),

  partner_document: Yup.mixed().when(["add_venture"], {
    is: true,
    then: Yup.mixed()
      .test(
        "Fichier taille",
        FileSize.MAX_FILE_SIZE_WORD,
        (value) => !value || (value && value.size <= FileSize.MAX_FILE_SIZE)
      )
      .test("fileFormat", "Required! PDF only", (value) => {
        return !value || ["application/pdf"].includes(value.type);
      })
      .test("isRequired", "Required", (value: any, context: any) => {
        const filledDocuments = context.parent.filledDocuments;
        if (
          filledDocuments &&
          !ifDocumentExist(filledDocuments, "partner_document")
        ) {
          return true;
        }
        if (!value) {
          return false;
        }
        return true;
      }),
  }),
});

//Schema for validating when  notice category is EOI
export const EoiDocumentSubmitValidationSchema = (
  documentNeed: string,
  catalougeFileNeed: string
) => {
  let documentNeedSchema =
    generateBidDocumentComparingToFilledDocument(documentNeed);
  let catalogueSchema = generateCatalogueFileValidation(catalougeFileNeed);
  const validationSchema = Yup.object({
    jointVenture: completeJointVentureValidationSchema,
    documents: Yup.object().shape({
      ...documentNeedSchema.fields,
      ...catalogueSchema.fields,
    }),
  });
  return validationSchema;
};

export const StandingEnlistmentSubmitValidationSchema = (
  documentNeed: string,
  catalougeFileNeed: string,
  ePayment: string
) => {
  let documentNeedSchema =
    generateBidDocumentComparingToFilledDocument(documentNeed);
  let catalogueSchema = generateCatalogueFileValidation(catalougeFileNeed);
  const validationSchema = Yup.object({
    jointVenture: completeJointVentureValidationSchema,
    documents: Yup.object().shape({
      ...documentNeedSchema.fields,
      ...catalogueSchema.fields,
    }),
    bidFee: bidFeeCompleteSchema(ePayment),
  });
  return validationSchema;
};

//works for onchange validation of joint venture
export const jointVentureValidationSchema = Yup.object({
  jointVenture: Yup.object({
    joint_venture_name: Yup.string().when(["add_venture"], {
      is: true,
      then: Yup.string().min(4, "Name too small"),
    }),
    venture_agreement: Yup.mixed().when(["add_venture"], {
      is: true,
      then: Yup.mixed()
        .test(
          "Fichier taille",
          FileSize.MAX_FILE_SIZE_WORD,
          (value) => !value || (value && value.size <= FileSize.MAX_FILE_SIZE)
        )
        .test("fileFormat", "Required! PDF only", (value) => {
          if (value == null) {
            return true;
          }
          return value && ["application/pdf"].includes(value.type);
        }),
    }),
    power_of_attorney: Yup.mixed().when(["add_venture"], {
      is: true,
      then: Yup.mixed()
        .test(
          "Fichier taille",
          FileSize.MAX_FILE_SIZE_WORD,
          (value) => !value || (value && value.size <= FileSize.MAX_FILE_SIZE)
        )
        .test("fileFormat", "Required! PDF only", (value) => {
          if (value == null) {
            return true;
          }
          return value && ["application/pdf"].includes(value.type);
        }),
    }),

    partner_document: Yup.mixed().when(["add_venture"], {
      is: true,
      then: Yup.mixed()
        .test(
          "Fichier taille",
          FileSize.MAX_FILE_SIZE_WORD,
          (value) => !value || (value && value.size <= FileSize.MAX_FILE_SIZE)
        )
        .test("fileFormat", "Required! PDF only", (value) => {
          if (value == null) {
            return true;
          }
          return value && ["application/pdf"].includes(value.type);
        }),
    }),
  }),
});

//BOQ Validation for submit
export const completeBidBoqValidationSchema = (
  columns: string[],
  boqFinancialFiles: string,
  boqFinancialResDocs: IBoqCatalogueFiles
) =>
  Yup.object().shape({
    boqData: Yup.object({
      boq: Yup.array().of(
        Yup.object({
          item: Yup.array().of(
            Yup.object({
              rate: Yup.number().when("blank", {
                is: false,
                then: Yup.number()
                  .typeError("Invalid Rate!")
                  .positive("Invalid Rate!"),
              }),
              additional_attributes: Yup.object().shape(
                columns?.reduce((acc, column) => {
                  acc[column] = Yup.string().required(`${column} is required`);
                  return acc;
                }, {} as Record<string, Yup.StringSchema>)
              ),
            })
          ),
        })
      ),
    }),
    boqFinancialDoc: Yup.object().shape({
      ...boqFinancialFilesValidation(
        boqFinancialFiles ?? "",
        boqFinancialResDocs
      ),
    }),
  });

export const partialBidValidation = (
  columns: string[],
  boqFinancialFiles: string
) =>
  Yup.object().shape({
    boqData: Yup.object().shape({
      boq: Yup.array().of(
        Yup.object().shape({
          item: Yup.array().of(
            Yup.object().shape({
              rate: Yup.number()
                .typeError("Invalid Rate!")
                .test("is-valid", "Invalid Rate!", (value: any) => {
                  if (value && !/^[0-9]+(\.[0-9]{1,2})?$/.test(value)) {
                    return false;
                  }
                  return true;
                })
                .nullable(),
            })
          ),
        })
      ),
    }),
    boqFinancialDoc: Yup.object().shape({
      ...generatePartialBidDocumentsValidation(boqFinancialFiles, "multi"),
    }),
  });

export const boqPartialValidation = (
  columns: string[],
  boqFinancialFiles: string
) => {
  return Yup.object({
    boq_details: partialBidValidation(columns, boqFinancialFiles),
  });
};

const voucherTypeValidation = () => {
  const temp = Yup.mixed()
    .test(
      "Fichier taille",
      FileSize.MAX_FILE_SIZE_WORD,
      (value) => !value || (value && value.size <= FileSize.MAX_FILE_SIZE)
    )

    .test("fileFormat", "Required! PDF only", (value, context) => {
      return !Boolean(value) || ["application/pdf"].includes(value.type);
    });
  return temp;
};

export const bidFeePartialSchema = Yup.object({
  bidFee: Yup.object({
    voucher: Yup.mixed().when(["bid_bond_security_amount"], {
      is: (value: string) => Number(value) > 0,
      then: voucherTypeValidation(),
    }),
    bid_fee_voucher: voucherTypeValidation(),
  }),
});

export const bidFeeCompleteSchema = (ePayment: string) =>
  Yup.object({
    voucher: Yup.mixed().when(["bid_bond_security_amount"], {
      is: (value: string) => Number(value) > 0,
      then: Yup.mixed()
        .test(
          "voucher",
          FileSize.MAX_FILE_SIZE_WORD,
          (value: any) =>
            !value || (value && value.size <= FileSize.MAX_FILE_SIZE)
        )
        .test("isRequired", "Required", (value: any, context: any) => {
          const filledDocuments = context.parent.filledDocuments;
          if (filledDocuments && !ifDocumentExist(filledDocuments, "voucher")) {
            return true;
          }
          if (!value) {
            return false;
          }
          return true;
        })
        .test("fileFormat", "PDF only", (value: any) => {
          return !Boolean(value) || ["application/pdf"].includes(value.type);
        }),
    }),

    ePayment: Yup.mixed().test("fileFormat", "Required", (value, context) => {
      const isPublisherFee = context.parent.publisher_bid_fee;
      const bidFeeVoucherDate = context.parent.bid_fee_voucher_date;
      const filledDocuments = context.parent.filledDocuments;
      const selectedDocument = context.parent.bid_fee_voucher
      const docValue = (selectedDocument && selectedDocument.size <= FileSize.MAX_FILE_SIZE) || (filledDocuments && !ifDocumentExist(filledDocuments, "bid_fee_voucher"))
      if (
        isPublisherFee &&
        docValue &&
        bidFeeVoucherDate
      ) {
        return true;
      }
      if (ePayment.toLowerCase() === PaymentStatus.Submitted.toLowerCase()) {
        return true;
      } else 
      return false;
    })
  });

//generates  schema for document page
export const partialBidDocumentValidationSchema = (
  documentsAndCatalogue: string
) => {
  const schema = Yup.object({
    documents: Yup.object().shape(
      generatePartialBidDocumentsValidation(documentsAndCatalogue, "pdf")
    ),
  });
  return schema;
};

export const completeBidSchema = (
  documentNeed: string,
  catalogueFile: string,
  columns: string[],
  boqFinancialFiles: string,
  boqFinancialResDocs: IBoqCatalogueFiles,
  ePayment: string
) => {
  const schema = Yup.object({
    ...EoiDocumentSubmitValidationSchema(documentNeed, catalogueFile).fields,
    boq_details: completeBidBoqValidationSchema(
      columns,
      boqFinancialFiles,
      boqFinancialResDocs
    ),
    bidFee: bidFeeCompleteSchema(ePayment),
  });
  return schema;
};

//here documentsAndCatalogue would be concat form of documentneed and cataloguefiles
export const requiredBidValidation = (
  page: number,
  documentsAndCatalogue: string,
  columns: string[],
  boqFinancialFiles: string
) => {
  switch (page) {
    case 1:
      return jointVentureValidationSchema;
    case 2:
      return partialBidDocumentValidationSchema(documentsAndCatalogue);
    case 3:
      return bidFeePartialSchema;
    case 4:
      const validation = boqPartialValidation(columns, boqFinancialFiles);
      return validation;
    default:
      return jointVentureValidationSchema;
  }
};
