import { mdiClose, mdiInformationOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBiddersList } from "../Api/GetApi";
import { catchError } from "../utils/catchError";
import "./QuotationBiddersList.scss";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export interface BidderList {
  id: number;
  email: string;
  contact_no: string;
  company_name: string;
}
interface QuotationBiddersListProps {
  toggleModal?: () => void;
  tender_id?: string;
  onRequestButtonClick?: any;
}

export const QuotationBiddersList: React.FC<QuotationBiddersListProps> = ({
  toggleModal,
  tender_id,
  onRequestButtonClick,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [biddersList, setBiddersList] = useState<Array<BidderList>>([]);
  const [selectedBidders, setSelectedBidders] = useState<Array<number>>([]);

  const handleClick = (id: number) => {
    setSelectedBidders((prevSelectedValues) => {
      if (prevSelectedValues.includes(id)) {
        return prevSelectedValues.filter((item) => item !== id);
      } else {
        return [...prevSelectedValues, id];
      }
    });
  };

  const FetchBidderList = (tender_id: string) => {
    setLoading(true);
    getBiddersList(tender_id)
      .then((res) => {
        if (res.status === 200) {
          setBiddersList(res.data.data);
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (tender_id) {
      FetchBidderList(tender_id);
    }
  }, [tender_id]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      {loading ? (
        ""
      ) : (
        <div className="bidders-list-popup">
          <div className="popup-title-section">
            <div
              className="close-icon"
              onClick={() => {
                toggleModal?.();
              }}
            >
              <Icon className="mdi-close" path={mdiClose} />
            </div>
            <div className="info-title">
              <div className="info-logo">
                <Icon className="mdi-alert" path={mdiInformationOutline}></Icon>
              </div>
              <div>
                <h5 className="text-title">
                  Are you sure you want to ask for a quotation?
                </h5>
              </div>
            </div>
          </div>
          <hr/>
          <div className="popup-content-section">
            <p className="content">Select Bidders to request quotation</p>
            {biddersList?.map((item, index) => {
              const isSelected = selectedBidders?.includes(item.id);

              return (
                <div
                  className={`checkbox-list ${isSelected ? `selected` : ``}`}
                  key={index}
                  onClick={() => handleClick(item.id)}
                >
                  <input
                    name={item.company_name}
                    className="checkbox"
                    type="checkbox"
                    checked={isSelected}
                  />
                  <label htmlFor={item.company_name}>{item.company_name}</label>
                </div>
              );
            })}
          </div>

          <div className="popup-body">
            <div className="check-section">
              <input
                onChange={() => handleCheckboxChange()}
                id="radio1"
                type="checkbox"
                className="checkbox mg-right-8"
              />
              <label
                className="checkbox-label"
                htmlFor="radio1"
                style={{ marginBottom: "0px" }}
              >
                I accept that I have read and accept the{" "}
                <Link to="/publisher-policy" target="_blank">
                  <b>Publisher Policy</b>
                </Link>
              </label>
            </div>
          </div>
          <div className="popup-btn">
            <div>
              <button
                className="transparent-btn"
                onClick={() => {
                  toggleModal?.();
                }}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className="primary-btn"
                onClick={() => {
                  if (selectedBidders?.length === 0) {
                    toast.error("Please select atleast one bidder");
                    return;
                  }
                  if (!isChecked) {
                    toast.error("Please accept the publisher policy");
                    return;
                  }
                  onRequestButtonClick?.(selectedBidders);
                }}
              >
                Request Quotation
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
