import { useFormikContext } from "formik";
import React, { useState } from "react";
import Dropzone, { DropzoneOptions } from "react-dropzone";
import "./pdfDropzone.scss";
import { Icon } from "@mdi/react";
import { mdiUpload } from "@mdi/js";
import { getValueFromNestedObject } from "../../utils/services";
import { getFileImage } from "./fileIImage";

export interface UploadedFile {
  fileName: string;
  filePath: string;
  fileType: string;
  fileSize: string;
}
interface DropZoneBoxProps {
  name: string;
  label: string;
  placeholder: string;
  setFileList?: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
  samplefile?: string;
  handleChange?: (e:any) => void;
}

export const DropZoneBox: React.FC<DropZoneBoxProps> = ({
  placeholder,
  name,
  handleChange
}) => {
  const handleFileremove = () => {
    setFieldValue(name, null);
  };
  const { values,setFieldValue } = useFormikContext();
  
  const handleDrop: DropzoneOptions["onDrop"] = (acceptedFiles) => {
    setFieldValue(name, acceptedFiles[0]);
    handleChange?.(acceptedFiles[0]);
  };

  
  return (
    <div className="drop-zone-page ">
      <div className="poped-box">
        <Dropzone onDrop={handleDrop} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input type="file" {...getInputProps()} name={name} />
              <div>
                <div className="dzu-dropzone">
                  <div className="small-text">
                    {placeholder}
                    <Icon className="small-icon" path={mdiUpload}></Icon>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dropzone>

        {
          //@ts-ignore
          getValueFromNestedObject(values, name) && (
            <li className="pdf-content block-title">
              <div className="pdf-file">
                <div className="img-text-wrapper">
                  <img
                  //@ts-ignore
                    src={getFileImage(getValueFromNestedObject(values, name)?.type)}
                    alt="img"
                    className="img-width"
                  />
                  <div className="text-wrapper">
                    <div className="document-topic">
                      
                      {
                        //@ts-ignore
                        getValueFromNestedObject(values, name).name ||
                          //@ts-ignore
                          getValueFromNestedObject(values, name).substring(
                            //@ts-ignore
                            getValueFromNestedObject(values, name).lastIndexOf(
                              "/"
                            ) + 1
                          )
                      }
                    </div>

                    <div className="mb">Uploaded</div>
                  </div>
                  <div onClick={handleFileremove} className="cross">
                    x
                  </div>
                </div>
              </div>
            </li>
          )
        }
      </div>
    </div>
  );
};
