import "./Testimonials.scss"
import OwlCarousel from "react-owl-carousel"
import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"
import { useEffect, useState } from "react"
import { testimonailsData } from "../Api/GetApi"
import { MyBidLoader } from "../MyBidLoader/MyBidLoader"

export interface TestimonialsType {
  name?: string
  description?: string
  photo?: string
}

export const Testimonials = () => {
  const [testData, setTestData] = useState<TestimonialsType[]>()
  const [loading, setLoading] = useState(true)
  const getTestimonials = () => {
    testimonailsData()
      .then((res) => {
        setTestData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }
  useEffect(() => {
    getTestimonials()
  }, [])
  return (
    <div className="testimonials-wrapper">
      <div className="container">
        <div>
          <p className="header"> Testimonials</p>
        </div>
        {loading ? (
       <div className="spinner">
       <MyBidLoader />
     </div>
        ) : (
          <>
            {testData && testData.length > 0 ? (
              <OwlCarousel
                items={1}
                className="owl-theme"
                loop
                nav
                margin={7}
                dots
                autoplay={true}
              >
                {testData?.map((item, index) => {
                  return (
                    <div className="card" key={index}>
                      <div className="testimonials-content">
                        <div className="comment-section">
                          <img
                            className="quote-img"
                            src={require("../../images/comment.png")}
                            alt="img"
                          />
                        </div>
                        <div>
                          <p className="content-testi">{item.description}</p>
                          <p className="content-testi">{item.name}</p>
                          <div className="content-images">
                            <img
                              className="testimonails-img"
                              src={item.photo}
                              alt="img"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </OwlCarousel>
            ) : (
              <div style={{ color: "red", textAlign: "center" }}>
                No Data Found
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

