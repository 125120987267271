import "./ImagePopup.scss";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPrint, faSave } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import { isBidder } from "../../utils/services";
import { Role } from "../../utils/EnumConstants";

export interface ImagePopupProps {
  ppmoFileUrl?: string;
  toggleModal?: any;
  savebids?: any;
  image?: any;
  saveBids?: any;
  tender_id?: any;
  role?: string;
}
export const ImagePopup: React.FC<ImagePopupProps> = ({
  ppmoFileUrl,
  toggleModal,
  savebids,
  image,
  saveBids,
  tender_id,
  role=Role.Bidder,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const handleDownload = async () => {
    if (ppmoFileUrl){
      window.open(ppmoFileUrl, '_blank');
    }else{
      const file = image;
      const response = await fetch(file,{ cache: "no-store" });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `bolpatra-tender-notice-${tender_id}.png`;
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div className="boq-img-popup">
      <div ref={componentRef} className="popup-scroll-section">
        {ppmoFileUrl ? (
          <img
          className="notice-img"
          style={{ width: "100%" }}
          src={require("../../images/ppmfile.jpg")}
          alt="ppmo-img"
        />
        ) : (
          <img
            className="notice-img"
            style={{ width: "100%" }}
            src={image}
            alt="notice-img"
          />
        )}
      </div>
      <div className="btn-option">
        {isBidder(role) && !savebids ? (
          <button
            className="save-bid-btn"
            onClick={() => {
              saveBids(tender_id);
              toggleModal();
            }}
          >
            <FontAwesomeIcon className="icon-bg" icon={faSave} />
            Save Bid
          </button>
        ) : (
          ""
        )}

        <div
          onClick={() => {
            handleDownload();
          }}
        >
          <button className="delete-btn btn-margin">
            <FontAwesomeIcon className="icon-bg" icon={faDownload} />
            Download
          </button>
        </div>

        {image && (
          <ReactToPrint
            trigger={() => (
              <button className="delete-btn" onClick={() => window.print()}>
                <FontAwesomeIcon className="icon-bg" icon={faPrint} />
                Print
              </button>
            )}
            content={() => componentRef.current}
          />
        )}
      </div>
    </div>
  );
};
