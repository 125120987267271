function Bidder() {
  return (
    <div className="container company-content">
      <h2 style={{ textAlign: "center" }}>
        <strong>BIDDER POLICY</strong>
      </h2>
      <div className="row">
        <div className="mar">
          <p style={{ margin: "10px" }}>
            <span style={{ fontWeight: 400 }}>
              POLICY FOR BIDDERS ON BOLPATRA NEPAL
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>INTRODUCTION</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              1.1 Bolpatra Nepal (<strong>"Company" or "we"</strong>) operates a
              web-based one-stop procurement portal for various activities of
              Nepal procurement lifecycle (<strong>"Website"</strong>).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.2 This Policy for Bidders on Bolpatra Nepal (
              <strong>"Policy"</strong>) sets forth the terms and conditions
              governing your use of the Website to participate in procurement
              activities.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.3 By using our Website to participate in procurement activities,
              you agree to the terms of this Policy.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>ELIGIBILITY</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              2.1 To be eligible to participate in procurement activities on the
              Website, you must meet the following requirements:
            </span>
            <ol style={{ listStyle: " disc inside", textAlign: "start" }}>
              <li>
                {" "}
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  You must be a legal entity or an individual capable of
                  entering into binding contracts;
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  You must be in compliance with all applicable laws and
                  regulations;
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  You must provide accurate and complete information about
                  yourself and your business.
                </span>
              </li>
            </ol>
          </p>
        </div>
        <ol>
          <li>
            <strong>PROCUREMENT PROCESS</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              3.1 Procurement activities on the Website are conducted in
              accordance with the rules and procedures set forth in the relevant
              tender documents.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.2 You must comply with all the requirements and deadlines set
              forth in the tender documents.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.3 We reserve the right to reject any bid that does not comply
              with the tender documents or this Policy.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>CONFIDENTIALITY</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              4.1 You agree to maintain the confidentiality of all information
              and documents provided to you in connection with procurement
              activities on the Website.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.2 You agree not to disclose any confidential information to any
              third party, except as required by law or with the prior written
              consent of the Company.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>INTELLECTUAL PROPERTY</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              5.1 You acknowledge and agree that all intellectual property
              rights in the tender documents and any other materials provided to
              you in connection with procurement activities on the Website are
              owned by the Company or its licensors.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.2 You agree not to use, reproduce, or distribute any of the
              tender documents or materials without the prior written consent of
              the Company.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>LIABILITY</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              6.1 We are not responsible for any errors or omissions in the
              tender documents or any other materials provided to you in
              connection with procurement activities on the Website.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              6.2 We are not liable for any direct, indirect, incidental,
              special, or consequential damages arising out of or in connection
              with your participation in procurement activities on the Website.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>TERMINATION</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              7.1 We reserve the right to terminate your access to the Website
              and participation in procurement activities at any time and for
              any reason.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.2 Upon termination, you must immediately cease all use of the
              Website and any materials provided to you in connection with
              procurement activities.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>CHANGES TO THIS POLICY</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              8.1 We may update this Policy from time to time. The updated
              policy will be posted on the Website and the effective date will
              be indicated.
            </span>
          </p>
        </div>
        <ol>
          <li>
            <strong>CONTACT US</strong>
          </li>
        </ol>
        <div className="mar">
          <p>
            <span style={{ fontWeight: 400 }}>
              9.1 If you have any questions or concerns about this Policy or our
              procurement activities, please contact us at the Bidder / User
              Department.
            </span>
          </p>
        </div>
        <p style={{ textAlign: "center", margin: "20px" }}>
          <strong>Phone No- (+977) 9823090145 / 9849135266</strong>
        </p>
        <p style={{ textAlign: "center" }}>
          <span style={{ fontWeight: 400 }}>
            <strong>Email-</strong>{" "}
            <span>
              <strong>bidder@bolpatranepal.com</strong>
            </span>
          </span>
        </p>
      </div>
    </div>
  );
}

export default Bidder;
