import { useState } from "react";
import SavedBidBanner from "../../Components/NewBanner/SavedBidBanner";
import { ContractForm } from "./ContractForm/ContractForm";
import { SubContract } from "./SubContract/SubContract";
import { WorkPost } from "./WorkPost";

export const PettyPage = () => {
  const [loadApi, setLoadApi] = useState(false);
  window.scrollTo(0, 0);
  return (
    <div className="petty-page-section">
      <div className="cardwrapper-section">
        {/* <NewBanner /> */}
        <SavedBidBanner />

        <div className="card-wrapper-content"></div>
        <div className="container">
          <div className="">
            {/* <p className="text-headers result-text">Results</p> */}

            <WorkPost />

            <div className="row">
              <div className="col-md-8">
                <SubContract loadApi={loadApi} />
              </div>

              <div className="col-md-4">
                <ContractForm setLoadApi={setLoadApi} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
