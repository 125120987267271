import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSpecificTender } from "../Api/GetApi";
import {
  AdditionalAttributes,
  PublishTenderData,
} from "../FormikWrapper/TenderFormikWrapper";
import { MyBidLoader } from "../MyBidLoader/MyBidLoader";
import PublishTenderStepper from "../PublishTenderStepper/PublishTenderStepper";
import { NotFound } from "../NotFound/NotFound";
import { Category } from "@mui/icons-material";
import { ITenderAttributes } from "../utils/interface";
import { catchError } from "../utils/catchError";

export const EditTenderPage = () => {
  const [tenderData, setTenderData] = useState<PublishTenderData>();
  const [deposit, setDeposit] = useState<boolean>();
  const [loading, setLoading] = useState(true);
  const [resDocument, setResDocument] = useState();
  const [additionalColumnsFromApi, setAdditionalColumns] = useState<string[]>(
    []
  );
  const param = useParams();

  //this may be useful when array value is send in notice categories in future
  // const categoryValues = (value: any) => {
  //   const emptyArr: Array<number> = [];
  //   value.forEach((item: { value: number; label: string }) => {
  //     emptyArr.push(item.value);
  //   });
  //   return emptyArr;
  // };

  const specificTender = () => {
    setLoading(true);
    getSpecificTender(param.id, false)
      .then((res) => {
        const reqData = res.data.data;
        const bidBondSecurityAmount = reqData.bid_bond_security_amount;
        setDeposit(reqData.deposit_paid);
        setAdditionalColumns(
          reqData?.additional_attributes?.map(
            (each: AdditionalAttributes) => each.column
          )
        );
        setTenderData((p) => ({
          estimated_cost: reqData?.estimated_cost[0]?.value,
          category: reqData.category,
          is_vat: reqData.is_vat,
          bid_bond_security: Number(bidBondSecurityAmount) === 0 ? false : true,
          bid_bond_security_amount:
            Number(bidBondSecurityAmount) === 0 ? "" : bidBondSecurityAmount,
          excelfile: reqData?.excelfile,
          catalogue_extra: reqData.boq.boq_catalog_name ? true : false,
          tender_number: reqData.tender_number,
          district: reqData.district,
          same_as_previous: reqData.bank_name ? true : false,
          notice_category: {
            label: reqData.notice_category,
            value: reqData.notice_category_id,
          },
          procurement_type: reqData.procurement_type[0].value,
          project_category: reqData.project_category.map(
            (category: ITenderAttributes) => category.value
          ),
          bid_bond_validity: reqData.bid_bond_validity,
          publishing_date: reqData.publishing_date,
          submission_date: reqData.submission_date.slice(0, 10),
          bank_name: reqData.bank_name,
          branch: reqData.branch,
          account_number: reqData.account_number,
          account_name: reqData.account_name,
          show_boq_item_rate: reqData.show_boq_item_rate,
          additional_attributes: reqData?.additional_attributes,
          document_needed: {
            bid_letter: reqData.document_needed.includes("bid_letter"),
            declaration_letter:
              reqData.document_needed.includes("declaration_letter"),
            applicants_information: reqData.document_needed.includes(
              "applicants_information"
            ),
            experience: reqData.document_needed.includes("experience"),
            capacity: reqData.document_needed.includes("capacity"),
            qualification: reqData.document_needed.includes("qualification"),
            citizenship: reqData.document_needed.includes("citizenship"),
          },
          boq: reqData.boq.boq_details,
          boq_catalog_name: reqData.boq.boq_catalog_name,
          notes: reqData.notes,
          terms: reqData.terms,
          is_update: true,
          work_locations: reqData.work_locations,
          publisher_bid_fee:
            reqData.publisher_bid_fee != null
              ? Number(reqData.publisher_bid_fee)
              : null,
          has_bid_fee: reqData.publisher_bid_fee ? true : false,
          hide_bidder: reqData.hide_bidder,
          has_boq_financial_documents: reqData.boq_financial_documents
            ? true
            : false,
          boq_financial_documents: reqData.boq_financial_documents,
          is_main: reqData.is_main,
          has_optional_document: reqData.optional_documents ? true : false,
          optional_documents: reqData.optional_documents,
          award_type: reqData.award_type
        }));

        setResDocument(res.data.data);
      })
      .catch((err) => {
        catchError(
          err?.response?.status,
          err?.response?.data?.status?.status_message
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    window.localStorage.removeItem("pathname-publish");
    specificTender();
  }, []);

  return (
    <>
      <div className="W-100">
        {loading ? (
          <div className="spinner">
            <MyBidLoader />
          </div>
        ) : (
          <>
            {tenderData ? (
              <PublishTenderStepper
                initialValuesProps={tenderData}
                deposit={deposit}
                resDocument={resDocument}
                additionalColumnsProps={additionalColumnsFromApi}
              />
            ) : (
              <NotFound message="Something Went Wrong" />
            )}
          </>
        )}
      </div>
    </>
  );
};
