import React, { useRef } from "react";
import "./Notice.scss";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPrint, faSave } from "@fortawesome/free-solid-svg-icons";
import { INoticeDetail } from "../../../Components/utils/interface";
import { saveTenderBid } from "../../../Components/Api/PostApi";
import { toast } from "react-toastify";
import { catchError } from "../../../Components/utils/catchError";


export interface NoticeProps {
  noticeDetail: INoticeDetail | undefined;
}

export const Notice: React.FC<NoticeProps> = ({ noticeDetail }) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const handleDownload = async () => {
    if (!noticeDetail) {
      return;
    }
    if (noticeDetail?.private) {
      console.log("This is not private tender notice...")
    } else {
      if(noticeDetail.ppmo_file_url){
        window.open(noticeDetail.ppmo_file_url, '_blank');
        return
      }
      const file = noticeDetail.image;
      const response = await fetch(file, { cache: "no-store" });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `bolpatra-nepal-notice-${noticeDetail.id}.png`;
      document.body.appendChild(link);
      link.click();
    }
  };

  const saveBids = (tender_id: string | number | undefined) => {
    saveTenderBid(tender_id)
      .then((res) => {
        toast.success("Bid Saved Successfully");
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };
  
  return (
    <div className="notice-section">
      <div ref={componentRef} className="img-block">
        {noticeDetail?.image === null ? (
          <img src={require("../../../images/ppmfile.jpg")} alt="notice-img" />
        ) : (
          <img src={noticeDetail?.image} alt="notice-img" />
        )}
      </div>
      <div className="btn-option">
        <button className="save-bid-btn" onClick={()=>saveBids(noticeDetail?.id)}>
          <FontAwesomeIcon className="icon-bg" icon={faSave} />
          Save Bid
        </button>

        <a onClick={handleDownload}>
          <button className="delete-btn btn-margin">
            <FontAwesomeIcon className="icon-bg" icon={faDownload} />
            Download
          </button>
        </a>

        {noticeDetail?.image !== null && (
          <ReactToPrint
            trigger={() => (
              <button className="delete-btn" onClick={() => window.print()}>
                <FontAwesomeIcon className="icon-bg" icon={faPrint} />
                Print
              </button>
            )}
            content={() => componentRef.current}
          />
        )}
      </div>
    </div>
  );
};
