import React, { useState } from "react";
import Lightbox from "react-spring-lightbox";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import "./ImageSlider.scss";
export interface ImageSliderProps {
  tenderCard?: any;
  selectedId?: number;
}

export const ImageSlider: React.FC<ImageSliderProps> = ({
  tenderCard,
  selectedId,
}) => {

  const combinedData = tenderCard.reduce(
    (accumulator: any, currentObject: any) => {
      const shouldInclude = currentObject.data.filter((item: any) => {
        return item.image !== null;
      });
      if (shouldInclude.length > 0) {
        return [...accumulator, ...shouldInclude];
      } else {
        return accumulator;
      }
    },
    []
  );

  const images = combinedData.map((item: any) => ({
    src: item.image,
    alt: "image",
  }));

  const findACtiveIndex = combinedData?.findIndex(
    (f: any) => f.id === selectedId
  );
  
  const [currentImageIndex, setCurrentIndex] = useState(findACtiveIndex);

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () => {
    currentImageIndex + 1 < images.length &&
      setCurrentIndex(currentImageIndex + 1);
  };
  return (
    <div className="lightbox-section">
      <Lightbox
        isOpen={true}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={images}
        currentIndex={currentImageIndex}
        renderNextButton={() => (
          <button
            className="btn-handle"
            onClick={gotoNext}
            style={{ position: "absolute", right: "10px" }}
          >
            <Icon path={mdiChevronRight} size={1} />
          </button>
        )}
        renderPrevButton={() => (
          <button
            className="btn-handle"
            onClick={gotoPrevious}
            style={{ position: "absolute", left: "10px", zIndex: "999999" }}
          >
            <Icon path={mdiChevronLeft} size={1} />
          </button>
        )}
        style={{ background: "grey", zIndex: "999" }}
        singleClickToZoom
      />
    </div>
  );
};
