import { ResetPasswordData } from "../../Pages/PasswordReset/PasswordResetPage";
import Axios from "../Api/AxiosInstance";
import { EditPersonal } from "../EditDetails/EditPersonalDetails";
import { PublishTenderData } from "../FormikWrapper/TenderFormikWrapper";
import { TenderRejectInitial } from "../RejectPopup/NewRejectPopup";

export const UpdatePersonalDetails = (data: EditPersonal) => {
  return Axios.put("/user/personal_detail", data);
};
export const UpdateCompanyDetails = (data: any) => {
  return Axios.put("/user/organization_detail", data);
};
export const ResetPassword = (data: ResetPasswordData, token: any) => {
  return Axios.put(`/user/password_reset/${token}/`, data);
};

export const UpdateTenderNotice = (
  data: PublishTenderData,
  id: string | number | undefined
) => {
  return Axios.put(`/tender_notice/${id}`, data);
};
export const UpdateMyBids = (
  id: string | number,
  requestType: string | number
) => {
  var data = {
    request_type: requestType,
  };
  return Axios.put(`/my_bid/withdrawn/${id}`, data);
};

export const RejectTender = (data: TenderRejectInitial) => {
  return Axios.put("/tender_bid_rejection", data);
};


export const EditProcurement = (data: any) => {
  return Axios.put("/user_interest", data);
};


export const extendSubmitDateOrOpenBid =(id:string,data:any)=>{
    return Axios.patch(`/tender_notice/${id}`,data)
}
