import React from "react";
import "./WorkPost.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailBulk,faHardHat,faHandshake } from "@fortawesome/free-solid-svg-icons";

export const WorkPost = () => {
  window.scrollTo(0, 0);
  return (
    <div className="workpost-section">
      <div className="">
        <div className="workpost-header d-space-between">
          <p className="text-headers heading-title ">
            Get the best Sub-Contractor for your work
          </p>
          {/* <button className="primary-btn">Post Work</button> */}
        </div>

        <div className="workpost-content">
          <div className="card card-section">
            <div className="row">
              <div className="col-md-4">
                <div className="work-mid-section">
                  <p className="medium-content ">01.</p>
                  <div className="workpost-details">
                    <FontAwesomeIcon className="icon-bg" icon={faMailBulk} />

                    <p className="medium-content workpost-title">
                      {" "}
                      Post your Work
                    </p>
                    {/* <p className="sub-title description">
                      Amet minim mollit non deserunt ullamco est sit.
                    </p> */}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="work-mid-section">
                  <p className="medium-content ">02.</p>
                  <div className="workpost-details">
                    <FontAwesomeIcon className="icon-bg" icon={faHardHat} />
                    <p className="medium-content workpost-title">
                      {" "}
                      Get best Sub-contractor
                    </p>
                    {/* <p className="sub-title description">
                      Amet minim mollit non deserunt ullamco est sit.
                    </p> */}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="work-mid-section">
                  <p className="medium-content ">03.</p>
                  <div className="workpost-details">
                    <FontAwesomeIcon className="icon-bg" icon={faHandshake} />
                    <p className="medium-content workpost-title">
                      {" "}
                      Seal the Deal
                    </p>
                    {/* <p className="sub-title description">
                      Amet minim mollit non deserunt ullamco est sit.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
