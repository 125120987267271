import "./ContactUS.scss";

export const ContactUS = () => {
  return (
    <div className="contactus-section">
      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="alert alert-warning">
              <h4 className="alert-heading">Dear Valued User</h4>
              <ul>
                <li>Forget User id or password?</li>
                <li>Want to know about Pricing plans?</li>
                <li>Have any complaints or suggesstions?</li>
                <li>Have any queries?</li>
              </ul>
            </div>

            <div className="contact-content-area">
              <div className="text-center d-flex justify-content-center flex-wrap">
                <div className="text-center d-flex justify-content-center flex-wrap">
                  <div className="card m-2">
                    <div className="card-body d-flex justify-content-center flex-column">
                      <h5 className="small contact-title">Contact Us at:</h5>
                      <i className="bi bi-phone"></i>
                      <div className="p-2">
                        <div>
                          <a href="tel: +977 9849447788">+977 9849447788</a>,{" "}
                          <a href="tel: +977 9849447788">9823090145</a>
                        </div>
                        <div>
                          <a href="tel: +977 9849447788">+977 9824700171</a>,{" "}
                          <a href="tel: +977 9849447788">9849135266</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card m-2">
                    <div className="card-body d-flex justify-content-center flex-column">
                      <h5 className="small contact-title">Email Us at:</h5>
                      <i className="bi bi-envelope"></i>
                      <div className="p-2">
                        <a href="mailto:someone@example.com">
                          <div>bolpatranepal@gmail.com</div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="card m-2 cursor-pointer">
                    <div className="card-body d-flex justify-content-center flex-column">
                      <h5 className="small contact-title">
                        Direct Chat with our support team:
                      </h5>
                      <i className="bi bi-chat-dots"></i>
                      <div className="p-2">
                        <div>(11am - 6pm)</div>
                        <i className="chat-note">You can start chat by clicking on bottom right icon</i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-between flex-wrap">
            <a href="https://www.facebook.com/bolpatranepal/">
              <div>
                <i className="bi bi-facebook"></i>
                Bolpatra Nepal
              </div>
            </a>

            <div>
              <i className="bi bi-geo-alt-fill"></i>
              Babarmahal, Kathmandu, Nepal
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
