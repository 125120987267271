import axios from "axios";

let url = "https://admin.bolpatranepal.com/api/v1";
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  url = "http://localhost:8000/api/v1";
}
export const domain = url;

axios.defaults.withCredentials = true;
const instance = axios.create({
  baseURL: domain,
});

instance.interceptors.request.use(
  (req: any) => {
    return req;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res: any) => {
    return res;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

export default instance;
