import React from "react";
import "./MobilePage.scss";
const MobilePageCheck = () => {
  return (
    <div className="mobile-view-section">
      <div className="row justify-content-center">
        <div className="col-sm-8 offset-sm-2">
          <img
            className="bolpatra-logo"
            src={require("../../images/bolpart-logo-05.png")}
            alt="img"
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 content-section">
          <h3 className="mobile-title">Please download the app</h3>
          <p className="mobile-desc">
            You are currently using a mobile device. To get the best experience,
            please download our app.
          </p>
        </div>
      </div>

      {/* <div className="row justify-content-center">
        <div className="col-sm-8 offset-sm-2">
          <h6 className="text-center mobile-title">For Android</h6>
          <a
            href="https://bolpatranepal.s3.ap-south-1.amazonaws.com/bolpatraapk.apk"
            download="bolpatraapk.apk"
          >
            <img
              className="app-logo"
              src={require("../../images/downloadimage.png")}
              alt="img"
            />
          </a>
        </div>
      </div> */}

      <div className="row justify-content-center">
        <div className="col-sm-8 offset-sm-2">
          <a href="https://play.google.com/store/apps/details?id=com.bolpatranepal.axios&hl=en&gl=US">
            <img
              className="app-logo"
              src={require("../../images/playstoreimg.png")}
              alt="img"
            />
          </a>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-sm-8 offset-sm-2">
          <h6 className="text-center mobile-title">For IOS</h6>
          <a href="https://apps.apple.com/np/app/bolpatra-nepal/id1517972097">
            <img
              className="app-logo"
              src={require("../../images/appstoreimg.png")}
              alt="img"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobilePageCheck;
