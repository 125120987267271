import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Undo,
  Autoformat,
  BlockQuote,
  Bold,
  CKFinder,
  CKFinderUploadAdapter,
  CloudServices,
  Essentials,
  Font,
  Heading,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  Base64UploadAdapter,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Mention,
  Paragraph,
  PasteFromOffice,
  PictureEditing,
  Table,
  TableToolbar,
  TextTransformation,
  Underline,
  TableCellProperties,
  TableProperties,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";

interface CustomCkEditorProps {
  dataValue: string;
  setFieldValue: (data: string) => void;
}

export const CustomCkEditor: React.FC<CustomCkEditorProps> = ({
  dataValue,
  setFieldValue,
}) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={dataValue}
      onChange={(event, editor) => {
        const data = editor.getData();
        setFieldValue(data);
      }}
      config={{
        toolbar: {
          items: [
            "undo",
            "redo",
            "|",
            "heading",
            "|",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "|",
            "link",
            "uploadImage",
            "ckbox",
            "insertTable",
            "blockQuote",
            "mediaEmbed",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
          ],
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
          ],
        },
        link: {
          addTargetToExternalLinks: true,
        },
        plugins: [
          Undo,
          Autoformat,
          BlockQuote,
          Bold,
          CKFinder,
          CKFinderUploadAdapter,
          CloudServices,
          Essentials,
          Font,
          Heading,
          Image,
          ImageCaption,
          ImageResize,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Base64UploadAdapter,
          Indent,
          IndentBlock,
          Italic,
          Link,
          List,
          MediaEmbed,
          Mention,
          Paragraph,
          PasteFromOffice,
          PictureEditing,
          Table,
          TableToolbar,
          TableProperties,
          TableCellProperties,
          TextTransformation,
          Underline,
        ],
      }}
    />
  );
};
