import { mdiPencil } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useState } from "react";
import Modal from "react-modal";
import { useAuth } from "../Context/AuthContext";
import "./Interest.scss";

import { UserInterestStepper } from "../UserInterestStepper/UserInterestStepper";

export interface ResponseInterest {
  name: string;
}
export interface ResponseBudgetSources {
  title: string;
}

export const Interest = () => {
  const { userData } = useAuth();
  const { procurement_type, project_category, estimated_cost, source } =
    userData;
  const [editOpen, setEditOpen] = useState(false);

  const toggleEditOpen = () => {
    setEditOpen(!editOpen);
  };

  const customClass = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "600px",
      width: "80%",
      zIndex: 50,
    },
  };
  return (
    <div className="interest">
      <Modal
        isOpen={editOpen}
        onRequestClose={toggleEditOpen}
        contentLabel="My dialog"
        style={customClass}
        ariaHideApp={false}
      >
        <UserInterestStepper
          showEdit={true}
          savedProject={project_category}
          savedEstimatedCost={estimated_cost}
          savedSources={source}
          setEditOpen={setEditOpen}
        />
      </Modal>
      <div className="interest-content">
        <div style={{ width: "100%" }}>
          {procurement_type?.length > 0 && (
            <div className="interest-block">
              <div className="content-block">
                <p className="content">Procurement Types</p>
              </div>
              <div className="content-block">
                <ul>
                  {procurement_type?.map((item: ResponseInterest) => {
                    return <li className="content">{item?.name}</li>;
                  })}
                </ul>
              </div>
            </div>
          )}
          {project_category?.length > 0 && (
            <div className="interest-block">
              <div className="content-block">
                <p className="content">Projects</p>
              </div>
              <div className="content-block">
                <ul>
                  {project_category?.map((item: ResponseInterest) => {
                    return <li className="content">{item?.name}</li>;
                  })}
                </ul>
              </div>
            </div>
          )}
          {estimated_cost?.length > 0 && (
            <div className="interest-block">
              <div className="content-block">
                <p className="content">Budgets</p>
              </div>
              <div className="content-block">
                <ul>
                  {estimated_cost?.map((item: ResponseInterest) => {
                    return <li className="content">{item?.name}</li>;
                  })}
                </ul>
              </div>
            </div>
          )}
          {source?.length > 0 && (
            <div className="interest-block">
              <div className="content-block">
                <p className="content">Sources</p>
              </div>
              <div className="content-block">
                <ul>
                  {source?.map((item: ResponseBudgetSources) => {
                    return <li className="content">{item?.title}</li>;
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>

        <button
          className="btn-name btn-edit btn-edit-icon"
          onClick={() => {
            setEditOpen(true);
          }}
        >
          <div className="d-flex">
            <div className="icon-size ">
              <Icon path={mdiPencil} />
            </div>
            Edit
          </div>
        </button>
      </div>
    </div>
  );
};
