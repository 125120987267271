import React from "react";
import "./ContractForm.scss";
import TextField from "@mui/material/TextField";
import { postPetty } from "../../../Components/Api/PostApi";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Form, ErrorMessage, Formik } from "formik";

const validationSchema = Yup.object().shape({
  work: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  rate: Yup.string().required("Required"),
  company: Yup.string().required("Required"),
  phone: Yup.string()
    .required("Required")
    .min(10, "Phone number must be minimum 10 ")
    .max(10, "Phone number must not exceed 10"),
});

export interface formProps {
  setLoadApi?: any;
}
export const ContractForm: React.FC<formProps> = ({ setLoadApi }) => {
  return (
    <div className="contract-form">
      <div className="card card-section">
        <p className="maintitle">Post your Sub-Contract work</p>
        <div>
          <Formik
            initialValues={{
              work: "",
              address: "",
              rate: "",
              company: "",
              phone: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(formData, { resetForm }) => {
              postPetty(formData)
                .then((res) => {
                  toast.success(res.data.status.status_message);
                  setLoadApi(true);
                  resetForm();
                })
                .catch((err) => {
                  alert("error");
                });
            }}
          >
            {({ getFieldProps }) => (
              <Form>
                <div className=" form-section">
                  <div className="input-field">
                    <TextField
                      className="form-control"
                      id="work-basic"
                      label="Work"
                      variant="outlined"
                      {...getFieldProps("work")}
                    />

                    <ErrorMessage
                      name="work"
                      component={"div"}
                      className="error"
                    />
                  </div>
                  <div className="input-field">
                    <TextField
                      id="address-basic"
                      label="Address"
                      variant="outlined"
                      {...getFieldProps("address")}
                    />
                    <ErrorMessage
                      name="address"
                      component={"div"}
                      className="error"
                    />
                  </div>

                  <div className="input-field">
                    <TextField
                      id="company-basic"
                      label="Company"
                      variant="outlined"
                      {...getFieldProps("company")}
                    />
                    <ErrorMessage
                      name="company"
                      component={"div"}
                      className="error"
                    />
                  </div>

                  <div className="input-field">
                    <TextField
                      type="number"
                      id="phone-basic"
                      label="Phone number"
                      variant="outlined"
                      {...getFieldProps("phone")}
                    />
                    <ErrorMessage
                      name="phone"
                      component={"div"}
                      className="error"
                    />
                  </div>

                  <div className="input-field">
                    <TextField
                      type="number"
                      id="rate-basic"
                      label="Rate"
                      variant="outlined"
                      {...getFieldProps("rate")}
                    />
                    <ErrorMessage
                      name="rate"
                      component={"div"}
                      className="error"
                    />
                  </div>

                  <button
                    className="primary-btn submit-btn"
                    style={{ textAlign: "center" }}
                    type="submit"
                  >
                    Submit petty
                  </button>

                  <p className="medium-content note-text">
                    Note: Post will be visible only for 30 days
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
