import "./App.css";
import "./Styles/main.scss";
import AuthProvider from "./Components/Context/AuthContext";
import Router from "./Components/Router/Router";
import { useEffect } from "react";
import { Crisp } from "crisp-sdk-web";
import { ModalContextProvider } from "./Components/Context/ModalContext/ModalContext";
import {
  GlobalContextProvider
} from "./Components/Context/GlobalContext";
import BidProvider from "./Components/Context/BidContext/BidContext";

function App() {
  useEffect(() => {
    Crisp.configure("f7b2fe3e-834c-4297-8f47-3b9202a4f65e");
  }, []);

  return (
    <>
      <div className="App">
        <GlobalContextProvider>
          <ModalContextProvider>
            <AuthProvider>
              <BidProvider>
              <Router />
              </BidProvider>
            </AuthProvider>
          </ModalContextProvider>
        </GlobalContextProvider>
      </div>
    </>
  );
}

export default App;
