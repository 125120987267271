import { useState, useEffect } from "react";
import { useAuth, UserProfile } from "../Context/AuthContext";
import { getUser } from "../Api/GetApi";
import { Icon } from "@mdi/react";
import { mdiLockOutline, mdiPencil } from "@mdi/js";
import Modal from "react-modal";
import ChangePassword from "../EditDetails/ChangePassword";
import { updatePassword } from "../Api/PostApi";
import { toast } from "react-toastify";
import { catchError } from "../utils/catchError";
import { useNavigate } from "react-router-dom";
import { FormikHelpers } from "formik";
import EditPersonalDetails, { EditPersonal } from "../EditDetails/EditPersonalDetails";
import { UpdatePersonalDetails } from "../Api/PutApi";

const changePw = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
  },
};
export const PrfoileInfo = () => {
  const navigate = useNavigate();
  const [isPWOpen, setIsPWOpen] = useState(false);
  const { logOut, userData, userProfile } = useAuth();
  const [isEPOpen, setIsEPOpen] = useState(false);

  function togglePWModal() {
    setIsPWOpen(!isPWOpen);
  }
  
  const toggleEPModal = () => {
    setIsEPOpen(!isEPOpen);
  };


  const editPassword = (val: any, { setSubmitting }: FormikHelpers<any>) => {
    updatePassword(val)
      .then((res) => {
        logOut(navigate);
        toast.success(
          res.data.status.status_message + " " + "Please login again"
        );
      })
      .catch((err) => {
        setSubmitting(false);
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };


  const editPersonalDetail = (
    data: EditPersonal,
    { setSubmitting }: FormikHelpers<EditPersonal>
  ) => {
    UpdatePersonalDetails(data)
      .then((res) => {
        userProfile();
        toast.success(res.data.status.status_message);
        setIsEPOpen(false);
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: "315px",
      width: "50%",
    },
  };

  return (
    <div className="profile-info">
      <Modal
        isOpen={isPWOpen}
        onRequestClose={togglePWModal}
        contentLabel="My dialog"
        style={changePw}
        ariaHideApp={false}
      >
        <ChangePassword
          toggleModal={() => togglePWModal()}
          onSubmit={editPassword}
        />
      </Modal>
      <Modal
        isOpen={isEPOpen}
        onRequestClose={toggleEPModal}
        contentLabel="My dialog"
        style={customStyles}
        ariaHideApp={false}
      >
        <EditPersonalDetails
          toggleModal={() => toggleEPModal()}
          userData={userData}
          onSubmit={editPersonalDetail}
        />
      </Modal>

      <div className="head-line">
        <div className=" d-flex ">
          <div className="profile-image">
            <div className="big-circle">
              <p className="circle-font">{userData?.first_name.charAt(0)}</p>
            </div>
          </div>
          <div className="person-details">
            <p className="person-name">{userData?.full_name}</p>
            <p className="person-email">{userData?.email}</p>
            <p>{`+${userData?.contact_no}`}</p>
          </div>
        </div>
        
        <div className="change-password ">
          <button
            className="btn-name btn-pass btn-edit-icon"
            onClick={() => togglePWModal()}
          >
            <div className="d-flex">
              <div className="icon-size">
                <Icon path={mdiLockOutline} />
              </div>
              <div className="change-p">Change Password </div>
            </div>
          </button>

          <button
              className="btn-name btn-edit btn-edit-icon ml-3"
              onClick={toggleEPModal}
            >
              <div className="d-flex">
                <div className="icon-size ">
                  <Icon path={mdiPencil} />
                </div>
                Edit
              </div>
            </button>
        </div>
      </div>
    </div>
  );
};
