import { Link } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import "./RegisterBid.scss";
import { isPublisher } from "../../utils/services";

export const RegisterBid = () => {
  const { isAuthenticate, role } = useAuth();
  const showText = isAuthenticate
    ? isPublisher(role)
      ? "You can now publish the tenders of your category."
      : "You can bid any of the tenders now."
    : "Register now to bid or publish tenders of your category";
  const retunrnButton = isAuthenticate ? (
    isPublisher(role) ? (
      <>
        <Link to="/publish-tender">
          <button className="register-btn">Publish Notice</button>
        </Link>
      </>
    ) : (
      <span className="text-white"> Enjoy Bidding</span>
    )
  ) : (
    <>
      <Link to="/register-form">
        <button className="register-btn">Register Now</button>
      </Link>
    </>
  );
  return (
    <div className="bid-wrapper">
      <div className="bid-img">
        <div className="bid-flex">
          <div>
            <p className="register-bid">{showText}</p>
          </div>
          <div className="btn-register">
            <div className="bid-register">
              <img src={require("../../images/bid-up.png")} alt="images" />
            </div>
            {retunrnButton}

            <div className="bid-register">
              <img src={require("../../images/bid-down.png")} alt="images" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
