import "./RegisterInfo.scss";

export const RegisterInfo = () => {
  return (
    <div className="register-info">
      <div>
        <p className="maintitle">Why Register?</p>
      </div>
      <div className="img-content">
        <img
          className="img-logo"
          src={require("../../images/ranking.png")}
          alt="ranking"
        />
        <p className="small-content">Get result in strategic way</p>
      </div>
      <div className="img-content">
        <img
          className="img-logo"
          src={require("../../images/like.png")}
          alt="like"
        />
        <p className="small-content">
          Industry leading customer <br /> Satisfaction
        </p>
      </div>

      <div className="img-content">
        <img
          className="img-logo"
          src={require("../../images/heart.png")}
          alt="heart"
        />
        <p className="small-content">Trusted by 35K+ customers</p>
      </div>
      <p className="small-content">
      Login / Register गर्दा समस्या भएमा कृप्या सम्पर्क गर्नु होला । 9849447788 / 9802347788
      </p>
    </div>
  );
};
