export const CalculateBoqTotal=(BoqArr:any[])=>{
    let BoqTotal=BoqArr.reduce((prev: any, current: any) => {
        return (
          prev +
          current["item"].reduce((p: any, c: any) => {
            return p + (c.quantity * c.rate);
          }, 0)
        );
      }, 0)
      return BoqTotal
}

export const VatCalculation=(amount:number)=>{
    const vatAmount=0.13*amount
    return vatAmount

}

