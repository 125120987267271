export const stringToDate = (date: string) => {
    const realDate = new Date(date)
    return realDate.toLocaleDateString()
  }

 export const checkDay=(date:string | undefined )=>{
     const realDate = new Date(date as string)
     let today=new Date()
     let yesterday=new Date()
     yesterday.setDate(yesterday.getDate() - 1);
     if (yesterday.toLocaleDateString() === realDate.toLocaleDateString()) {
        return "Yesterday";
      }
      else if(today.toLocaleDateString()===realDate.toLocaleDateString()){
          return "Today"
      }
      return date
 } 
export const DateSlicing =(first:number,last:number,date:any)=>{
  return date.toISOString().slice(first,last)
}