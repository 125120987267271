import "./PricingPlan.scss";
import { faCheck, faLink, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { getPricingPlan } from "../Api/GetApi";
import Modal from "react-modal";
import { SubscriptionPlan } from "../SubscriptionPlan/SubscriptionPlan";
import { PricingShimmer } from "./PricingShimmer";
import { useAuth } from "../Context/AuthContext";
import { isBidder } from "../../utils/services";

export interface PlanType {
  id: number;
  created: string;
  updated: string;
  title: string;
  plan_type: string;
  duration_month: number;
  duration_day: number;
  price: number;
  best_value: boolean;
}

export const PricingPlan = () => {
  const { isAuthenticate, role } = useAuth();
  const [loading, setLoading] = useState(false);
  const [pricing, setPricing] = useState<PlanType[]>();
  const [selectedOption, setSelectedOption] = useState<PlanType>();
  const [arr, setArr] = useState<any>([]);
  const [openPlan, setOpenPlan] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<{
    id: null | number;
    amount: number;
  }>({ id: null, amount: 0 });

  const togglePlanModal = () => {
    setOpenPlan(!openPlan);
  };
  const getStarted = (id: number, amount: number) => {
    setOpenPlan(!openPlan);
    setSelectedPlan((p) => ({
      ...p,
      id: id as number,
      amount: amount as number,
    }));
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
      padding: "0px",
    },
  };

  useEffect(() => {
    setLoading(false);
    getPricingPlan().then((res) => {
      setPricing(res.data.data);
      setLoading(true);
    });
  }, []);

  const basicPlan = useMemo(() => {
    const newArr = pricing?.find((item) => {
      return item.plan_type.toLowerCase() === "basic";
    });
    return newArr;
  }, [pricing]);

  useMemo(() => {
    setArr(
      pricing?.filter((item) => {
        return item.plan_type.toLowerCase() === "business" && item.best_value;
      }) || []
    );
  }, [pricing]);

  const businessPlan = useMemo(() => {
    const newArr = pricing?.find((item) => {
      return (
        item.plan_type.toLowerCase() === "business" && item.best_value === false
      );
    });
    return newArr;
  }, [pricing]);

  useEffect(() => {
    if (arr?.length > 0) {
      const highestPriceProduct = arr.reduce((acc: any, current: any) => {
        return current.price > acc.price ? current : acc;
      });
      setSelectedOption(highestPriceProduct);
    }
  }, [arr]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;

    const selectedOption = arr?.find((item: any) => item.title === selectedId);
    setSelectedOption(selectedOption);
  };

  return (
    <div className="pricing-plan-section">
      <Modal
        isOpen={openPlan}
        onRequestClose={togglePlanModal}
        contentLabel="My dialog"
        style={customStyles}
        ariaHideApp={false}
      >
        <SubscriptionPlan
          toggleModal={togglePlanModal}
          selectedPlan={selectedPlan}
        />
      </Modal>
      {loading ? (
        <div className="container">
          <h2 style={{ textAlign: "center" }}>
            <strong>Pricing Plan</strong>
          </h2>
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-3">
                <div className="pricing-plan-card">
                  <div className="pricing-top-section">
                    <h4 className="text-headers">
                      {basicPlan?.price}
                      <span className="year-text">
                        {basicPlan && "/" + basicPlan?.duration_month / 12} year
                      </span>
                    </h4>
                    <p className="plan-title">{basicPlan?.title}</p>
                  </div>
                  <div className="border-line"></div>

                  <div className="pricing-plan-list">
                    <div className="feature-section">
                      <p className="sub-title">
                        <FontAwesomeIcon className="icon-bg" icon={faStar} />
                        <label>Features</label>
                      </p>

                      <div className="list-item">
                        <FontAwesomeIcon className="icon-bg" icon={faCheck} />

                        <p className="content">Mobile App</p>
                      </div>

                      <div className="list-item">
                        <FontAwesomeIcon className="icon-bg" icon={faCheck} />

                        <p className="content">Website</p>
                      </div>
                    </div>
                    <p className="sub-title">
                      <FontAwesomeIcon className="icon-bg" icon={faLink} />
                      <label>Connect</label>
                    </p>

                    <div className="list-item">
                      <FontAwesomeIcon className="icon-bg" icon={faCheck} />

                      <p className="content">Phone Support</p>
                    </div>
                    <div className="list-item">
                      <FontAwesomeIcon className="icon-bg" icon={faCheck} />

                      <p className="content">Chat Support</p>
                    </div>
                  </div>
                  {isAuthenticate && isBidder(role) && (
                    <button
                      className="btn transparent-btn"
                      onClick={() => {
                        getStarted(
                          basicPlan?.id as number,
                          basicPlan?.price as number
                        );
                      }}
                    >
                      Subscription
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="pricing-plan-card">
                  <div className="best-value">
                    <p>Best Plan</p>
                  </div>

                  <div className="pricing-top-section">
                    <h4 className="text-headers">
                      Rs {selectedOption?.price}
                      <span className="year-text">
                        {selectedOption &&
                          "/" + selectedOption?.duration_month / 12}
                        year
                      </span>
                    </h4>
                    <select
                      onChange={handleSelectChange}
                      value={selectedOption?.title}
                    >
                      {arr?.map((item: any) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                    </select>
                  </div>
                  <div className="border-line"></div>

                  <div className="pricing-plan-list">
                    <div className="feature-section">
                      <p className="sub-title">
                        <FontAwesomeIcon className="icon-bg" icon={faStar} />
                        <label> Features</label>
                      </p>

                      <div className="list-item">
                        <FontAwesomeIcon className="icon-bg" icon={faCheck} />

                        <p className="content">Mobile App</p>
                      </div>

                      <div className="list-item">
                        <FontAwesomeIcon className="icon-bg" icon={faCheck} />

                        <p className="content">Website</p>
                      </div>
                      <div className="list-item">
                        <FontAwesomeIcon className="icon-bg" icon={faCheck} />

                        <p className="content">Email Everyday</p>
                      </div>
                    </div>

                    <div className="connect-section">
                      <p className="sub-title">
                        <FontAwesomeIcon className="icon-bg" icon={faLink} />
                        <label> Connect </label>
                      </p>

                      <div className="list-item">
                        <FontAwesomeIcon className="icon-bg" icon={faCheck} />

                        <p className="content">Phone Support</p>
                      </div>
                      <div className="list-item">
                        <FontAwesomeIcon className="icon-bg" icon={faCheck} />

                        <p className="content">Chat Support</p>
                      </div>
                    </div>
                  </div>
                  {isAuthenticate && isBidder(role) && (
                    <button
                      className="btn transparent-btn"
                      onClick={() => {
                        getStarted(
                          selectedOption?.id as number,
                          selectedOption?.price as number
                        );
                      }}
                    >
                      Subscription
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="pricing-plan-card">
                  <div className="pricing-top-section">
                    <h4 className="text-headers">
                      Rs {businessPlan?.price}
                      <span className="year-text">
                        {businessPlan &&
                          "/" + businessPlan?.duration_month / 12}{" "}
                        year
                      </span>
                    </h4>
                    <p className="plan-title">{businessPlan?.title}</p>
                  </div>
                  <div className="border-line"></div>

                  <div className="pricing-plan-list">
                    <div className="feature-section">
                      <p className="sub-title">
                        <FontAwesomeIcon className="icon-bg" icon={faStar} />
                        <label>Features</label>
                      </p>

                      <div className="list-item">
                        <FontAwesomeIcon className="icon-bg" icon={faCheck} />

                        <p className="content">Mobile App</p>
                      </div>

                      <div className="list-item">
                        <FontAwesomeIcon className="icon-bg" icon={faCheck} />

                        <p className="content">Website</p>
                      </div>

                      <div className="list-item">
                        <FontAwesomeIcon className="icon-bg" icon={faCheck} />

                        <p className="content">Email Everyday</p>
                      </div>
                    </div>

                    <div className="connect-section">
                      <p className="sub-title">
                        <FontAwesomeIcon className="icon-bg" icon={faLink} />
                        <label>Connect</label>
                      </p>
                      <div className="list-item">
                        <FontAwesomeIcon className="icon-bg" icon={faCheck} />

                        <p className="content">Phone Support</p>
                      </div>
                      <div className="list-item">
                        <FontAwesomeIcon className="icon-bg" icon={faCheck} />

                        <p className="content">Chat Support</p>
                      </div>
                    </div>
                  </div>
                  {isAuthenticate && isBidder(role) && (
                    <button
                      className="btn transparent-btn"
                      onClick={() => {
                        getStarted(
                          businessPlan?.id as number,
                          businessPlan?.price as number
                        );
                      }}
                    >
                      Subscription
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <PricingShimmer />
        </div>
      )}
    </div>
  );
};
