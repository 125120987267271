import { useState } from "react";
import "./MyProfile.scss";
import { useEffect } from "react";
import { ProfileDetails } from "./ProfileDetails";
import { ITab, Tab } from "../Tab/Tab";
import { PrfoileInfo } from "./ProfileInfo";
import { Interest } from "../Interest/Interest";
import { useAuth } from "../Context/AuthContext";
import { isPublisher } from "../../utils/services";

export const MyProfile = () => {
  const [activeTab, setActiveTab] = useState<ITab>({ title: "", children: "" });
  const { role } = useAuth();
  const tabList = [
    {
      title: "Profile",
      children: <ProfileDetails />,
    },
    {
      title: "Interest",
      children: <Interest />,
    },
  ];

  useEffect(() => {
    setActiveTab({ title: "Profile", children: <ProfileDetails /> });
  }, []);

  return (
    <>
      <div className="my-profile-section">
        <div className="card">
          <p className="profile-content topic ">My Profile</p>
          <PrfoileInfo />
          <div className="tab-block">
            {tabList.map((item, index) => {
              if (isPublisher(role) && item.title === tabList[1].title)
                return null;
              return (
                <Tab
                  key={index}
                  onClick={() => {
                    setActiveTab(item);
                  }}
                  isActive={activeTab?.title === item?.title}
                >
                  {item.title}
                </Tab>
              );
            })}
          </div>

          {activeTab.children}
        </div>
      </div>
    </>
  );
};
