import "./AccountDeletion.scss";
import { useAccountDeletion } from "./useAccountDeletion";

function AccountDeletion() {
  const { accoutDeletionRequest, setFormValue, formValue, loading } =
    useAccountDeletion();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    accoutDeletionRequest();
  };

  return (
    <div className="container account-deletion mt-5">
      <h2 className="headline">
        <strong>Request Deletion</strong>
      </h2>
      <div className="card">
        <p className="sub-title">
          Please fill the form for requesting deletion of your account
        </p>
        <div className="d-flex justify-content-center">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email address<span className="text-danger">*</span></label>
              <input
                name="email"
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter registered email"
                value={formValue?.email}
                onChange={(e) => setFormValue(e)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Contact No.<span className="text-danger">*</span></label>
              <input
                name="contact_no"
                type="number"
                className="form-control"
                placeholder="Enter registered contact no."
                onChange={setFormValue}
                value={formValue?.contact_no}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Reason.</label>
              <textarea
                name="reason"
                className="form-control"
                onChange={setFormValue}
                value={formValue?.reason}
              />
            </div>
            <div className="d-flex justify-content-end">
              <button type="submit" className="search-btn pd-btn" disabled={loading}>
                {loading? "Submitting.." : "Request Deletion"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AccountDeletion;
