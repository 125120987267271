import { mdiInformationOutline } from "@mdi/js"
import Icon from "@mdi/react"
import "./NoteComponent.scss"

export interface NoteComponentProps {
  note: string
  traingle?: boolean
}

export const NoteComponent: React.FC<NoteComponentProps> = ({
  note,
  traingle,
}) => {
  return (
    <>
      {traingle && <div className="blue-trangle"></div>}
      <div className="blue-box">
        <Icon className="mdi-alert" path={mdiInformationOutline}></Icon>
        <span className="form-label">{note} </span>
      </div>
    </>
  )
}
