import { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { combineClasses } from "../utils/css-utils";
import "./Tab.scss";

export interface ITab {
  title: string;
  children: JSX.Element | string;
}

export interface TabProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isActive?: boolean;
  disable?: boolean;
}

export const Tab: FC<TabProps> = ({
  isActive,
  disable,
  children,
  className,
  ...divProps
}) => {
  return (
    <div className="tab">
      <div
        className={combineClasses(
          "tab-btn",
          className,
          isActive ? "active" : ""
        )}
        {...divProps}
      >
        {children}
      </div>
    </div>
  );
};
