import "react-tabs/style/react-tabs.scss"
import "./NotFound.scss"

export interface NotFoundProps {
  message?: string
}

export const NotFound: React.FC<NotFoundProps> = ({ message }) => {
  return (
    <div className="not-found">
      <div className="error-page">
        <img
          src={require("../../images/not-found.png")}
          alt="images"
          className="astro"
        />
        <h1 className="big-font-title">Ooops</h1>
        <p className="big-font">{message} </p>
        <p className="big-font">We're working on it.</p>
      </div>
    </div>
  )
}
