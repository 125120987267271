import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mdiFileDocumentOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  findOrganizationLabelByValue,
  isBidder,
  isNoticeCategoryEOI,
} from "../../utils/services";

import fallbackLogoUrl from "../../images/bolpatra logo-full.png";
import { saveTenderBid, tenderBid } from "../Api/PostApi";
import { useAuth } from "../Context/AuthContext";
import { ModalContext } from "../Context/ModalContext/ModalContext";
import { AdditionalAttributes } from "../FormikWrapper/TenderFormikWrapper";
import { Note } from "../Info";
import { catchError } from "../utils/catchError";
import { ITenderAttributes, IUserInfo } from "../utils/interface";
import "./BidDetails.scss";
import { TenderCardAction } from "../TenderCardAction/TenderCardAction";
import { ImageWithFallback } from "../ImageWithFallback/ImageWithFallback";

export interface DetailBid {
  tender_number: number;
  pubilshing_date: string;
  submission_date: any;
  notice_category: string;
  project_category: Array<ITenderAttributes>;
  procurement_type: Array<ITenderAttributes>;
  title: string;
  district: string;
  public_entity_name: string;
  ppmoFileUrl?: string;
  isPrivate?: boolean;
  publisherId: number;
  image?: string;
  boq?: any;
  savebids?: boolean;
  notes?: string;
  terms?: string;
  show_boq_item_rate?: boolean;
  category: string;
  opening_date: string;
  specification_terms_format: string;
  technical_pass: boolean | null;
  isVat: boolean;
  privateBid?: boolean;
  remainingDays?: number;
  hide_bidder: boolean;
  open_bid?: boolean;
  additional_attributes: AdditionalAttributes[];
  systemBidFee?: boolean;
  isMain: boolean;
  user_info: IUserInfo;
  award_type?: string;
}

interface BidProps {
  bidDetail: DetailBid | undefined;
  detailView: boolean | false;
}

export const BidDetails: React.FC<BidProps> = ({ bidDetail, detailView }) => {
  const { id } = useParams();
  const { role, isAuthenticate, eligible } = useAuth();
  const shareUrl = detailView ? `bids/view/${id}` : `tender-bid/${id}`;
  const { TogglePopUpFlags } = useContext(ModalContext);

  const handleButtonClick = () => {
    window.open(bidDetail?.specification_terms_format, "_blank");
  };

  const addBidToMyBids = (tender_id: string | number | undefined) => {
    const data = {
      tender_notice_id: tender_id,
    };
    tenderBid(data)
      .then((res) => {
        if (res.status === 202) {
          toast.error(res.data.status.status_message);
        }
        if (res.status === 200) {
          toast.success("Successfully added the bid ");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return TogglePopUpFlags("login");
        }
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };

  const saveBids = (tender_id: string | number | undefined) => {
    saveTenderBid(tender_id)
      .then((res) => {
        toast.success("Bid Saved Successfully");
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };

  const handleOnClickBid = (id: number) => {
    if (isAuthenticate && isBidder(role)) {
      if (eligible) {
        addBidToMyBids(id);
      } else {
        TogglePopUpFlags("editCompanyDetail");
      }
    } else {
      TogglePopUpFlags("login");
    }
  };

  return (
    <>
      <div className="card details-section">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="company-details-section">
                <div className="logo-section">
                  <ImageWithFallback
                    src={bidDetail?.user_info?.additional_info?.logo || ""}
                    fallbackSrc={fallbackLogoUrl}
                    alt="Company Logo"
                  />
                </div>
                <div className="company-details">
                  <div className="d-flex">
                    <h4 className="block-title">
                      {bidDetail?.user_info?.company_name}
                    </h4>
                    {bidDetail?.user_info?.is_verified && (
                      <img
                        src={
                          require("../../images/bolpatra verified icon-01.svg")
                            .default
                        }
                        alt="verified"
                        className="banner-img"
                      />
                    )}
                  </div>
                  <p className="company-detail-content">
                    {findOrganizationLabelByValue(
                      bidDetail?.user_info?.organization_category as string
                    )}{" "}
                    | {bidDetail?.user_info?.location}
                  </p>
                  <p className="company-detail-content">
                    Contact Person: {bidDetail?.user_info?.full_name}
                  </p>
                  <p className="company-detail-content">
                    Phone No: {bidDetail?.user_info?.contact_no}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="card-wrapper ">
                <h4 className="block-title">{bidDetail?.title} </h4>
                <TenderCardAction
                  shareUrl={shareUrl}
                  boq={bidDetail?.boq}
                  tender_id={Number(id)}
                  savebids={bidDetail?.savebids}
                  title={bidDetail?.title}
                  image={bidDetail?.image}
                  ppmoFileUrl={bidDetail?.ppmoFileUrl}
                  notes={bidDetail?.notes}
                  terms={bidDetail?.terms}
                  show_boq_item_rate={bidDetail?.show_boq_item_rate}
                  privateBid={bidDetail?.privateBid}
                  additional_attributes={bidDetail?.additional_attributes}
                />
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="d-flex">
                    <p className="detail-content"> Notice ID:</p>
                    <p className="content-right">{bidDetail?.tender_number}</p>
                  </div>
                  <div className="d-flex align-items-start">
                    <p className="detail-content text-nowrap">
                      Project Category:
                    </p>
                    <p className="content-right">
                    {bidDetail?.project_category?.map((item) => item?.label).join(', ')}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex">
                    <p className="detail-content"> Published Date: </p>
                    <p className="content-right">
                      {bidDetail?.pubilshing_date.slice(0, 10)}
                    </p>
                  </div>
                  <div className="d-flex">
                    <p className="detail-content">Last Submission Date:</p>
                    <p className="content-right">
                      {bidDetail?.submission_date
                        ?.toLocaleString()
                        .slice(0, 10)}{" "}
                      | 12:00 PM
                    </p>
                  </div>
                  {isNoticeCategoryEOI(bidDetail?.notice_category as string) &&
                    bidDetail?.opening_date && (
                      <div className="d-flex">
                        <p className="detail-content">Final Submission Date:</p>
                        <p className="content-right">
                          {bidDetail?.opening_date
                            ?.toLocaleString()
                            .slice(0, 10)}{" "}
                          | 12:00 PM
                        </p>
                      </div>
                    )}
                </div>
              </div>
              <div className="btn-block">
                <button
                  type="button"
                  className="bid-document-btn"
                  onClick={() => {
                    handleButtonClick();
                  }}
                >
                  <Icon className="doc-icon" path={mdiFileDocumentOutline} />
                  Bid Document
                </button>
                {(!isAuthenticate || isBidder(role)) &&
                  Number(bidDetail?.remainingDays) > 0 && (
                    <button
                      className="transparent-btn apply-btn"
                      onClick={() => {
                        handleOnClickBid(Number(id));
                      }}
                    >
                      <img
                        className="btn-img-icon-size"
                        src={require("../../images/bidblue.png")}
                        alt="images"
                      />
                      Apply Bid
                    </button>
                  )}
              </div>
            </div>
          </div>
          <div className="bid-page-buttons">
            <div className="bid-buutons">
              {isBidder(role) && !bidDetail?.isPrivate && (
                <div className="btn-combination mr-2">
                  <div className="mg-btn-right">
                    <button
                      className="save-bid-btn"
                      onClick={() => {
                        saveBids(Number(id));
                      }}
                    >
                      <FontAwesomeIcon className="icon-bg" icon={faSave} />
                      Save Bid
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {bidDetail?.hide_bidder && (
            <Note>
              The publisher has chosen to keep the participant's details
              confidential.
            </Note>
          )}
        </div>
      </div>
    </>
  );
};
